import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { CognitoIdentityProvider } from '@aws-sdk/client-cognito-identity-provider';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import i18n from 'i18next';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { initReactI18next } from 'react-i18next';
import { RouterProvider } from 'react-router-dom';
import translationEN from './i18n/en.json';
import router from './router';
import { AlertProvider } from './shared/context/alert';
import { AuthProvider } from './shared/auth/context';
import Theme from './styles/Theme';
import './styles/styles.scss';

const languageCode = 'en';
const region = 'ap-southeast-1';
const channel = new BroadcastChannel('BIZAPP');

function syncStorage() {
  const sessionArr = [];
  const sessionKeys = Object.keys(window.sessionStorage);
  for (const key of sessionKeys) {
    if (key.includes('Cognito')) {
      window.sessionStorage.setItem(key, window.sessionStorage.getItem(key)!);
      sessionArr.push({ key: key, value: window.sessionStorage.getItem(key)! });
    }
  }
  if (sessionArr.length) {
    channel.postMessage(sessionArr);
  }
}

if (window.localStorage.getItem('isLoggedIn') !== 'true') {
  window.localStorage.setItem('isLoggedIn', 'false')
}

window.sessionStorage.setItem('firstLoad', 'true');

window.addEventListener('beforeunload', function () {
  channel.postMessage('beforeunload');
});

window.onload = () => {
  channel.postMessage('onload');
};

channel.onmessage = (event: any) => {
  if (event.data === 'signout') {
    window.localStorage.setItem('isLoggedIn', 'false');
    window.sessionStorage.clear();
    window.location.pathname = '/login';
  }

  if (event.data === 'signin' || (event.data === 'onload' && window.sessionStorage.getItem('firstLoad') === 'true')) {
    syncStorage();
  }

  if ((Array.isArray(event.data) && event.data.length > 1) &&
      sessionStorage.getItem('tabOpenTime') === null &&
      window.localStorage.getItem('isLoggedIn') === 'true') {

      event.data.forEach((keys: any) => {
        window.sessionStorage.setItem(keys.key, keys.value);
      });

      window.sessionStorage.setItem('firstLoad', 'false');
      window.location.reload();
  }
}

export const cognitoUserPool = new CognitoUserPool({
  UserPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID ?? '',
  ClientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID ?? '',
  Storage: window.sessionStorage
})


export const cognitoIdentityProvider = new CognitoIdentityProvider({
  region: region,
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_COGNITO_ACCESS_KEY_ID ?? '',
    secretAccessKey: process.env.REACT_APP_AWS_COGNITO_SECRET_ACCESS_KEY ?? ''
  }
});

i18n
  .use(initReactI18next)
  .init({
    lng: languageCode,
    fallbackLng: languageCode,
    interpolation: {
      escapeValue: false
    },
    resources: {
      [languageCode]: {
        translation: translationEN
      }
    }
  });

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

dayjs.extend(duration);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={Theme}>
      <AuthProvider>
        <AlertProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <RouterProvider router={router} />
          </LocalizationProvider>
        </AlertProvider>
      </AuthProvider>
    </ThemeProvider>
  </React.StrictMode>
);
