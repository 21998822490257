import React, {ReactElement} from 'react';
import {NxFormikProps} from '../model/SharedTypes';
import {useField, useFormikContext} from 'formik';
import NxInput, {NxInputProps} from './NxInput';

export type NxFormikInputProps = Omit<NxInputProps, NxFormikProps> & {
  name: string;
  validate?: (text: string) => string | void | Promise<string | void>;
};

export const NxFormikInput = (props: NxFormikInputProps): ReactElement => {

  const context = useFormikContext();
  const [field, meta, ] = useField(props);

  return <NxInput {...field}
                  {...props}
                  error={meta.error}
                  disabled={props.disabled || context.isSubmitting} />;
}