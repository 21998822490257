export type ReportsDownloadQuery =  {
  dateFrom: string;
  dateTo: string;
  report: ReportGroup;
};

export enum ReportGroup {
  BILLS_PAYMENT = "bills-payment-transactions",
  RCBC_GROUP = "rcbc-group-transactions",
  INSTAPAY = "instapay-transactions",
  PESONET = "pesonet-transactions",
  FEEDBACKS = "feedbacks",
  DELETED_ACCOUNTS = "deleted-accounts",
  REGISTERED_ACCOUNTS = "registered-accounts",
  INVOICES = 'invoices',
  PAYROLLS = 'payrolls',
};
