import {ReactElement} from 'react';
import {Navigate} from 'react-router-dom';
import {ReactComponent as Logo} from '../../assets/images/logo-login-header.svg';
import {setNewPassword, signIn, useAuth} from '../../shared/auth/context';
import {AuthState} from '../../shared/auth/model';
import { useTranslation } from 'react-i18next';
import { useAlert } from '../../shared/context/alert';
import LoginForm from './login-form/LoginForm';
import styles from './Login.module.scss';
import PasswordSetForm from './password-set-form/PasswordSetForm';

export default function Login(): ReactElement {

  const auth = useAuth();
  const { showAlert } = useAlert();
  const { t } = useTranslation();

  const submitLogin = async (username: string, password: string): Promise<void> => {
    try {
      await signIn(username, password, auth.dispatch);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const submitPasswordChange = async (password: string) => {
    try {
      await setNewPassword(password, auth.state.cognitoUser, auth.dispatch);
      showAlert({
        type: 'success',
        message: t(`LOGIN.CHANGE_PASSWORD_SUCCESS`)
      });
    } catch (error) {
      return Promise.reject(error);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.formContainer}>
        <Logo />
        <div className={styles.form}>
          {
            auth.state.authState === AuthState.PASSWORD_CHANGE_REQUIRED ?
              <PasswordSetForm onSubmit={submitPasswordChange} /> :
              <LoginForm onSubmit={submitLogin} />
          }
          {auth.state.authState === AuthState.SIGNED_IN && <Navigate to={'/'} />}
        </div>
      </div>
    </div>
  );
}
