import { ReactElement, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAlert } from '../../shared/context/alert';
import usePost from '../../shared/hooks/use-post.hook';
import { downloadCsv, downloadXLSX } from '../../shared/utils/file-download';
import { TransHelper } from '../../shared/utils/trans-helper';
import ContentBox from '../shared/content-box/ContentBox';
import ReportsDownloadForm from './reports-download-form/ReportsDownloadForm';
import { ReportsDownloadQuery } from './Reports.model';
import styles from './Reports.module.scss';

export default function Reports(): ReactElement {

  const { t } = useTranslation();

  const PrefixedTrans = TransHelper.getPrefixedTrans('REPORTS');

  const [isLoading, setIsLoading] = useState(false);

  const downloadReport = usePost(`/admin/reports`);

  const { showAlert } = useAlert();

  const submitReportDownload = useCallback(async (fields: ReportsDownloadQuery) => {

    const reportGroupTrans = t(`REPORTS.REPORT_GROUPS.${fields.report.replaceAll('-', '_').toUpperCase()}`);

    try {
      setIsLoading(true);

      const isFeedbackReport = fields.report === 'feedbacks' || fields.report === 'deleted-accounts' || fields.report === 'registered-accounts' || fields.report === 'invoices' || fields.report === 'payrolls'
      const responseType = isFeedbackReport ? 'arraybuffer' : '';

      downloadReport({ dateFrom: fields.dateFrom, dateTo: fields.dateTo }, null, null, null, `/${fields.report}`, responseType)
        .then((res) => {
          if (isFeedbackReport) {
            downloadXLSX(res as string, reportGroupTrans)
          } else {
            return downloadCsv(res + '', `${reportGroupTrans} report ${fields.dateFrom}-${fields.dateTo}`)
          }
        })
        .then(() => showAlert({
          type: 'success',
          message: t('REPORTS.MESSAGE_CODE.REPORT_DOWNLOAD_SUCCESS', { reportGroup: reportGroupTrans })
        }))
        .catch(() => showAlert({ type: 'error', message: t('REPORTS.MESSAGE_CODE.REPORT_DOWNLOAD_FAIL', { reportGroup: reportGroupTrans }) }))
        .finally(() => setIsLoading(false));
    } catch (error) {
      showAlert({ type: 'error', message: t('REPORTS.MESSAGE_CODE.REPORT_DOWNLOAD_FAIL', { reportGroup: reportGroupTrans }) });
      return Promise.reject(error);
    }
  }, [downloadReport, showAlert, t]);

  return (
    <ContentBox header={<PrefixedTrans>BOX_TITLE</PrefixedTrans>}>
      <div className={styles.container}>
        <ReportsDownloadForm
          isLoading={isLoading}
          onSubmit={submitReportDownload} />
      </div>
    </ContentBox>
  );
}
