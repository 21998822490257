import { Formik, FormikHelpers, FormikProps } from 'formik';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useTrans from '../../../../shared/hooks/use-trans.hook';
import { adminGetUser, sendforgotPasswordCode } from '../../../../shared/auth/context';
import { useAlert } from '../../../../shared/context/alert';
import NxButton from '../../../shared/nxButton/NxButton';
import { NxFormikInput } from '../../../shared/nxInput/NxFormikInput';
import { ForgotPasswordRequestFields } from './ForgotPasswordRequest.model';
import styles from './ForgotPasswordRequest.module.scss';
import ResetPasswordForm from '../reset-password-form/ResetPasswordForm'
import { ReactComponent as Logo } from '../../../../assets/images/logo-login-header.svg'

export default function ForgotPasswordRequest(): React.ReactElement {

    const { t } = useTranslation();
    const SharedTrans = useTrans('LOGIN');
    const { showAlert } = useAlert();
    const [currentUser, setCurrentUser] = useState<{ username: string; email: string }>({
        username: "",
        email: "",
    });

    const submit = async (values: ForgotPasswordRequestFields, actions: FormikHelpers<ForgotPasswordRequestFields>): Promise<void> => {
        try {
            const userData = await adminGetUser(values.username);
            const foundEmail = userData?.UserAttributes?.find(attribute => attribute.Name === 'email')?.Value;

            if (values.email !== foundEmail) {
                throw new Error("EmailMismatch");
            }
            await sendforgotPasswordCode(values.username);
            setCurrentUser({ username: values.username, email: values.email });
        } catch (err: any) {
            if (err.name === "InvalidParameterException" || err.name === "UserNotFoundException" || err.message === "EmailMismatch") {
                actions.setFieldError('username', "");
                actions.setFieldError('email', t(`LOGIN.FAILED_FORGOT_PASS_CREDENTIALS`));
            }
            showAlert({
                type: 'error',
                message: t(`LOGIN.FAILED_CODE_SEND`)
            });
        }
    };

    const LoginForm = ({
        handleSubmit,
        errors,
        dirty,
        isSubmitting
    }: FormikProps<ForgotPasswordRequestFields>): ReactElement => (
        <form onSubmit={handleSubmit} className={styles.form}>
            <NxFormikInput name='username'
                className={styles.input}
                disabled={isSubmitting}
                label={<SharedTrans>USERNAME</SharedTrans>}
            />
            <NxFormikInput name='email'
                className={styles.input}
                disabled={isSubmitting}
                label={<SharedTrans>EMAIL</SharedTrans>}
            />
            <div className={styles.error}>{errors.generalError}</div>
            <div className={styles.signInRow}>
                <NxButton type='submit' className={styles.button} loaded={!isSubmitting} disabled={!dirty}>
                    <SharedTrans>SEND_CODE</SharedTrans>
                </NxButton>
            </div>
        </form>
    );

    const formInitValues = {
        username: '',
        email: '',
        generalError: ''
    };

    return (
        <>
            {!!currentUser.username ? <ResetPasswordForm user={currentUser} /> : (<div className={styles.container}>
                <div className={styles.formContainer}>
                    <Logo />
                    <div className={styles.header}>{t(`LOGIN.FORGOT_PASSWORD`)}</div>
                    <span className={styles.description}>{t(`LOGIN.FORGOT_PASSWORD_DESC`)}</span>

                    <Formik<ForgotPasswordRequestFields> initialValues={formInitValues}
                        onSubmit={submit}
                        validateOnMount={false}
                        validateOnBlur={false}
                        validateOnChange={false}
                    >
                        {LoginForm}
                    </Formik>
                </div>
                <span className={styles.footer}>{t(`MAIN_CONTAINER.FOOTER_TEXT`)}</span>
            </div>)}
        </>
    );
}
