import { ReactElement, useEffect, useRef } from 'react';
import DetailBox from '../../shared/detail-box/DetailsBox';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import EnableIndicator from '../../business-owners/details/mobile-app-access-table/enable-indicator/EnableIndicator';
import { ActivityRow, ActivityDetails } from '../Activity.model'
import styles from './ActivityDetail.module.scss';
import { ReactComponent as IconDelete } from '../../../assets/icons/icon-delete.svg';

type ActivityDetailProps = {
  row: ActivityRow;
  activityDetail: ActivityDetails;
  getDetailHeight: (height: number) => void
}

function Detail({ label, value }: { label: string | undefined, value: string | ReactElement | undefined }) {
  return (
    <div className={styles.detailContainer}>
      <div className={styles.detailLabel}>{label}</div>
      <div className={styles.detailValue}>{value}</div>
    </div>
  );
}


const ActivityDetail = ({ row, activityDetail, getDetailHeight }: ActivityDetailProps): ReactElement => {
  const { t } = useTranslation();
  const detailRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if(detailRef.current) {
      getDetailHeight(detailRef.current.clientHeight);
    }
  })

  function showDetail() {
    switch (row.activityType) {
      case 'BUSINESS_OWNERS':
        const businessOwnersActivityDetail = (
          <div className={styles.detailContainer}>
            <DetailBox>
              <div className={styles.detailSectionHeader}>
                {t(`ACTIVITY.BEFORE`)}
              </div>
              <Detail label={t(`TRANSACTIONS.DETAILS.BIZAP_USERNAME`) + ":"} value={activityDetail?.before?.BIZ_APP_USERNAME} />
              <Detail label={t(`SHARED.STATUS`) + ":"} value={<><EnableIndicator isEnabled={activityDetail.after.STATUS === 'Blocked'} /> <span className={styles.svgSpan}>{activityDetail?.before?.STATUS}</span></>} />
            </DetailBox>

            <DetailBox>
              <div className={styles.detailSectionHeader}>
                {t(`ACTIVITY.AFTER`)}
              </div>
              <Detail label={t(`TRANSACTIONS.DETAILS.BIZAP_USERNAME`) + ":"} value={activityDetail.after.BIZ_APP_USERNAME} />
              <Detail label={t(`SHARED.STATUS`) + ":"} value={<><EnableIndicator isEnabled={activityDetail.after.STATUS === 'Unblocked'} /> <span className={styles.svgSpan}>{activityDetail.after.STATUS}</span></>} />
            </DetailBox>
          </div>
        )

        const businessOwnersActivityDetailWithStatus = (
          <div className={styles.detailContainer}>
            <DetailBox>
              <div className={styles.detailSectionHeader}>
                {t(`ACTIVITY.BEFORE`)}
              </div>
              <Detail label={t(`TRANSACTIONS.DETAILS.BIZAP_USERNAME`) + ":"} value={activityDetail?.before?.BIZ_APP_USERNAME} />
              <Detail label={t(`SHARED.STATUS`) + ":"} value={<><EnableIndicator isEnabled={activityDetail?.before?.STATUS === 'Active'} /><span className={styles.svgSpan}>{activityDetail?.before?.STATUS}</span></>} />
            </DetailBox>

            <DetailBox>
              <div className={styles.detailSectionHeader}>
                {t(`ACTIVITY.AFTER`)}
              </div>
              <Detail label={t(`TRANSACTIONS.DETAILS.BIZAP_USERNAME`) + ":"} value={activityDetail.after.BIZ_APP_USERNAME} />
              <Detail label={t(`SHARED.STATUS`) + ":"} value={<><IconDelete /><span className={styles.svgSpan}>{activityDetail.after.STATUS}</span></>} />
            </DetailBox>
          </div>
        )

        const businessOwnersActivityDetailWithBlock = (
          <div className={styles.detailContainer}>
            <DetailBox>
              <div className={styles.detailSectionHeader}>
                {t(`ACTIVITY.BEFORE`)}
              </div>
              <Detail label={t(`TRANSACTIONS.DETAILS.BIZAP_USERNAME`) + ":"} value={activityDetail?.before?.BIZ_APP_USERNAME} />
              <Detail label={t(`SHARED.STATUS`) + ":"} value={<><EnableIndicator isEnabled={activityDetail.after.STATUS === 'SUSPENDED'} /> <span className={styles.svgSpan}>{activityDetail?.before?.STATUS}</span></>} />
              {activityDetail?.before?.REMARKS && <Detail label={"Remarks:"} value={activityDetail?.before?.REMARKS} />}
            </DetailBox>

            <DetailBox>
              <div className={styles.detailSectionHeader}>
                {t(`ACTIVITY.AFTER`)}
              </div>
              <Detail label={t(`TRANSACTIONS.DETAILS.BIZAP_USERNAME`) + ":"} value={activityDetail.after.BIZ_APP_USERNAME} />
              <Detail label={t(`SHARED.STATUS`) + ":"} value={<><EnableIndicator isEnabled={activityDetail.after.STATUS === 'ACTIVE'} /> <span className={styles.svgSpan}>{activityDetail.after.STATUS}</span></>} />
              {activityDetail?.after?.REMARKS && <Detail label={"Remarks:"} value={activityDetail?.after?.REMARKS} />}
            </DetailBox>
          </div>
        )

        if (row?.modification.includes("Delete")) {
          return businessOwnersActivityDetailWithStatus
        } else if (row?.modification.includes("Boz Profile")) {
          return businessOwnersActivityDetailWithBlock
        } else {
          return businessOwnersActivityDetail
        }

      case 'DICTIONARIES':
        const dictionaryBeforeAfterActivityDetail = (
          <div className={styles.detailContainer}>
            <DetailBox>
              <div className={styles.detailSectionHeader}>
                {t(`ACTIVITY.BEFORE`)}
              </div>
              <Detail label={t(`DICTIONARIES.CODE_LABEL`) + ":"} value={activityDetail?.before?.CODE} />
              <Detail label={t(`DICTIONARIES.VALUE_LABEL`) + ":"} value={activityDetail?.before?.VALUE} />
              <Detail label={t(`DICTIONARIES.VISIBILITY_LABEL`) + ":"} value={activityDetail?.before?.VISIBILITY === "true" ? t(`SHARED.ENABLED`) : t(`SHARED.DISABLED`)} />
            </DetailBox>

            <DetailBox>
              <div className={styles.detailSectionHeader}>
                {t(`ACTIVITY.AFTER`)}
              </div>
              <Detail label={t(`DICTIONARIES.CODE_LABEL`) + ":"} value={activityDetail?.after?.CODE} />
              <Detail label={t(`DICTIONARIES.VALUE_LABEL`) + ":"} value={activityDetail?.after?.VALUE} />
              <Detail label={t(`DICTIONARIES.VISIBILITY_LABEL`) + ":"} value={activityDetail?.after?.VISIBILITY === "true" ? t(`SHARED.ENABLED`) : t(`SHARED.DISABLED`)} />
            </DetailBox>
          </div>
        )

        const dictionaryActivityDetail = (
          <div className={styles.detailContainer}>
            <DetailBox>
              <div className={styles.detailSectionHeader}>{t(`SHARED.DETAILS`)}</div>
              <Detail label={t(`DICTIONARIES.CODE_LABEL`) + ":"} value={activityDetail?.after?.CODE} />
              <Detail label={t(`DICTIONARIES.VALUE_LABEL`) + ":"} value={activityDetail?.after?.VALUE} />
              <Detail label={t(`DICTIONARIES.VISIBILITY_LABEL`) + ":"} value={activityDetail?.after?.VISIBILITY === "true" ? t(`SHARED.ENABLED`) : t(`SHARED.DISABLED`)} />
            </DetailBox>
          </div>
        )
        return activityDetail.before ? dictionaryBeforeAfterActivityDetail : dictionaryActivityDetail

      case 'TRANSACTIONS':
        const transactionActivityDetail = (
          <div className={styles.detailContainer}>
            <DetailBox>
              <div className={styles.detailSectionHeader}>
                {t(`ACTIVITY.BEFORE`)}
              </div>
              <Detail label={t(`TRANSACTIONS.TABLE.COLUMN_HEADERS.TRANSACTION_ID`) + ":"} value={activityDetail?.before?.TRANSACTIONS_ID} />
              <div className={styles.detailContainer}>
                <div className={styles.detailLabel}>{t(`SHARED.STATUS`) + ":"}</div>
                <div className={clsx(styles.status, styles[activityDetail?.before?.STATUS?.toLowerCase() as string])}>
                  <p>{activityDetail?.before?.STATUS}</p>
                </div>
              </div>
            </DetailBox>

            <DetailBox>
              <div className={styles.detailSectionHeader}>
                {t(`ACTIVITY.AFTER`)}
              </div>
              <Detail label={t(`TRANSACTIONS.TABLE.COLUMN_HEADERS.TRANSACTION_ID`) + ":"} value={activityDetail?.after?.TRANSACTIONS_ID} />
              <div className={styles.detailContainer}>
                <div className={styles.detailLabel}>{t(`SHARED.STATUS`) + ":"}</div>
                <div className={clsx(styles.status, styles[activityDetail?.after?.STATUS?.toLowerCase() as string])}>
                  <p>{activityDetail?.after?.STATUS}</p>
                </div>
              </div>
            </DetailBox>
          </div>
        )
        return transactionActivityDetail

      case 'SYSTEM_PROPERTIES':
        const systemActivityDetail = (
          <div className={styles.detailContainer}>
            <DetailBox>
              <div className={styles.detailSectionHeader}>
                {t(`ACTIVITY.BEFORE`)}
              </div>
              <Detail label={t(`SYSTEM_PROPERTIES.CODE`) + ":"} value={activityDetail?.before?.CODE} />
              <Detail label={t(`SYSTEM_PROPERTIES.VALUE`) + ":"} value={activityDetail?.before?.VALUE} />
            </DetailBox>

            <DetailBox>
              <div className={styles.detailSectionHeader}>
                {t(`ACTIVITY.AFTER`)}
              </div>
              <Detail label={t(`SYSTEM_PROPERTIES.CODE`) + ":"} value={activityDetail?.after?.CODE} />
              <Detail label={t(`SYSTEM_PROPERTIES.VALUE`) + ":"} value={activityDetail?.after?.VALUE} />
            </DetailBox>
          </div>
        )
        return systemActivityDetail
    }
  }

  return (
    <div ref={detailRef}>
      {showDetail()}
    </div>
  )
}

export default ActivityDetail;
