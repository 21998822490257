import React, {ReactElement} from 'react';
import {ReactComponent as LogoutIcon} from '../../../../assets/icons/icon-logout.svg';
import {signOut, useAuth} from '../../../../shared/auth/context';
import {TransHelper} from '../../../../shared/utils/trans-helper';
import useInactivityTimer from '../../../../shared/hooks/useInactivityTimer';
import styles from './LoginInfo.module.scss';

export const LoginInfo = (): ReactElement => {

  const PrefixedTrans = TransHelper.getPrefixedTrans("MAIN_CONTAINER");
  const auth = useAuth();
  useInactivityTimer();

  return (
    <div className={styles.loginInfoContainer}>
      <div className={styles.loggedAsText}>
        <PrefixedTrans>LOGGED_AS</PrefixedTrans>
        <span className={styles.loggedAsUser}>{auth.state.username}</span>
      </div>
      <div className={styles.logoutButton} onClick={() => signOut(auth.dispatch)}>
        <LogoutIcon />
      </div>
    </div>
  );
};

export default LoginInfo;
