import {GridColDef} from '@mui/x-data-grid';
import {TFunction} from 'i18next';
import {formatCurrency} from '../../../shared/utils/currency-format';
import {createColDefWithDefaultConfiguration} from '../../../shared/utils/dataGridDefaultColumnDefValues';
import styles from './BusinessOwnersDetails.module.scss';
import StatusFieldDecorated from './StatusFieldDecorated';

export const businessOwnerAccountsTableColumnDefinitions = (
  t: TFunction,
  isDeletedAccount: boolean
): Array<GridColDef> =>
  createColDefWithDefaultConfiguration([
    {
      field: "productName",
      headerName: t("BUSINESS_OWNERS.DETAILS.ACCOUNTS.NAME") ?? "",
    },
    {
      field: "number",
      headerName: t("BUSINESS_OWNERS.DETAILS.ACCOUNTS.NUMBER") ?? "",
      cellClassName: isDeletedAccount ? styles.deletedAccount : '',
    },
    {
      field: "balance",
      headerName: t("BUSINESS_OWNERS.DETAILS.ACCOUNTS.BALANCE") ?? "",
      cellClassName: isDeletedAccount ? styles.deletedAccount : styles.accountListBalanceCell,
      valueFormatter: (gridFormatterParams) =>
        `${formatCurrency(gridFormatterParams.value, {
          style: undefined,
        })} PHP`,
    },
    {
      field: "status",
      headerName: "Status" ?? "",
      renderCell: (params) => {
        return <StatusFieldDecorated status={params.row.status} isDeletedAccount={isDeletedAccount} />
      },
    },
  ]);
