import React, {ReactElement, useCallback} from 'react';
import Alert from '../../components/shared/alert/Alert';

export type AlertData = {
  isOpen?: boolean
  delay?: number;
  duration?: number;
  message: string;
  vertical?: 'top' | 'bottom';
  horizontal?: 'left' | 'center' | 'right';
  type: 'warning' | 'success' | 'error' | 'info';
}

export type ActionType =
  'WARN' |
  'SUCCESS' |
  'ERROR' |
  'INFORM';

const AlertContext = React.createContext<{
  alertState: AlertData,
  showAlert: (payload: AlertData) => void,
  hideAlert: () => void
} | undefined>(undefined);

export const useAlert = () => {
  const context = React.useContext(AlertContext);
  if (context === undefined) {
    throw new Error('useAlert must be used within a AlertProvider');
  }
  return context;
};

export const AlertProvider = ({children}: {children: ReactElement}): ReactElement => {

  const [alertState, setAlertState] = React.useState<AlertData>({
    isOpen: false,
    type: 'info',
    duration: 4000,
    message: ''
  });

  const showAlert = useCallback((payload: AlertData) => {
    setAlertState((state: AlertData) => ({...state, ...payload, isOpen: true}))
  }, [])

  const hideAlert = useCallback(() => {
    setAlertState((state: AlertData) => ({...state, isOpen: false}))
  }, [])

  return (
    <AlertContext.Provider value={{alertState, showAlert, hideAlert}}>
      <Alert {...{...alertState, hideAlert}} />
      {children}
    </AlertContext.Provider>
  );
};
