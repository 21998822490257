import {AxiosResponse} from 'axios';

export type SimpleErrorBody = {
  customError: true;
  errorMessage: string;
}

export type ErrorBody = SimpleErrorBody & {
  commandId: string;
  errorCode: string;
  errorId: string;
  errorMessage: string;
  errorTimestamp: string;
  path: string;
  requestUuid: string;
  systemVersion: string;
  username: string;
}

export class HttpError<T extends SimpleErrorBody> extends Error {
  constructor(public response: AxiosResponse, public error: T) {
    super(HttpError.getHttpError(response, error));
  }

  private static getHttpError = <E extends SimpleErrorBody>(response: AxiosResponse, error: E): string => {
    const statusMessage = response?.status ? '\nStatus: ' + response.status : '';
    const errorMessage = error ? '\n' + JSON.stringify(error) : '';
    return `Http Error${statusMessage}${errorMessage}`;
  };
}