import {GridColDef} from '@mui/x-data-grid';
import {TFunction} from 'i18next';
import {createColDefWithDefaultConfiguration} from '../../shared/utils/dataGridDefaultColumnDefValues';
import dayJs from "dayjs";
import {
  TRANSACTION_DATE_FORMAT,
  TRANSACTION_TIME_FORMAT,
} from "../../shared/constants/format-templates";
import StatusFieldDecorated from './details/StatusFieldDecorated';

export const businessOwnersTableColumnDefinitions = (
  t: TFunction
): Array<GridColDef> =>
  createColDefWithDefaultConfiguration([
    {
      field: "alias",
      headerName: t("BUSINESS_OWNERS.TABLE.COLUMN_HEADERS.USERNAME") ?? "",
    },
    {
      field: "businessName",
      headerName: t("BUSINESS_OWNERS.TABLE.COLUMN_HEADERS.BUSINESS_NAME") ?? "",
    },
    {
      field: "mobileNumber",
      headerName: t("BUSINESS_OWNERS.TABLE.COLUMN_HEADERS.PHONE_NUMBER") ?? "",
    },
    {
      field: "status",
      headerName: "Profile status" ?? "",
      renderCell: (params) => {
        return <StatusFieldDecorated isChangeClosed={true} status={params.row.status} />;
      },
    },
    {
      field: "registeredOn",
      headerName: "Registration date" ?? "",
      valueFormatter: ({ value }) =>
        dayJs(value).format(
          `${TRANSACTION_DATE_FORMAT} ${TRANSACTION_TIME_FORMAT}`
        ),
    },
    {
      field: "deletedOn",
      headerName: "Date deleted" ?? "",
      valueFormatter: ({ value }) => {
        if (value) {
          return dayJs(value).format(
            `${TRANSACTION_DATE_FORMAT} ${TRANSACTION_TIME_FORMAT}`
          );
        } else {
          return "-";
        }
      },
    },
    {
      field: "deletedBy",
      headerName: "Deleted by" ?? "",
    },
  ]);
