import clsx from 'clsx';
import {ReactComponent as IconTick} from '../../../../../assets/icons/icon-tick.svg';
import {ReactComponent as IconX} from '../../../../../assets/icons/icon-x.svg';
import styles from './EnableIndicator.module.scss';

export default function EnableIndicator({isEnabled}: {isEnabled: boolean}) {
  return (
    <div className={clsx(styles.circle, {[styles.circle_enabled]: isEnabled})}>
      {isEnabled ? <IconTick/> : <IconX/>}
    </div>
  );
}