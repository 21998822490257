import { ReactElement, useState, useCallback, useRef, useEffect } from 'react';
import { createRoot, Root } from 'react-dom/client';
import { useTranslation } from 'react-i18next';
import { TransHelper } from '../../shared/utils/trans-helper';
import { GridRowParams } from '@mui/x-data-grid';
import ContentBox from '../shared/content-box/ContentBox';
import NxLoader from '../shared/nxLoader/NxLoader';
import clsx from 'clsx';
import ActivitySearch from './search/ActivitySearch';
import ActivityDetail from './detail/ActivityDetail';
import DataTable from '../shared/table/Table';
import useSingleQuery from '../../shared/hooks/use-single-query.hook';
import usePost from '../../shared/hooks/use-post.hook';
import { downloadCsv } from '../../shared/utils/file-download';
import { PagedResponse } from '../../shared/model/Page.model';
import { ActivitySearchQuery, ActivityDownloadQuery, ActivityRow, ActivityDetails, ActivityType } from './Activity.model'
import { activityTableColumnDefinitions } from './activityTableColumnDefinitions';
import styles from './Activity.module.scss';
import { ReactComponent as IconUp } from '../../assets/icons/icon-chevron-up.svg';
import { ReactComponent as IconDown } from '../../assets/icons/icon-chevron-down.svg';
import { ReactComponent as DownloadIcon } from '../../assets/icons/icon-ic-color-lens.svg';
import { useAuth } from '../../shared/auth/context';
import { hasRequiredPermission } from '../../shared/auth/utils';
import { Permission } from '../../shared/auth/model';


const activityDetailCache: { [key: number]: ActivityDetails } = {};

export default function Activity(): ReactElement {
  const [pageSize, setPageSize] = useState<number | unknown>(10);
  const [searchParams, setSearchParams] = useState<ActivitySearchQuery | undefined>();
  const [selectedRowId, setSelectedRowId] = useState<ActivityRow | null>();
  const [targetRow, setTargetRow] = useState<HTMLDivElement | null>();
  const [isRowDetailOpen, setIsRowDetailOpen] = useState<boolean>(false);
  const detailHeight = useRef<number>(0);
  const tableContainerParentDiv = useRef<HTMLDivElement>();
  const activityRef = useRef<Root>();
  const svgRef = useRef<any>();

  const PrefixedTrans = TransHelper.getPrefixedTrans('ACTIVITY');
  const { t } = useTranslation();
  const { state } = useAuth();
  const isBusinessOwnersOnlyVisible = hasRequiredPermission(state.userGroups ?? [], Permission.ACTIVITY_BUSINESS_OWNERS_ONLY);
  const { data, isLoading, fetch } = useSingleQuery<PagedResponse<ActivitySearchQuery>>(`/admin/system/activities`, null, false);
  const activityDetails = useSingleQuery(`/admin/system/activities/details/${selectedRowId?.id}`, null, false);
  const downloadReport = usePost(`/admin/reports/activities`);
  const heightReduce = 150;
  const heightOffset = 200;

  const submitActivityDownload = useCallback(async () => {
    const downloadParams: ActivityDownloadQuery = {
      ...(searchParams?.activityType && { type: searchParams.activityType }),
      ...(searchParams?.username && { username: searchParams.username }),
      ...(searchParams?.fromDate && { dateFrom: searchParams.fromDate }),
      ...(searchParams?.toDate && { dateTo: searchParams.toDate })
    };
    try {
      downloadReport(downloadParams)
        .then((res) => downloadCsv(res + '', generateActivityReport(searchParams)))
    } catch (error) {
      return Promise.reject(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadReport])

  const submitActivitySearch = useCallback(async (params: ActivitySearchQuery) => {
    try {
      delete searchParams?.pageNo;
      const newParams = { pageSize: String(pageSize), ...searchParams, ...params };
      Object.keys(newParams).forEach((key) => {
        if (newParams[key as keyof ActivitySearchQuery] === '') {
          delete newParams[key as keyof ActivitySearchQuery];
        }
      });
      fetch(new URLSearchParams(newParams));
      setSearchParams(newParams);
      clearActivityDetail();
      clearSelectedRow();
    } catch (error) {
      return Promise.reject(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetch, searchParams, pageSize]);

  const handlePageChange = useCallback((pageNo: number) => {
    submitActivitySearch({ pageNo: String(pageNo) });
  }, [submitActivitySearch])

  const handlePageSizeChange = useCallback((pageSize: number | unknown) => {
    setPageSize(pageSize);
    submitActivitySearch({ pageSize: String(pageSize) });
  }, [submitActivitySearch])

  const showActivityDetail = (rowData: ActivityRow, activityDetail: ActivityDetails, rowDom: HTMLDivElement) => {
    // Initial render of down arrow
    svgRef.current = createRoot(rowDom.querySelectorAll('svg')[1]);
    svgRef.current.render(<IconUp />);
    // Remove detail element, if any is open
    clearActivityDetail();
    // Create reference to the table for detail appending
    const tableContainerDiv = rowDom?.parentElement;
    tableContainerParentDiv.current = tableContainerDiv?.parentElement as HTMLDivElement;

    const appendedDiv = document.createElement('div');
    rowDom?.after(appendedDiv);
    // Render actual detail element
    setTimeout(() => {
      activityRef.current = createRoot(appendedDiv);
      activityRef.current.render(<ActivityDetail row={rowData} activityDetail={activityDetail} getDetailHeight={getDetailHeight} />);
    }, 0);

    setIsRowDetailOpen(true);
  }

  const setDetailRowHeight = () => {
    // Calculate detail height
    const grandParentHeight = tableContainerParentDiv.current?.offsetHeight;
    const newHeight = grandParentHeight ? grandParentHeight + heightOffset : 0;
    tableContainerParentDiv.current!.style.height = `${newHeight}px`;
    // If detail height is too big, make the height change dynamic according to content size
    setTimeout(() => {
      if (detailHeight.current > heightOffset) {
        tableContainerParentDiv.current!.style.height = `${newHeight + (detailHeight.current - heightReduce)}px`;
      }
    }, 0);
  }

  const clearActivityDetail = () => {
    if (isRowDetailOpen) {
      setIsRowDetailOpen(false);
      let reduceHeight = tableContainerParentDiv?.current?.offsetHeight ? tableContainerParentDiv.current.offsetHeight - heightOffset : 0;
      if (detailHeight.current > heightOffset) {
        reduceHeight = reduceHeight - (detailHeight.current - heightReduce);
      }
      tableContainerParentDiv.current!.style.height = `${reduceHeight}px`;
      setTimeout(() => {
        activityRef?.current?.unmount();
      }, 0)
    }
  }

  useEffect(() => {
    if (isBusinessOwnersOnlyVisible) {
      submitActivitySearch({ activityType: ActivityType.BUSINESS_OWNERS })
    } else {
      submitActivitySearch({ pageSize: String(10) })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getDetailHeight = (height: number) => {
      detailHeight.current = height;
      setDetailRowHeight();
  }

  const clearSelectedRow = () => {
    svgRef?.current?.render(<IconDown />);
    setTargetRow(null);
    setSelectedRowId(null);
    setIsRowDetailOpen(false);
  }

  useEffect(() => {
    if (selectedRowId) {
      if (selectedRowId.id in activityDetailCache) {
        showActivityDetail(selectedRowId, activityDetailCache[selectedRowId.id] as ActivityDetails, targetRow as HTMLDivElement);
      } else {
        activityDetails.fetch();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowId])

  useEffect(() => {
    if (selectedRowId) {
      showActivityDetail(selectedRowId, activityDetails.data as ActivityDetails, targetRow as HTMLDivElement)
      activityDetailCache[selectedRowId.id] = activityDetails.data as ActivityDetails;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityDetails.data])

  return (
    <ContentBox header={<PrefixedTrans>BOX_TITLE</PrefixedTrans>}>
      <div className={styles.container}>
        <ActivitySearch isLoading={isLoading} onSubmit={submitActivitySearch} isBusinessOwnersOnlyVisible={isBusinessOwnersOnlyVisible} />
        <div className={styles.dataContainer}>
          {(isLoading || !data) && (
            <div className={clsx(styles.overlay, styles.loaderContainer)}>
              <NxLoader />
            </div>
          )}
          <div className={styles.tableHeader}>
            <div className={styles.countHeader}>Latest Activity ({data?.totalCount})</div>
            {!!data?.result.length && <span className={styles.navButton} onClick={submitActivityDownload}>
              <div className={styles.navButtonIcon}><DownloadIcon /></div>
              <div className={styles.navButtonLabel}>Download</div>
            </span>}
          </div>
          <DataTable
            loading={activityDetails.isLoading}
            pageInfo={{
              pageNo: data?.pageNo ?? 0,
              resultCount: data?.resultCount ?? 0,
              totalCount: data?.totalCount ?? 0
            }}
            pageSize={Number(pageSize)}
            rows={data?.result ?? []}
            onRowClick={(rowParams: GridRowParams): void => {
              if (rowParams.row.id !== selectedRowId?.id) {
                svgRef?.current?.render(<IconDown />);
                const containerDiv = document.querySelector(`div[data-id="${rowParams.id}"]`);
                setTargetRow(containerDiv as HTMLDivElement);
                setSelectedRowId(rowParams.row);
              } else {
                clearSelectedRow();
                clearActivityDetail();
              }
            }}
            onPageNumberChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            columns={activityTableColumnDefinitions(t)}
            noRowsMessage={t('SHARED.NO_RESULTS')}
            hideFooterPagination={false}
            hideFooter={false}
          />
        </div>
      </div>
    </ContentBox>
  );
}


function generateActivityReport(searchParams: ActivitySearchQuery | undefined) {
  const currentDate = new Date().toLocaleDateString();

  if (!searchParams?.username && !searchParams?.activityType && !searchParams?.fromDate && !searchParams?.toDate) {
    return `Activity History CSV Report - ${currentDate}`;
  }
  else if (searchParams.activityType && !searchParams.username && !searchParams.fromDate && !searchParams.toDate) {
    return `Activity History CSV Report - ${searchParams.activityType}`;
  }
  else if (searchParams.activityType && !searchParams.username && searchParams.fromDate && searchParams.toDate) {
    return `Activity History CSV Report - ${searchParams.activityType} - From ${searchParams.fromDate} To ${searchParams.toDate}`;
  }
  else if (searchParams.activityType && searchParams.username && !searchParams.fromDate && !searchParams.toDate) {
    return `Activity History CSV Report - ${searchParams.username} - ${searchParams.activityType}`;
  }
  else if (searchParams?.username && !searchParams?.activityType && !searchParams?.fromDate && !searchParams?.toDate) {
    return `Activity History CSV Report - ${searchParams.username}`;
  } else if (!searchParams?.username && !searchParams?.activityType && searchParams?.fromDate && searchParams?.toDate) {
    return `Activity History CSV Report - From ${searchParams.fromDate} To ${searchParams.toDate}`;
  } else if (searchParams?.username && !searchParams?.activityType && searchParams?.fromDate && searchParams?.toDate) {
    return `Activity History CSV Report - ${searchParams?.username} From ${searchParams?.fromDate} To ${searchParams?.toDate}`;
  } else {
    return `Activity History CSV Report - ${searchParams?.username} - ${searchParams.activityType} From ${searchParams?.fromDate} To ${searchParams?.toDate}`;
  }
}