import {Divider} from '@mui/material';
import React, {ReactElement} from 'react';
import {TransHelper} from '../../../shared/utils/trans-helper';
import AppLogo from '../app-logo/AppLogo';
import LoginInfo from './login-info/LoginInfo';
import styles from './MainContainer.module.scss';
import NavigationBar from './navigation-bar/NavigationBar';

type MainContainerProps = {
  children: ReactElement
}

export default function MainContainer({children}: MainContainerProps): ReactElement {

  const PrefixedTrans = TransHelper.getPrefixedTrans("MAIN_CONTAINER");

  return (
    <div className={styles.mainContainer}>
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <AppLogo />
          <LoginInfo />
        </div>
        <Divider flexItem/>
        <NavigationBar />
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.content}>
          {children}
        </div>
      </div>
      <div className={styles.footer}>
        <PrefixedTrans>FOOTER_TEXT</PrefixedTrans>
      </div>
    </div>
  );
}