import { Formik, FormikHelpers, FormikProps } from 'formik';
import { object, SchemaOf, string } from 'yup';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useTrans from '../../../../shared/hooks/use-trans.hook';
import usePost from '../../../../shared/hooks/use-post.hook';
import { resetPassword, sendforgotPasswordCode } from '../../../../shared/auth/context';
import NxButton from '../../../shared/nxButton/NxButton';
import { NxFormikInput } from '../../../shared/nxInput/NxFormikInput';
import NxPasswordInput from '../../../shared/nxPasswordInput/NxPasswordInput';
import PasswordErrorField from '../../../shared/password-error-field/PasswordErrorField';
import ChangePasswordResult from '../change-password-result/ChangePasswordResult';
import CountdownTimer from '../../../shared/countdown-timer/CountdownTimer';
import { ResetPasswordFormFields, PasswordFields } from './ResetPasswordForm.model';
import { useAlert } from '../../../../shared/context/alert';
import { Divider } from '@mui/material';
import { ReactComponent as Logo } from '../../../../assets/images/logo-login-header.svg';
import {
    checkLowercase,
    checkUppercase,
    checkNumber,
    checkSpecialChar,
    checkInvalidChar
} from '../../../../shared/utils/passwordValidationUtils';
import styles from './ResetPasswordForm.module.scss';

type ResetPasswordFormProps = {
    user: { username: string; email: string };
}

export default function ResetPasswordForm({ user }: ResetPasswordFormProps): React.ReactElement {

    const { t } = useTranslation();
    const { showAlert } = useAlert();
    const navigate = useNavigate();
    const SharedTrans = useTrans('LOGIN');
    const sendEmail = usePost(`/admin/backoffice/reset-password-confirmation-email`);
    const [isPasswordTooltipEnabled, setIsPasswordTooltipEnabled] = useState<boolean>(false);
    const [result, setResult] = useState<'success' | 'fail' | ''>('');
    const [codeAttempts, setCodeAttempts] = useState<number>(3);
    const [invalidAttempts, setInvalidAttempts] = useState<number>(0);
    const [timerActive, setTimerActive] = useState<boolean>(false);

    const submit = async (values: ResetPasswordFormFields, actions: FormikHelpers<ResetPasswordFormFields>): Promise<void> => {
        try {
            if (values.password === values.confirmPassword) {
                await resetPassword(user.username, values.code, values.password);
                sendEmail({ email: user.email, username: user.username });
                setResult('success');
            } else {
                throw new Error();
            }
        } catch (err: any) {
            if (err.name.includes("CodeMismatchException")) {
                actions.setFieldError('code', t("LOGIN.INVALID_CODE"));
                setInvalidAttempts(invalidAttempts + 1);
            }
            else if (err.name.includes("LimitExceededException")) {
                actions.setFieldError('code', t("LOGIN.LIMIT_EXCEEDED"));
                setInvalidAttempts(invalidAttempts + 1);
            }
            else if (err.name.includes("ExpiredCodeException")) {
                actions.setFieldError('code', t("LOGIN.EXPIRED_CODE"));
                setInvalidAttempts(invalidAttempts + 1);
            }
            else {
                actions.setFieldError('confirmPassword', t("LOGIN.PASSWORD_DONT_MATCH"));
            }

            if (invalidAttempts === 2) {
                navigate('/login');
            }
        }
    };

    const loginFormSchema: SchemaOf<PasswordFields> = object({
        password: string()
            .defined(t('SHARED.FORM.ERROR.REQUIRED'))
            .required()
            .test('min', t('PASSWORD.LENGTH'), function (value) {
                return value?.length! >= 8
            })
            .test('number', t('PASSWORD.NUMBER'), function (value) {
                return checkNumber(value!)
            })
            .test('special', t('PASSWORD.SPECIAL'), function (value) {
                return checkSpecialChar(value!)
            })
            .test('lower', t('PASSWORD.LOWERCASE'), function (value) {
                return checkLowercase(value!)
            })
            .test('invalid', t('PASSWORD.INVALID'), function (value) {
                return !checkInvalidChar(value!)
            })
            .test('upper', t('PASSWORD.UPPERCASE'), function (value) {
                return checkUppercase(value!)
            }),
        confirmPassword: string()
            .defined(t('SHARED.FORM.ERROR.REQUIRED'))
            .required()
            .test('min', t('PASSWORD.LENGTH'), function (value) {
                return value?.length! >= 8
            })
            .test('number', t('PASSWORD.NUMBER'), function (value) {
                return checkNumber(value!)
            })
            .test('special', t('PASSWORD.SPECIAL'), function (value) {
                return checkSpecialChar(value!)
            })
            .test('lower', t('PASSWORD.LOWERCASE'), function (value) {
                return checkLowercase(value!)
            })
            .test('invalid', t('PASSWORD.INVALID'), function (value) {
                return !checkInvalidChar(value!)
            })
            .test('upper', t('PASSWORD.UPPERCASE'), function (value) {
                return checkUppercase(value!)
            }),
        generalError: string().notRequired(),
    }).defined();

    function showPasswordTooltip() {
        setIsPasswordTooltipEnabled(true);
    }

    async function sendCode() {
        try {
            await sendforgotPasswordCode(user.username);
            setCodeAttempts(codeAttempts - 1);
            setTimerActive(true);
            showAlert({
                type: 'success',
                message: t(`LOGIN.SUCCESS_CODE_SEND`)
            });
        } catch (err: any) {
            showAlert({
                type: 'error',
                message: t(`LOGIN.FAILED_CODE_SEND`)
            });
        }
    }

    function handleTimerExpired() {
        setTimerActive(false);
    };

    function handleKeyPress(e: any) {
        const keyCode = e.keyCode || e.which;

        // Allow digits, backspace, delete, arrow keys, tab, and copy-paste actions
        if (
            (keyCode < 48 || keyCode > 57) && // Not a digit
            keyCode !== 8 && // Not backspace
            keyCode !== 9 && // Not tab
            keyCode !== 46 && // Not delete
            keyCode !== 37 && // Not left arrow
            keyCode !== 39 && // Not right arrow
            !(e.ctrlKey || e.metaKey) // Not copy-paste actions
        ) {
            e.preventDefault();
        }
    };

    function maskEmail(email: string) {
        const [user, domain] = email.split('@');
        const maskedUser = user.length > 2
            ? user[0] + '*'.repeat(user.length - 2) + user[user.length - 1]
            : user;

        return maskedUser + '@' + domain;
    }

    function resendValidAttempts() {
        return (<>
            {t(`LOGIN.CODE_NOT_RECEIVED`)}{' '}

            {(codeAttempts > 0 && !timerActive) &&
                <span className={styles.codeSend} onClick={sendCode}>
                    {t(`LOGIN.RESEND_CODE`)}
                </span>}{' '}

            {(codeAttempts > 0 && codeAttempts < 3) && <CountdownTimer
                key={codeAttempts}
                onTimerExpired={handleTimerExpired}
                time={2}
                activeMessage={t(`LOGIN.CODE_TIMER_TEXT`)} />}{' '}

            ({codeAttempts} {codeAttempts === 1 ? t(`LOGIN.ATTEMPT`) : t(`LOGIN.ATTEMPTS`)} {t(`LOGIN.LEFT`)})
        </>)
    }

    function resendNoAttempts() {
        return (<>{t(`LOGIN.EXCEEDED_ATTEMPTS`)}</>)
    }

    const LoginForm = ({
        values,
        handleSubmit,
        errors,
        handleChange,
        isSubmitting
    }: FormikProps<ResetPasswordFormFields>): ReactElement => (
        <form onSubmit={handleSubmit} className={styles.form}>
            <NxFormikInput name='code'
                autoComplete='new-code'
                maxLength={6}
                onKeyDown={handleKeyPress}
                id={'code-input'}
                className={styles.input}
                disabled={isSubmitting}
                label={<SharedTrans>CODE</SharedTrans>}
            />
            <div className={styles.code}>
                <span>
                    {codeAttempts > 0 ? resendValidAttempts() : resendNoAttempts()}
                </span>
            </div>
            <Divider className={styles.divider} />
            <div style={{ width: '100%' }} onFocus={showPasswordTooltip}>
                <NxPasswordInput name='password'
                    autoComplete='new-password'
                    className={styles.inputPassword}
                    disabled={isSubmitting}
                    label={<SharedTrans>NEW_PASSWORD</SharedTrans>}
                    // error={errors.password}
                    value={values.password}
                    onChange={handleChange}
                />
            </div>
            {isPasswordTooltipEnabled && <div className={styles.passwordTooltip}><PasswordErrorField password={values.password} /></div>}
            <NxPasswordInput name='confirmPassword'
                className={styles.input}
                disabled={isSubmitting}
                label={<SharedTrans>CONFIRM_PASSWORD</SharedTrans>}
                error={errors.confirmPassword}
                value={values.confirmPassword}
                onChange={handleChange}
            />
            <div className={styles.signInRow}>
                <NxButton type='submit' className={styles.button} loaded={!isSubmitting}>
                    <SharedTrans>CHANGE_PASSWORD</SharedTrans>
                </NxButton>
            </div>
        </form>
    );

    const formInitValues = {
        code: '',
        password: '',
        confirmPassword: '',
        generalError: ''
    };

    return (
        <>
            {result !== "" ? <ChangePasswordResult result={result} /> : <div className={styles.container}>
                <div className={styles.formContainer}>
                    <Logo />
                    <div className={styles.header}>{t(`LOGIN.NEW_PASSWORD`)}</div>
                    <span className={styles.description}>{t(`LOGIN.CHANGE_PASSWORD_DESC`, { email: maskEmail(user.email) })}</span>
                    {/* Dummy hidden form to trick browser/password manager autofill */}
                    <form>
                        <div className={styles.hiddenForm}>
                            <label htmlFor="username-1"></label>
                            <input tabIndex={-1} type="text" className={styles.hiddenForm} name="username-1" id="username-1"></input>
                            <label htmlFor="password-1"></label>
                            <input tabIndex={-1} type="password" name="password-1" className={styles.hiddenForm} id="password-1"></input>
                        </div>
                    </form>

                    <Formik<ResetPasswordFormFields> initialValues={formInitValues}
                        onSubmit={submit}
                        validateOnMount={false}
                        validateOnBlur={false}
                        validateOnChange={false}
                        validationSchema={loginFormSchema}
                    >
                        {LoginForm}
                    </Formik>
                </div>
                <span className={styles.footer}>{t(`MAIN_CONTAINER.FOOTER_TEXT`)}</span>
            </div>}
        </>
    );
}
