import {Formik, FormikHelpers, FormikProps} from 'formik';
import React, {ReactElement} from 'react';
import {Permission} from '../../../shared/auth/model';
import useTrans from '../../../shared/hooks/use-trans.hook';
import NxInput from '../../shared/nxInput/NxInput';
import NxButton from '../../shared/nxButton/NxButton';
import {Property} from '../SystemProperties.model';
import {PropertyUpdateFormFields} from './PropertyUpdateForm.model';
import styles from './PropertyUpdateForm.module.scss';

type PropertyUpdateFormProps = {
  property: Property;
  fetchIsLoading: boolean;
  onSubmit: (fields: Property, callBack: () => void) => Promise<void>;
};

export default function PropertyUpdateForm({
                                             property,
                                             fetchIsLoading,
                                             onSubmit
                                           }: PropertyUpdateFormProps): React.ReactElement {

  const SharedTrans = useTrans('SYSTEM_PROPERTIES');

  const [updateIsLoading, setUpdateIsLoading] = React.useState<boolean>(false);

  const submitPropertyChange = async (values: PropertyUpdateFormFields,
                                      actions: FormikHelpers<PropertyUpdateFormFields>): Promise<void> => {
    onSubmit({code: values.code, id: values.id, value: values.value}, () => setUpdateIsLoading(false))
      .then(() => setUpdateIsLoading(true))
      .catch(error => actions.setFieldError('generalError', error));
  };

  const propertyUpdateForm = ({
                                values,
                                handleSubmit,
                                errors,
                                handleChange,
                                isSubmitting,
                                dirty
                              }: FormikProps<PropertyUpdateFormFields>): ReactElement => (
    <form onSubmit={handleSubmit} className={styles.form}>
      <div className={styles.fieldContainer}>{values.code}</div>
      <div className={styles.fieldContainer}>
        <NxInput name='value'
                 className={styles.input}
                 disabled={isSubmitting || fetchIsLoading || updateIsLoading}
                 error={errors.value}
                 value={values.value}
                 onChange={handleChange}
                 permission={Permission.SETTINGS_WRITE} />
        <NxButton type='submit'
                  className={styles.button}
                  disabled={!dirty || fetchIsLoading}
                  loaded={!isSubmitting && !updateIsLoading}
                  permission={Permission.SETTINGS_WRITE}>
          <SharedTrans>UPDATE_LABEL</SharedTrans>
        </NxButton>
      </div>
    </form>
  );

  const formInitValues = {
    ...property,
    value: property.value || '',
    generalError: ''
  };

  return (
    <Formik<PropertyUpdateFormFields>
      initialValues={formInitValues}
      onSubmit={submitPropertyChange}
      enableReinitialize
    >
      {propertyUpdateForm}
    </Formik>
  );
}
