import React from 'react';
import {Trans} from 'react-i18next';

interface Props {
  children: string;
  values?: Record<string, unknown>;
  components?: readonly React.ReactElement[] | {readonly [tagName: string]: React.ReactElement};
}

export const TransHelper = {
  getPrefixedTrans: (prefix: string) => {
    return function PrefixTrans({children, values, components}: Props): React.ReactElement {
      return <Trans values={values} components={components}>{`${prefix}.${children}`}</Trans>;
    };
  }
};
