export const downloadCsv = (csv: string, filename: string) => {
  const temporaryLink = document.createElement("a");
  temporaryLink.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
  temporaryLink.target = "_blank";
  temporaryLink.download = filename;
  temporaryLink.click();
  temporaryLink.remove();
};

export const downloadXLSX = (response: string, reportGroupTrans: string) => {
  const blob = new Blob([response], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet.main+xml;charset=UTF-8",
  });

  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  /*
  TODO: Use server response header filename
  */
  switch (reportGroupTrans) {
    case "Customer Feedback":
      link.download = "Feedback_Report.xlsx";
      break;
    case "Deleted Profile":
      link.download = "Deleted Accounts.xlsx";
      break;
    case "Registered Accounts":
      link.download = "Registereduser.xlsx";
      break;
    case "Invoices":
      link.download = "Invoices.xlsx";
      break;
    case "Payroll":
      link.download = "Payroll.xlsx";
      break;
  }

  link.click();

  setTimeout(() => {
    window.URL.revokeObjectURL(url);
  }, 100);
};
