import {ReactElement, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useAlert} from '../../shared/context/alert';
import usePost from '../../shared/hooks/use-post.hook';
import useSingleQuery from '../../shared/hooks/use-single-query.hook';
import {TransHelper} from '../../shared/utils/trans-helper';
import ContentBox from '../shared/content-box/ContentBox';
import PropertyUpdateForm from './property-update-form/PropertyUpdateForm';
import {Property, PropertyQuery} from './SystemProperties.model';
import styles from './SystemProperties.module.scss';

export default function SystemProperties(): ReactElement {

  const {t} = useTranslation();

  const PrefixedTrans = TransHelper.getPrefixedTrans('SYSTEM_PROPERTIES');

  const {data, isLoading, fetch}: PropertyQuery = useSingleQuery(`/admin/system/settings`);

  const updateProperty = usePost(`/admin/system/settings/update`);

  const {showAlert} = useAlert();

  const submitPropertyUpdate = useCallback(async (fields: Property, callBack: () => void) => {
    try {
      updateProperty({id: fields.id, value: fields.value})
        .then(fetch)
        .then(() => showAlert({
          type: 'success',
          message: t('SYSTEM_PROPERTIES.MESSAGE_CODE.PROPERTY_UPDATE_SUCCESS', {propertyName: fields.code})
        }))
        .finally(callBack);
    } catch (error) {
      showAlert({type: 'warning', message: t('SYSTEM_PROPERTIES.MESSAGE_CODE.PROPERTY_UPDATE_FAIL')});
      return Promise.reject(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContentBox header={<PrefixedTrans>BOX_TITLE</PrefixedTrans>}>
      <div className={styles.container}>
        <div className={styles.formHeader}>
          <div><PrefixedTrans>CODE</PrefixedTrans></div>
          <div><PrefixedTrans>VALUE</PrefixedTrans></div>
        </div>
        {data?.map((property: Property, index: number) => (
          <PropertyUpdateForm
            key={property.id}
            property={property}
            fetchIsLoading={isLoading}
            onSubmit={submitPropertyUpdate}
          />
        ))}
      </div>
    </ContentBox>
  );
}
