import {CancelTokenSource} from 'axios';
import {useAuth} from '../auth/context';
import {Request} from '../model/Request.model';
import HttpService from '../services/http.service';
import useApiCatch from './api-error-catch.hook';

/**
 * Dedicated hook for perform GET request.
 *
 * @param url - target url for API request.
 *
 * T - type of data returned by api call.
 *
 * @return function which allows execute configured GET request. It has following arguments:
 * cancelTokenSource - CancelTokenSource which allows to abort request, it can be null or undefined.
 */
export default function useGet<T>(
  url: string
): (params?: URLSearchParams | null, cancelTokenSource?: CancelTokenSource | null) => Promise<T> {

  const catchError = useApiCatch();
  const {state} = useAuth();

  return (params?: URLSearchParams | null, cancelTokenSource?: CancelTokenSource | null): Promise<T> => {
    const request: Request = {
      url,
      method: 'GET',
      params,
      cancelTokenSource,
      sessionToken: state.apiToken
    };

    return HttpService.request<T>(request)
      .catch(catchError);
  };
}
