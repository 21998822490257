import clsx from 'clsx';
import {HTMLAttributes, ReactElement} from 'react';
import {ReactComponent as InfoIcon} from '../../../assets/icons/icon-info.svg';
import styles from './SystemInfoText.module.scss';

type SystemInfoTextProps = Pick<HTMLAttributes<HTMLDivElement>, 'children' | 'className'> & {
  icon?: ReactElement;
};

export default function SystemInfoText({children, className, icon = <InfoIcon />}: SystemInfoTextProps) {
  return (
    <div className={clsx(styles.box, className)}>
      {icon}
      {children}
    </div>
  );
}