import {CancelTokenSource} from 'axios';
import {useAuth} from '../auth/context';
import {Request} from '../model/Request.model';
import HttpService from '../services/http.service';
import useApiCatch from './api-error-catch.hook';

/**
 * Dedicated hook for perform DELETE request.
 *
 * @param url - target url for API request.
 *
 * E - type of data passed as PUT body.
 *
 * @return function which allows execute configured DELETE request. It has following arguments:
 * data - Data of type E which would be passed as DELETE body, it can be null or undefined.
 * cancelTokenSource - CancelTokenSource which allows to abort request, it can be null or undefined.
 */
export default function useDelete<E, T>(url: string): (data?: E,
                                                       cancelTokenSource?: CancelTokenSource | null) => Promise<T> {

  const catchError = useApiCatch();
  const {state} = useAuth();

  return (data?: E, cancelTokenSource?: CancelTokenSource | null): Promise<T> => {
    const request: Request = {
      url,
      method: 'DELETE',
      data,
      cancelTokenSource,
      sessionToken: state.apiToken
    };

    return HttpService.request<T>(request)
      .catch(catchError);
  };
}
