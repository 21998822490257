import axios, {CancelTokenSource} from 'axios';
import {useEffect, useState} from 'react';
import {useAuth} from '../auth/context';
import {Request} from '../model/Request.model';
import HttpService, {ABORT_ERROR_NAME} from '../services/http.service';
import useApiCatch from './api-error-catch.hook';

/**
 * Helpful hook for getting data from API directly after component rendered.
 *
 * @param url - target url for API request.
 * @param mapFunction - function which should be used to map received data e.g. TO object to object.
 * It can be null or undefined, in case of response will be cast to T generic type.
 * @param shouldExecute - indicates when hook should perform request.
 * @param params - Map of query parameters (url parameters), defaulted to empty if undefined.
 *
 * T - type of data returned by hook execution.
 * E - type of data returned by api call.
 *
 * @return data of type T (after mapping or casting) returned by api call.
 */
export default function useSingleQuery<T, E = T>(
  url: string,
  mapFunction?: ((arg: E) => T) | null,
  shouldExecute?: boolean,
  params: URLSearchParams = {} as URLSearchParams
): {data: T | undefined, isLoading: boolean, fetch: (params?: URLSearchParams) => Promise<void>;} {

  const [data, setData] = useState<T | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const catchError = useApiCatch();
  const {state} = useAuth();

  const fetch = async (searchParams: URLSearchParams = {} as URLSearchParams,
                       cancelTokenSource?: CancelTokenSource): Promise<void> => {

    const request: Request = {
      method: 'GET',
      url,
      params: searchParams,
      cancelTokenSource,
      sessionToken: state.apiToken
    };

    setIsLoading(true);

    await HttpService.request<E>(request)
      .then(response => setData(mapFunction ? mapFunction(response) : response as unknown as T))
      .catch(e => e.error?.errorCode === ABORT_ERROR_NAME ? Promise.resolve() : catchError(e))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (shouldExecute === false) {
      return;
    }
    const cancelTokenSource = axios.CancelToken.source();

    fetch(params, cancelTokenSource);

    return cancelTokenSource.cancel;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldExecute]);

  return {data, isLoading, fetch};
}
