import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import {ErrorBody, HttpError} from '../model/Error.model';
import {Request} from '../model/Request.model';

export const ABORT_ERROR_NAME = 'AbortError';
const API_HOST = process.env.NODE_ENV !== 'development' ? process.env.REACT_APP_API_URL : '';
const API_URL = `${API_HOST}/api`;

axios.interceptors.response.use(
  response => response,
  error => {
    const errorBody = axios.isCancel(error) ?
      {errorCode: ABORT_ERROR_NAME} as ErrorBody
      : error.response.data;

    return Promise.reject(new HttpError(error.response, errorBody));
  }
);

const getResponseData = async <ResType>(axiosRequest: Promise<AxiosResponse<ResType>>): Promise<ResType> => {

  const response = await axiosRequest;

  return response.data;
};

class HttpService {
  request<ResType>(request: Request): Promise<ResType> {
    const {
      url,
      method,
      data,
      params,
      sessionToken,
      cancelTokenSource,
      responseType,
    } = request;

    return getResponseData<ResType>(
      axios.request({
        url: API_URL + url,
        method,
        data,
        responseType,
        ...(params && {params}),
        ...(cancelTokenSource && {cancelToken: cancelTokenSource.token}),
        headers: {
          ...(sessionToken && {'Authorization': `Bearer ${sessionToken}`})
        }
      } as AxiosRequestConfig)
    );
  }
}

export default new HttpService();
