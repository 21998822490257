import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useAlert} from '../../../../shared/context/alert';
import usePost from '../../../../shared/hooks/use-post.hook';
import {SimpleDetailsDataTable} from '../../../shared/table/Table';
import {BusinessOwnerAuthData} from '../BusinessOwnerDetails.model';
import {mobileAppAccessTableColumnDefinitions} from './mobileAppAccessTableColumnDefinitions';

export type AuthDataTableRow = BusinessOwnerAuthData & {
  isLoading: boolean;
  changeLockStatus: (authId: AuthDataTableRow) => Promise<void>;
};

type MobileAppAccessProps = {
  businessOwnerAuthData: BusinessOwnerAuthData[];
  isDeletedAccount: boolean;
};

export default function MobileAppAccessTable({ businessOwnerAuthData, isDeletedAccount }: MobileAppAccessProps) {

  const {t} = useTranslation();
  const [authDataRows] = useState<AuthDataTableRow[]>(getMappedDataRows());
  const enableAuthData = usePost('/admin/customers/auth/enable');
  const disableAuthData = usePost('/admin/customers/auth/disable');

  const {showAlert} = useAlert();

  function getMappedDataRows(): AuthDataTableRow[] {
    return businessOwnerAuthData.map<AuthDataTableRow>(
      authData => ({...authData, isLoading: false, changeLockStatus})
    ) ?? [];
  }

  async function changeLockStatus(authDataTableRow: AuthDataTableRow): Promise<void> {
    authDataTableRow.isLoading = true;

    try {
      await switchMobileAppAccessStatusOnServer(authDataTableRow);
    } catch (e) {
      showAlert({type: 'error', message: t('BUSINESS_OWNERS.DETAILS.MOBILE_APP_ACCESS.ENABLE_ERROR')});
      console.error(e);
    }

    authDataTableRow.isLoading = false;

    return Promise.resolve();
  }

  async function switchMobileAppAccessStatusOnServer(authDataTableRow: AuthDataTableRow) {
    if (authDataTableRow.enabled) {
      await disableAuthData({'customerAuthId': authDataTableRow.authDataId});
      showAlert({type: 'success', message: t('BUSINESS_OWNERS.DETAILS.MOBILE_APP_ACCESS.DISABLE_SUCCESS')});
    } else {
      await enableAuthData({'customerAuthId': authDataTableRow.authDataId});
      showAlert({type: 'success', message: t('BUSINESS_OWNERS.DETAILS.MOBILE_APP_ACCESS.ENABLE_SUCCESS')});
    }
    authDataTableRow.enabled = !authDataTableRow.enabled;
  }

  return (
    <SimpleDetailsDataTable getRowId={(row: BusinessOwnerAuthData) => row.authDataId}
      rows={authDataRows}
      pageSize={authDataRows.length}
      columns={mobileAppAccessTableColumnDefinitions(t, isDeletedAccount)}
      noRowsMessage={t('BUSINESS_OWNERS.TABLE.NO_RESULT_MESSAGE')} />
  );
}
