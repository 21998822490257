import { useState, useEffect } from 'react';

type CountdownTimerProps = {
    time: number;
    activeMessage?: string;
    doneMessage?: string;
    onTimerExpired?: () => void;
}


export default function CountdownTimer({ time, activeMessage, doneMessage, onTimerExpired } : CountdownTimerProps) {
    const [timeLeft, setTimeLeft] = useState(time * 60);

    useEffect(() => {
        if (timeLeft <= 0 && onTimerExpired) {
            onTimerExpired();
            return;
        }

        const timer = setTimeout(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);

        return () => {
            clearTimeout(timer);
        };
    }, [timeLeft, onTimerExpired]);

    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;

    return (
        <span>
            {timeLeft > 0
                ? `${activeMessage} ${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
                : doneMessage}
        </span>
    );
};