import { ReactElement } from "react";
import formatString from "../../../shared/utils/stringFormatter";

const mapStatusToStyle = (status?: string) => {
    switch (status) {
        case "ACTIVE":
            return '#21AFC0';
        case "INACTIVE":
            return '#FF9A33';
        case "CLOSED":
            return '#778699';
        case "SUSPENDED":
            return '#F84C1E';
    }
};

export default function StatusFieldDecorated({ status, isDeletedAccount, isChangeClosed = false }: any): ReactElement {
    const color = isDeletedAccount ? '#B1BECF' : mapStatusToStyle(status);

    if (isChangeClosed && status === "CLOSED") {
        status = "Account Deleted"
    }

    return (
        <span style={{ color }}>{formatString(status)}</span>
    );
}