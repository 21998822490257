import {MenuItem} from '@mui/material';
import MuiPagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import {ReactElement, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import NxGenericTextField from '../../nxGenericTextField/NxGenericTextField';
import {TableProps} from '../Table';
import styles from './TableFooter.module.scss';

const TableFooter = (props: Omit<TableProps, 'tableFooter'>): ReactElement => {

  const {
    pageInfo,
    pageSize = 10,
    pageSizeOptions = [10, 15, 20],
    onPageNumberChange,
    onPageSizeChange
  } = props;

  const {t} = useTranslation();

  const {from, to, totalPages} = useMemo(() => ({
    from: (pageInfo?.pageNo ?? 0) * pageSize + 1,
    to: (pageInfo?.pageNo ?? 0) * pageSize + (pageInfo?.resultCount ?? 0),
    totalPages: Math.ceil((pageInfo?.totalCount ?? 0) / pageSize)
  }), [pageInfo, pageSize]);

  return (
    <div className={styles.footer}>
      <Stack direction='row' justifyContent='space-between' alignItems='center' width='100%'>
        <div className={styles.pageSizeSelect}>
          <NxGenericTextField select value={String(pageSize)} onChange={(e) => onPageSizeChange?.(e.target.value)}>
            {pageSizeOptions.map((number: number) => (
              <MenuItem key={number} value={number}>{number}</MenuItem>
            ))}
          </NxGenericTextField>
          <div>{t('SHARED.ITEMS_PER_PAGE')}</div>
        </div>
        <MuiPagination
          page={(pageInfo?.pageNo ?? 0) + 1}
          count={totalPages}
          siblingCount={1}
          boundaryCount={1}
          onChange={(event, page) => onPageNumberChange?.(page - 1, event)}
        />
        <div>{t('SHARED.TABLE_PAGE_RESULTS', {from, to, totalCount: pageInfo?.totalCount})}</div>
      </Stack>
    </div>
  );
};

export default TableFooter;