export const lowercaseRegex = /^(?=.*[a-z])/;
export const uppercaseRegex = /^(?=.*[A-Z])/;
export const numberRegex = /^(?=.*[0-9])/;
//eslint-disable-next-line
export const specialCharRegex = /^(?=.*[~!@#$%^&*()_+`';\]\[{},.<>\/?])/;
//eslint-disable-next-line
export const invalidCharRegex = /[^A-Za-z0-9~!@#$%^&*()_+`';\]\[{},.<>\/?]/;

export const checkLowercase = (password: string): boolean => {
  return lowercaseRegex.test(password);
};
export const checkUppercase = (password: string): boolean => {
  return uppercaseRegex.test(password);
};
export const checkNumber = (password: string): boolean => {
  return numberRegex.test(password);
};
export const checkSpecialChar = (password: string): boolean => {
  return specialCharRegex.test(password);
};
export const checkInvalidChar = (password: string): boolean => {
  return invalidCharRegex.test(password);
};
