import clsx from 'clsx';
import dayjs from 'dayjs';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {Outlet, useNavigate} from 'react-router-dom';
import {TransHelper} from '../../shared/utils/trans-helper';
import ContentBox from '../shared/content-box/ContentBox';
import styles from './Dictionaries.module.scss';

export default function Dictionaries(): ReactElement {

  const PrefixedTrans = TransHelper.getPrefixedTrans('DICTIONARIES.MAIN');
  const navigate = useNavigate();
  const {t} = useTranslation();

  const tableItems: string[] = [
    'INSTAPAY_BANK_CODE',
    'INSTAPAY_FINTECH_BANK_CODE',
    'PESONET_BANK_CODE'
  ];

  return (
    <ContentBox header={<PrefixedTrans>BOX_TITLE</PrefixedTrans>}>
      <div>
        <div className={clsx(styles.dictionaryRow, styles.dictionaryRow_header)}>
          <div className={styles.noCell}>
            No
          </div>
          <div className={styles.nameCell}>
            Names
          </div>
        </div>
        {
          tableItems.map((item, index) => (
            <div className={styles.dictionaryRow}
                 key={`${index}_${dayjs().millisecond()}`}
                 onClick={() => navigate(item)}>
              <div className={styles.noCell}>{index + 1}</div>
              <div className={styles.nameCell}>{t(`DICTIONARIES.CODE.${item}`)}</div>
            </div>
          ))
        }
        <Outlet />
      </div>
    </ContentBox>
  );
}
