import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import NxButton from '../../../shared/nxButton/NxButton';
import styles from './DeleteDialog.module.scss';
import clsx from 'clsx';

export default function GoalActiveDialog({ open, onClose, onConfirm }: any) {
    const save = async (): Promise<void> => {
        onConfirm()
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <div className={styles.container}>
                <DialogTitle className={styles.headerText}>
                    {"Business Goal Account is still active"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText className={styles.text}>
                        {"If you wish to delete this profile, kindly close the Business Goal account first."}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <div className={styles.buttonSet}>
                        <NxButton className={clsx(styles.button)}
                            onClick={save}
                        >
                            {"Okay, Got it"}
                        </NxButton>
                    </div>
                </DialogActions>
            </div>
        </Dialog>
    );
}