import {IconButton} from '@mui/material';
import React from 'react';
import {ReactComponent as PasswordNotVisible} from '../../../assets/icons/password-not-visible.svg';
import {ReactComponent as PasswordVisible} from '../../../assets/icons/password-visible.svg';
import styles from './PasswordVisibilityButton.module.scss';

interface PasswordVisibilityButtonProps {
  isVisible: boolean;
  setIsVisible: (state: boolean) => void;
}

export const PasswordVisibilityButton = (
  {isVisible, setIsVisible}: PasswordVisibilityButtonProps
): React.ReactElement => {

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>): void => event.preventDefault();

  const iconClasses = {root: styles.icon};

  return (
    <IconButton
      aria-label='toggle password visibility'
      onClick={(): void => setIsVisible(!isVisible)}
      classes={iconClasses}
      onMouseDown={handleMouseDownPassword}>
      {isVisible ? <PasswordVisible /> : <PasswordNotVisible />}
    </IconButton>
  );
};
