import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {TransHelper} from '../../../../shared/utils/trans-helper';
import NxButton, {NxButtonVariant} from '../../../shared/nxButton/NxButton';
import NxSelect, {NxSelectOption} from '../../../shared/nxSelect/NxSelect';
import {TransactionStatus} from '../../Transactions.model';
import styles from './StatusChangeDialog.module.scss';

type StatusChangeDialogProps = {
  open: boolean;
  onClose: () => void;
  onSave: (status: TransactionStatus) => Promise<void>;
}

export default function StatusChangeDialog({open, onClose, onSave}: StatusChangeDialogProps) {

  const PrefixedTrans = TransHelper.getPrefixedTrans('TRANSACTIONS.DETAILS');
  const SharedPrefixedTrans = TransHelper.getPrefixedTrans('SHARED');

  const {t} = useTranslation();
  const [selectedStatus, setSelectedStatus] = useState<TransactionStatus | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const selectOptions: NxSelectOption<TransactionStatus>[] = [
    {
      value: TransactionStatus.SUCCESS,
      label: t('TRANSACTIONS.TRANS_STATUSES.SUCCESS')
    },
    {
      value: TransactionStatus.FAILURE,
      label: t('TRANSACTIONS.TRANS_STATUSES.FAILURE')
    }
  ];

  const save = async (): Promise<void> => {
    if (selectedStatus) {
      setIsLoading(true);
      await onSave(selectedStatus)
        .then(onClose)
        .catch(() => setIsLoading(false));
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <PrefixedTrans>CHANGE_STATUS_DIALOG.TITLE</PrefixedTrans>
      </DialogTitle>
      <DialogContent>
        <DialogContentText marginBottom={'1.5rem'}>
          <PrefixedTrans>CHANGE_STATUS_DIALOG.STATUS_CHANGE_HINT</PrefixedTrans>
        </DialogContentText>
        <NxSelect options={selectOptions}
                  value={selectedStatus}
                  disabled={isLoading}
                  onChange={value => setSelectedStatus(value)}
                  label={t('SHARED.STATUS')} />
      </DialogContent>
      <DialogActions>
        <NxButton className={styles.button}
                  onClick={onClose}
                  variant={NxButtonVariant.TEXT}
                  disabled={isLoading}>
          <SharedPrefixedTrans>CANCEL</SharedPrefixedTrans>
        </NxButton>
        <NxButton className={styles.button}
                  onClick={save}
                  disabled={isLoading || !selectedStatus}
                  loaded={!isLoading}>
          <SharedPrefixedTrans>SAVE</SharedPrefixedTrans>
        </NxButton>
      </DialogActions>
    </Dialog>
  );
}