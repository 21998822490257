import {
  ClassNameMap,
  InputBaseClassKey,
  InputBaseComponentProps,
  InputClassKey,
  InputLabelClassKey,
  InputProps as StandardInputProps,
  SelectProps,
  TextField
} from '@mui/material';
import clsx from 'clsx';
import { isNil } from 'lodash/fp';
import React, { ChangeEvent, MutableRefObject, ReactElement, useCallback, useState } from 'react';
import styles from './NxGenericTextField.module.scss';

// Component used by other components

type InputClasses = Partial<ClassNameMap<InputClassKey | InputBaseClassKey>>;

export const inputClasses = (
  disabled: boolean,
  adornedEnd?: boolean,
  adornedStart?: boolean,
  noLabel?: boolean,
  multiline?: boolean
): InputClasses => (
  {
    root: clsx(
      styles.inputWrapper,
      {
        [styles.inputWrapper_textarea]: multiline,
        [styles.inputWrapper_disabled]: disabled
      }
    ),
    focused: styles.inputWrapper_focused,
    error: styles.inputWrapper_error,
    adornedEnd: adornedEnd ? styles.inputWrapper_end : undefined,
    adornedStart: adornedStart ? styles.inputWrapper_start : undefined,
    input: clsx(
      styles.input,
      {
        [styles.input_textarea]: multiline,
        [styles.input_no_label]: noLabel
      }
    )
  }
);

export const fieldClassName = styles.field;

export const labelClasses: Partial<ClassNameMap<InputLabelClassKey>> = {
  root: styles.label,
  shrink: styles.label_shrink,
  focused: styles.label_shrink
};

export interface NxGenericTextFieldProps {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  endAdornment?: React.ReactNode;
  error?: string;
  id?: string;
  inputRef?: MutableRefObject<HTMLInputElement | undefined>;
  label?: React.ReactNode;
  multiline?: boolean;
  name?: string;
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: React.KeyboardEventHandler;
  onFocus?: (e: ChangeEvent) => void;
  select?: boolean;
  SelectProps?: SelectProps;
  startAdornment?: React.ReactNode;
  positionAbsoluteError?: boolean;
  required?: boolean;
  rows?: number;
  rowsMax?: number;
  type?: string;
  value?: string | string[];
  fullWidth?: boolean;
  sx?: any;
  /**
   * Set value to 'true' to force label to stick to the top. 'False' causes it to center.
   * Undefined is a default and it fallbacks to material-ui behaviour.
   */
  shrinkLabel?: boolean;
  /**
   * Sets input mode property for html input
   */
  inputMode?: 'numeric';
  inputComponent?: React.ElementType<InputBaseComponentProps>;
  inputProps?: StandardInputProps['inputProps'];
  autoComplete?: string;
}

export const fieldWrapperClassName = (className?: string): string => clsx(className, styles.fieldWrapper);
export const errorClassName = (absolute?: boolean): string => clsx(styles.error, { [styles.error_absolute]: absolute });

const NxGenericTextField = (
  {
    children,
    className,
    disabled = false,
    endAdornment,
    error,
    id,
    inputRef,
    label,
    multiline = false,
    name,
    onBlur,
    onChange,
    onKeyDown,
    onFocus,
    rows,
    rowsMax,
    select,
    SelectProps,
    startAdornment,
    type,
    value,
    shrinkLabel,
    inputMode,
    positionAbsoluteError = true,
    required,
    inputComponent,
    inputProps,
    autoComplete,
    fullWidth,
    sx
  }: NxGenericTextFieldProps
): ReactElement => {

  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = useCallback((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setIsFocused(true);
    onFocus?.(event);
  }, [onFocus]);

  const handleBlur = useCallback((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setIsFocused(false);
    onBlur?.(event as ChangeEvent<HTMLInputElement>);
  }, [onBlur]);

  const InputProps = {
    classes: inputClasses(disabled, true, !!startAdornment, !label, multiline),
    endAdornment,
    startAdornment: isFocused || value ? startAdornment : null,
    inputMode,
    inputComponent
  };

  const InputLabelProps = { classes: labelClasses, shrink: shrinkLabel };

  return (
    <div className={fieldWrapperClassName(className)}>
      <TextField variant='filled'
        sx={sx}
        autoComplete={autoComplete}
        onKeyDown={onKeyDown}
        disabled={disabled}
        className={fieldClassName}
        error={!isNil(error)}
        id={id}
        inputRef={inputRef}
        inputProps={inputProps}
        InputProps={InputProps}
        InputLabelProps={InputLabelProps}
        label={label}
        multiline={multiline}
        name={name}
        onBlur={handleBlur}
        onChange={onChange}
        onFocus={handleFocus}
        required={required}
        rows={rows}
        maxRows={rowsMax}
        select={select}
        SelectProps={SelectProps}
        type={type}
        fullWidth={fullWidth}
        value={value}>
        {children}
      </TextField>
      {
        !!error && <div className={errorClassName(positionAbsoluteError)}>{error}</div>
      }
    </div>
  );
};

export default React.memo(NxGenericTextField);
