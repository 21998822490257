import clsx from 'clsx';
import dayjs from 'dayjs';
import React, {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {NavLink} from 'react-router-dom';
import {ReactComponent as BookIcon} from '../../../../assets/icons/icon-book.svg';
import {ReactComponent as DocumentIcon} from '../../../../assets/icons/icon-document.svg';
import {ReactComponent as GearIcon} from '../../../../assets/icons/icon-gear.svg';
import {ReactComponent as PersonIcon} from '../../../../assets/icons/icon-person.svg';
import {ReactComponent as DownloadIcon} from '../../../../assets/icons/icon-ic-color-lens.svg';
import { ReactComponent as HistoryIcon } from '../../../../assets/icons/icon-history.svg';
import { ReactComponent as AnnouncementIcon } from '../../../../assets/icons/icon-announcement.svg'
import {RoutePaths} from '../../../../router';
import {useAuth} from '../../../../shared/auth/context';
import {Permission} from '../../../../shared/auth/model';
import {hasRequiredPermission} from '../../../../shared/auth/utils';
import styles from './NavigationBar.module.scss';

type TabButtonDefinition = {
  icon: ReactElement;
  label: string;
  routeTarget: RoutePaths;
  permission: Permission;
};

export default function NavigationBar(): ReactElement {

  const {state} = useAuth();
  const {t} = useTranslation();

  const tabsDefinition: TabButtonDefinition[] = [
    {
      icon: <PersonIcon />,
      label: t('BUSINESS_OWNERS.BOX_TITLE'),
      routeTarget: RoutePaths.BUSINESS_OWNERS,
      permission: Permission.CUSTOMER_READ
    },
    {
      icon: <BookIcon />,
      label: t('DICTIONARIES.MAIN.BOX_TITLE'),
      routeTarget: RoutePaths.DICTIONARIES,
      permission: Permission.DICTIONARY_READ
    },
    {
      icon: <DocumentIcon />,
      label: t('TRANSACTIONS.BOX_TITLE'),
      routeTarget: RoutePaths.TRANSACTIONS,
      permission: Permission.INSTAPAY_READ
    },
    {
      icon: <DownloadIcon />,
      label: t('REPORTS.BOX_TITLE'),
      routeTarget: RoutePaths.REPORTS,
      permission: Permission.REPORT_READ
    },
    {
      icon: <HistoryIcon className={styles.historyIcon} />,
      label: "Activity",
      routeTarget: RoutePaths.ACTIVITY,
      permission: Permission.ACTIVITY_READ
    },
    {
      icon: <GearIcon />,
      label: t('SYSTEM_PROPERTIES.BOX_TITLE'),
      routeTarget: RoutePaths.SYSTEM_PROPERTIES,
      permission: Permission.SETTINGS_READ
    },
    {
      icon: <AnnouncementIcon />,
      label: "Announcements",
      routeTarget: RoutePaths.ANNOUNCEMENTS,
      permission: Permission.ANNOUNCEMENT_READ
    }
  ];

  const button = (tabButtonDefinition: TabButtonDefinition, index: number): ReactElement => (
    <NavLink className={({isActive}) => clsx(styles.navButton, {[styles.navButton_active]: isActive})}
      key={`${index}_${dayjs().millisecond()}`}
      to={tabButtonDefinition.routeTarget}>
      <span className={styles.navButtonContentWrapper}>
        <div className={styles.navButtonIcon}>{tabButtonDefinition.icon}</div>
        <div className={styles.navButtonLabel}>{tabButtonDefinition.label}</div>
      </span>
    </NavLink>
  );

  return (
    <div className={styles.container}>
      {
        tabsDefinition.filter(
          tabsDefinition => hasRequiredPermission(state.userGroups ?? [], tabsDefinition.permission)
        )
          .map(button)
      }
    </div>
  );
}
