import dayjs, {Dayjs, isDayjs} from 'dayjs';
import {TABLE_DATE_FORMAT} from '../constants/format-templates';
import {formatCurrency} from './currency-format';

type Value = string | number | null;

export const getValueOrNullReplacement = (value?: Value): Value => value ?? '-';

export const getFormattedDateOrNullReplacement = (value?: string | null, format?: string | undefined): Value => {
  if (value && isDayjs(value)) {
    return (value as Dayjs).format(format ?? TABLE_DATE_FORMAT);
  }

  if (value && !isDayjs(value)) {
    return dayjs(value).format(format ?? TABLE_DATE_FORMAT);
  }

  return getValueOrNullReplacement(value);
};

export const getAmountOrNullReplacement = (value?: Value): Value => {
  if (!isNaN(Number(value))) {
    return formatCurrency(value as number);
  }

  return getValueOrNullReplacement(value);
};
