import { capitalize } from '@mui/material';
import clsx from 'clsx';
import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useParams, useLocation } from 'react-router-dom';
import { RoutePaths } from '../../../router';
import useSingleQuery from '../../../shared/hooks/use-single-query.hook';
import { TransHelper } from '../../../shared/utils/trans-helper';
import ContentBox from '../../shared/content-box/ContentBox';
import DetailBox from '../../shared/detail-box/DetailsBox';
import NxButton, { NxButtonVariant } from '../../shared/nxButton/NxButton';
import NxLoader from '../../shared/nxLoader/NxLoader';
import StatusColored, { StatusStyle } from '../../shared/statusColored/StatusColored';
import { SimpleDetailsDataTable } from '../../shared/table/Table';
import { businessOwnerAccountsTableColumnDefinitions } from './businessOwnerAccountsTableColumnDefinitions';
import { BusinessOwnerAccount, BusinessOwnerDetails, BusinessOwnerStatus } from './BusinessOwnerDetails.model';
import styles from './BusinessOwnersDetails.module.scss';
import MobileAppAccessTable from './mobile-app-access-table/MobileAppAccessTable';
import DeleteDialog from './delete-dialog/DeleteDialog';
import GoalActiveDialog from './delete-dialog/GoalActiveDialog';
import useDelete from '../../../shared/hooks/use-delete.hook';
import usePost from '../../../shared/hooks/use-post.hook';
import { useAlert } from '../../../shared/context/alert';
import { useAuth } from '../../../shared/auth/context';
import { hasRequiredPermission } from '../../../shared/auth/utils';
import { Permission } from '../../../shared/auth/model';
import dayJs from "dayjs";
import {
  TRANSACTION_DATE_FORMAT,
  TRANSACTION_TIME_FORMAT,
} from "../../../shared/constants/format-templates";
import { ReactComponent as IconLock } from '../../../assets/icons/icon-lock.svg';
import { ReactComponent as IconUnlock } from '../../../assets/icons/icon-unlock.svg';
import BlockDialog from './block-dialog/BlockDialog';
import UnblockDialog from './unblock-dialog/UnblockDialog';

export default function BusinessOwnersDetails(): ReactElement {

  const { t } = useTranslation();
  const location = useLocation();
  const { businessOwnerId } = useParams();
  const PrefixedTrans = TransHelper.getPrefixedTrans('BUSINESS_OWNERS');
  const SharedTrans = TransHelper.getPrefixedTrans('SHARED');
  const query = location?.state?.deleteId ? `/admin/customers/${businessOwnerId}/deleted/${location.state.deleteId}` : `/admin/customers/${businessOwnerId}`;
  const queryResult = useSingleQuery<BusinessOwnerDetails>(query);
  const [deleteDialogShown, setDeleteDialogShown] = useState(false);
  const [blockDialogShown, setBlockDialogShown] = useState(false);
  const [unblockDialogShown, setUnblockDialogShown] = useState(false);
  const [goalDialogShown, setGoalDialogShown] = useState(false);
  const deleteProfileRequest = useDelete(`/admin/customers/delete`);
  const blockCustomerRequest = usePost(`/admin/customers/${businessOwnerId}/block`);
  const unblockCustomerRequest = usePost(`/admin/customers/${businessOwnerId}/unblock`);
  const [deleteLoading, setDeleteLoading] = useState(true);
  const { showAlert } = useAlert();
  const { state } = useAuth();
  const isDeletedAccount = Boolean(location?.state?.deleteId);
  const isDeleteButtonEnabled = hasRequiredPermission(state.userGroups ?? [], Permission.DELETE_ACCOUNTS) && !isDeletedAccount;
  const isBlockButtonEnabled = hasRequiredPermission(state.userGroups ?? [], Permission.BLOCK_ACCOUNTS) && !isDeletedAccount;


  const deleteProfile = async () => {
    try {
      setDeleteLoading(false)
      await deleteProfileRequest({
        "customerNumber": businessOwnerId
      })
      showAlert({ type: 'success', message: `${queryResult.data?.username} account deleted succesfully.` })
    } catch (error: any) {
      if (error.toString().includes("BUSINESS_GOAL_IS_NOT_CLOSED") || error.toString().includes("BUSINESS_GOAL_IS_POSITIVE") || error.toString().includes("BUSINESS_GOAL_ACCOUNT_IS_NOT_CLOSED")) {
        setDeleteDialogShown(false)
        setGoalDialogShown(true)
      } else {
        showAlert({ type: 'error', message: `Something went wrong. Please try again.` })
      }

      return Promise.reject(error);
    } finally {
      setDeleteLoading(true)
      setDeleteDialogShown(false)
    }
  }

  const blockAccount = async (remarks: string) => {
    try {
      setBlockDialogShown(true);
      await blockCustomerRequest({
        "customerNumber": businessOwnerId,
        "remarks": remarks
      })
      showAlert({ type: 'success', message: `${queryResult.data?.username} profile blocked successfully.` })
    } catch {
      showAlert({ type: 'error', message: `Something went wrong. Please try again.` })
    } finally {
      setBlockDialogShown(false);
      queryResult.fetch();
    }
  }

  const unblockAccount = async () => {
    try {
      setUnblockDialogShown(true);
      await unblockCustomerRequest({
        "customerNumber": businessOwnerId,
      })
      showAlert({ type: 'success', message: `${queryResult.data?.username} profile unblocked successfully.` })
    } catch {
      showAlert({ type: 'error', message: `Something went wrong. Please try again.` })
    } finally {
      setUnblockDialogShown(false);
      queryResult.fetch();
    }
  }

  const BackButton = () => (
    <NavLink to={RoutePaths.BUSINESS_OWNERS} state={location?.state?.deleteId ? [location.state[0]] : location.state}>
      <NxButton variant={NxButtonVariant.BACK} className={styles.backButton}>
        <SharedTrans>BACK</SharedTrans>
      </NxButton>
    </NavLink>
  );

  const mapStatusToStyle = (status?: BusinessOwnerStatus): StatusStyle | undefined => {
    switch (status) {
      case BusinessOwnerStatus.ACTIVE:
        return StatusStyle.SUCCESS;
      case BusinessOwnerStatus.INACTIVE:
        return StatusStyle.WARN;
      case BusinessOwnerStatus.CLOSED:
        return StatusStyle.NEUTRAL;
      case BusinessOwnerStatus.SUSPENDED:
        return StatusStyle.ERROR;
    }
  };

  const personalInformation = (
    <DetailBox>
      <div className={styles.detailSectionHeader}>
        <PrefixedTrans>DETAILS.PERSONAL.HEADER</PrefixedTrans>
      </div>
      <div className={clsx(styles.detailsSectionContent, styles.detailsSectionContent_personal)}>
        <div className={clsx(styles.detail_label, styles.detail_label_username)}>
          <PrefixedTrans>DETAILS.PERSONAL.USERNAME</PrefixedTrans>
        </div>
        <div className={clsx(styles.detail_value_username)} style={{ color: isDeletedAccount ? '#B1BECF' : '' }}>
          {queryResult.data?.username}
        </div>
        <div className={clsx(styles.detail_label, styles.detail_label_middlname)}>
          <PrefixedTrans>DETAILS.PERSONAL.MIDDLE_NAME</PrefixedTrans>
        </div>
        <div className={clsx(styles.detail_value_middlname)} style={{ color: isDeletedAccount ? '#B1BECF' : '' }}>
          {queryResult.data?.middleName}
        </div>
        <div className={clsx(styles.detail_label, styles.detail_label_cstno)}>
          <PrefixedTrans>DETAILS.PERSONAL.CUSTOMER_NUMBER</PrefixedTrans>
        </div>
        <div className={clsx(styles.detail_value_cstno)} style={{ color: isDeletedAccount ? '#B1BECF' : '' }}>
          {queryResult.data?.customerNumber}
        </div>
        <div className={clsx(styles.detail_label, styles.detail_label_phonno)}>
          <PrefixedTrans>DETAILS.PERSONAL.PHONE_NUMBER</PrefixedTrans>
        </div>
        <div className={clsx(styles.detail_value_phonno)} style={{ color: isDeletedAccount ? '#B1BECF' : '' }}>
          {queryResult.data?.mobileNumber}
        </div>
        <div className={clsx(styles.detail_label, styles.detail_label_status)}>
          <PrefixedTrans>DETAILS.PERSONAL.STATUS</PrefixedTrans>
        </div>
        <div className={clsx(styles.detail_value_status)}>
          <StatusColored isDeletedAccount={isDeletedAccount} style={mapStatusToStyle(queryResult.data?.status)}
            statusName={<PrefixedTrans>{`STATUS.${queryResult.data?.status}`}</PrefixedTrans>} />
        </div>
        {queryResult.data?.statusChangeRemarks && (<>
          <div className={clsx(styles.detail_label, styles.detail_label_remarks)}>
            Remarks:
          </div>
          <div className={clsx(styles.detail_value_remarks)} style={{ color: isDeletedAccount ? '#B1BECF' : '' }}>
            {queryResult.data?.statusChangeRemarks}
          </div>
        </>)}
      </div>
    </DetailBox>
  );

  const businessInformation = (
    <DetailBox>
      <div className={styles.detailSectionHeader}>
        <PrefixedTrans>DETAILS.BUSINESS.HEADER</PrefixedTrans>
      </div>
      <div className={clsx(styles.detailsSectionContent, styles.detailsSectionContent_business)}>
        <div className={clsx(styles.detail_label, styles.detail_label_businessName)}>
          <PrefixedTrans>DETAILS.BUSINESS.NAME</PrefixedTrans>
        </div>
        <div className={clsx(styles.detail_value_businessName)}>
          {queryResult.data?.businessName}
        </div>
      </div>
    </DetailBox>
  );

  const accountsList = (
    <DetailBox>
      <div className={styles.detailSectionHeader}>
        <PrefixedTrans>DETAILS.ACCOUNTS.HEADER</PrefixedTrans>
      </div>
      <SimpleDetailsDataTable
        className={styles.table}
        getRowId={(row: BusinessOwnerAccount) => row.number}
        rows={queryResult.data?.accountList ?? []}
        pageSize={queryResult.data?.accountList.length}
        columns={businessOwnerAccountsTableColumnDefinitions(t, isDeletedAccount)}
        noRowsMessage={t('BUSINESS_OWNERS.TABLE.NO_RESULT_MESSAGE')} />
    </DetailBox>
  );

  return (
    <ContentBox header={<BackButton />}>
      <BlockDialog
        open={blockDialogShown}
        onClose={() => setBlockDialogShown(false)}
        onConfirm={blockAccount}
        username={queryResult.data?.username}
      />
      <UnblockDialog
        open={unblockDialogShown}
        onClose={() => setUnblockDialogShown(false)}
        onConfirm={unblockAccount}
        username={queryResult.data?.username}
      />
      <DeleteDialog
        open={deleteDialogShown}
        onClose={() => setDeleteDialogShown(false)}
        onConfirm={deleteProfile}
        isLoading={deleteLoading}
        username={queryResult.data?.username}
      />
      <GoalActiveDialog
        open={goalDialogShown}
        onClose={() => setGoalDialogShown(false)}
        onConfirm={() => setGoalDialogShown(false)}
      />
      <div className={styles.container}>
        {
          queryResult.isLoading || !queryResult.data ?
            <NxLoader /> :
            <div className={styles.dataContainer}>
              <div className={styles.businessOwnerNameContainer}>
                <div className={styles.businessOwnerName}>
                  <div className={styles.initialsCircle} style={{ color: isDeletedAccount ? '#B1BECF' : '', background: isDeletedAccount ? '#F3F7FB' : '' }}>
                    {`${queryResult.data?.firstName.substring(0, 1)}${queryResult.data?.lastName.substring(0, 1)}`}
                  </div>
                  <div className={styles.businessOwnerName} style={{ color: isDeletedAccount ? '#B1BECF' : '' }}>
                    {`${capitalize(queryResult.data?.firstName.toLocaleLowerCase() ?? '')} ${capitalize(queryResult.data?.lastName.toLocaleLowerCase() ?? '')}`}
                  </div>
                  {isDeletedAccount && <div className={styles.headerDeleted}>Account deleted</div>}
                </div>

                <div className={styles.businessOwnerNameButtons}>
                  {(queryResult.data?.status === "ACTIVE" && isBlockButtonEnabled) && 
                    (<NxButton className={styles.deleteButton} onClick={() => { setBlockDialogShown(true) }}>
                      { <IconLock />}
                      {"Block Boz Profile"}
                    </NxButton>)} 
                  {(queryResult.data?.status === "SUSPENDED" && isBlockButtonEnabled) &&
                    (<NxButton className={styles.deleteButton} onClick={() => { setUnblockDialogShown(true) }}>
                      {<IconUnlock />}
                      {"Unblock Boz Profile"}
                    </NxButton>)
                  }
                </div>
              </div>
              {personalInformation}
              {businessInformation}
              {accountsList}
              {!isDeletedAccount && <DetailBox>
                <div className={styles.detailSectionHeader}>
                  <PrefixedTrans>DETAILS.MOBILE_APP_ACCESS.HEADER</PrefixedTrans>
                </div>
                <MobileAppAccessTable isDeletedAccount={isDeletedAccount} businessOwnerAuthData={queryResult.data.authData} />
              </DetailBox>}

              {isDeleteButtonEnabled && <NxButton variant={NxButtonVariant.PROFILE_DELETE} className={styles.deleteButton} onClick={() => { setDeleteDialogShown(true) }}>
                Delete Boz Profile
              </NxButton>}


              {isDeletedAccount && <div>
                <div style={{ color: '#B1BECF', marginLeft: '5px' }}>Date deleted: {dayJs(location.state.deletedOn).format(
                  `${TRANSACTION_DATE_FORMAT} ${TRANSACTION_TIME_FORMAT}`
                )}</div>
                <div style={{ color: '#B1BECF', marginLeft: '5px' }}>Deleted by: {location.state.deletedBy}</div>
              </div>}
            </div>
        }
      </div>
    </ContentBox>
  );
}
