import {useLocation} from 'react-router';
import {useAuth} from '../auth/context';
import {HttpError, SimpleErrorBody} from '../model/Error.model';

export default function useApiCatch(): (error: HttpError<SimpleErrorBody>) => Promise<never> {

  const location = useLocation();
  const {dispatch} = useAuth();

  return (error): Promise<never> => {
    if (location.pathname !== '/login' && (error as HttpError<SimpleErrorBody>).response?.status === 401) {
      dispatch({type: 'SIGNED_OUT'});
    }

    if (process.env.REACT_APP_LOGGING_ENABLED === 'true') {
      console.error(error);
    }

    return Promise.reject(error);
  };
}
