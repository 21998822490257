import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {Formik} from 'formik';
import InputAdornment from '@mui/material/InputAdornment';
import NxButton from '../../shared/nxButton/NxButton';
import NxInput from '../../shared/nxInput/NxInput';
import styles from './SearchBar.module.scss';
import {object, SchemaOf, string} from 'yup';

type SearchBarProps = {
  onSearch: (values: SearchValues) => void;
  isLoading: boolean;
  formValues: [string, string][];
};

export type SearchValues = {
  alias?: string;
  businessName?: string;
  mobileNumber?: string;
};

const SearchBar = ({onSearch, isLoading, formValues}: SearchBarProps) => {

  const formValuesFormatted = formValues?.reduce((obj: any, [key, value]: any) => {
    obj[key] = value;
    return obj;
  }, {});

  const {t} = useTranslation();

  const handleSearch = useCallback((values: SearchValues) => {
    onSearch({
      ...values,
      ...(values.mobileNumber ? {mobileNumber: t('SHARED.FORM.MOBILE_NUMBER_PREFIX.PHP') + values.mobileNumber} : {})
    });
  }, [onSearch, t]);

  const searchSchema: SchemaOf<SearchValues> = object({
    alias: string().notRequired(),
    businessName: string().notRequired(),
    mobileNumber: string()
      .matches(/^\d+$/, t('SHARED.FORM.ERROR.MOBILE_NUMBER_FORMAT'))
      .min(10, t('SHARED.FORM.ERROR.MOBILE_NUMBER_MIN'))
      .notRequired()
  }).defined();

  const formInitValues = {
    alias: formValuesFormatted?.alias ?? '',
    businessName: formValuesFormatted?.businessName ?? '',
    mobileNumber: formValuesFormatted?.mobileNumber ? formValuesFormatted.mobileNumber.slice(3, 13) : '',
    generalError: ''
  };

  return (
    <Formik<SearchValues>
      initialValues={formInitValues}
      onSubmit={handleSearch}
      validationSchema={searchSchema}
    >
      {({
        values,
        handleSubmit,
        errors,
        handleChange
      }) => (
        <form className={styles.searchBarContainer} onSubmit={handleSubmit}>
          <NxInput className={styles.searchInput}
            label={'Username'}
            name='alias'
            value={values.alias}
            onChange={handleChange} />
          <NxInput className={styles.searchInput}
            label={'Business name'}
            name='businessName'
            value={values.businessName}
            onChange={handleChange} />
          <NxInput className={styles.searchInput}
            label={'Mobile number'}
            name='mobileNumber'
            value={values.mobileNumber}
            onChange={handleChange}
            error={errors.mobileNumber}
            startAdornment={<InputAdornment position='start'>{t('SHARED.FORM.MOBILE_NUMBER_PREFIX.PHP')}</InputAdornment>} />
          <NxButton
            type='submit'
            className={styles.searchButton}
            disabled={isLoading}
            loaded={!isLoading}
          >
            {t('SHARED.SEARCH')}
          </NxButton>
        </form>
      )}
    </Formik>
  );
};

export default SearchBar;
