import {Formik, FormikHelpers, FormikProps} from 'formik';
import React, {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {object, SchemaOf, string} from 'yup';
import useTrans from '../../../shared/hooks/use-trans.hook';
import { useNavigate } from 'react-router-dom';
import NxButton from '../../shared/nxButton/NxButton';
import {NxFormikInput} from '../../shared/nxInput/NxFormikInput';
import NxPasswordInput from '../../shared/nxPasswordInput/NxPasswordInput';
import {LoginFormFields} from './LoginForm.model';
import styles from './LoginForm.module.scss';

type LoginFormProps = {
  onSubmit: (username: string, password: string) => Promise<void>;
}

export default function LoginForm({onSubmit}: LoginFormProps): React.ReactElement {

  const {t} = useTranslation();
  const SharedTrans = useTrans('LOGIN');
  const navigate = useNavigate();

  const submit = async (values: LoginFormFields, actions: FormikHelpers<LoginFormFields>): Promise<void> => {
    await onSubmit(values.username, values.password)
      .catch(() => {
        actions.setErrors({
          username: "",
          password: "",
        })

        actions.setFieldError('generalError', t(`LOGIN.INCORRECT_LOGIN`))
      });
  };

  const loginFormSchema: SchemaOf<LoginFormFields> = object({
    username: string().defined(t('SHARED.FORM.ERROR.REQUIRED')),
    password: string().defined(t('SHARED.FORM.ERROR.REQUIRED')),
    generalError: string().notRequired()
  }).defined();

  const LoginForm = ({
                       values,
                       handleSubmit,
                       errors,
                       handleChange,
                       isSubmitting
                     }: FormikProps<LoginFormFields>): ReactElement => (
    <form onSubmit={handleSubmit}>
      <NxFormikInput name='username'
                     className={styles.input}
                     disabled={isSubmitting}
                     label={<SharedTrans>USERNAME</SharedTrans>} />
      <NxPasswordInput name='password'
                       className={styles.inputPassword}
                       disabled={isSubmitting}
                       label={<SharedTrans>PASSWORD</SharedTrans>}
                       error={errors.password}
                       value={values.password}
                       onChange={handleChange} />
      <div className={styles.error}>{errors.generalError}</div>
      <div className={styles.forgotPassword} onClick={() => { navigate('/forgot-password') }}>Forgot password?</div>
      <div className={styles.signInRow}>
        <NxButton type='submit' className={styles.button} loaded={!isSubmitting}>
          <SharedTrans>SUBMIT_LABEL</SharedTrans>
        </NxButton>
      </div>
    </form>
  );

  const formInitValues = {
    username: '',
    password: '',
    generalError: ''
  };

  return (
    <Formik<LoginFormFields> initialValues={formInitValues}
                             onSubmit={submit}
                             validateOnMount={false}
                             validateOnBlur={false}
                             validateOnChange={false}
                             validationSchema={loginFormSchema}>
      {LoginForm}
    </Formik>
  );
}
