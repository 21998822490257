import {isNil} from 'lodash/fp';
import clsx from 'clsx';
import dayjs from 'dayjs';
import React, {FocusEvent, ReactElement, useCallback} from 'react';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import {PopperProps, TextField, TextFieldProps} from '@mui/material';
import {ReactComponent as CalendarIcon} from '../../../assets/icons/icon-calender.svg';
import {fieldClassName, inputClasses, labelClasses} from '../nxGenericTextField/NxGenericTextField';
import {PickersActionBarProps} from '@mui/x-date-pickers/PickersActionBar';
import textFieldStyles from '../nxGenericTextField/NxGenericTextField.module.scss';
import { pickersLayoutClasses } from '@mui/x-date-pickers/PickersLayout';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

export interface NxDatepickerProps {
	children?: React.ReactNode;
	className?: string;
	disabled?: boolean;
	error?: string;
	icon?: React.ElementType<any> | undefined;
	id?: string;
	inputFormat?: string;
	label: React.ReactNode;
	/**
	 * Set maximum selectable date.
	 */
	maxDate?: any;
	minDate?: any;
	name?: string;
	onBlur?: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void;
	onChange: (value: any) => void | null;
	onKeyDown?: React.KeyboardEventHandler;
	onFocus?: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void;
	positionAbsoluteError?: boolean;
	PopperProps?: PopperProps;
	ActionBar?: React.ElementType<PickersActionBarProps>;
	required?: boolean;
	value?: string | string[] | null | any;
	/**
	 * Set value to 'true' to force label to stick to the top. 'False' causes it to center.
	 * Undefined is a default and it fallbacks to material-ui behaviour.
	 */
	shrinkLabel?: boolean;
	/**
	 * Sets datepicker custom input
	 */
	renderInput?: (props: TextFieldProps) => React.ReactElement;
	hasTimePicker?: boolean;
	disablePast?: boolean;
	disableFuture?: boolean;
}

export const fieldWrapperClassName = (className?: string): string => clsx(className, textFieldStyles.fieldWrapper);
export const errorClassName = (absolute?: boolean): string => clsx(textFieldStyles.error, {[textFieldStyles.error_absolute]: absolute});

const NxDatepicker = (
	{
		children,
		className,
		disabled = false,
		error,
		icon = CalendarIcon,
		id,
		inputFormat,
		label,
		maxDate,
		minDate,
		name,
		onBlur,
		onChange,
		onKeyDown,
		onFocus,
		value,
		shrinkLabel,
		positionAbsoluteError = true,
		required,
		renderInput,
		hasTimePicker = false,
		disableFuture = false,
		disablePast = false
	}: NxDatepickerProps
): ReactElement => {

	// MUI v5 -> v6 now checks if it is null instead of empty string
	value = dayjs(value).isValid() ? dayjs(value?.toString()) : null


	const handleFocus = useCallback((event: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
		onFocus?.(event);
	}, [onFocus]);

	const handleBlur = useCallback((event: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
		onBlur?.(event);
	}, [onBlur]);

	const InputProps = {
		classes: inputClasses(disabled)
	};

	const InputLabelProps = {classes: labelClasses, shrink: shrinkLabel};

	const commonProps = {
		onChange,
		format: inputFormat,
		label: label,
		maxDate: maxDate,
		minDate: minDate,
		components: { OpenPickerIcon: icon,
		},
		renderInput: (params: any) => {
			return (
				renderInput?.(params) || (
					<TextField
						{...params}
						variant="filled"
						name={name}
						onKeyDown={onKeyDown}
						disabled={disabled}
						className={fieldClassName}
						InputProps={{ ...params.InputProps, ...InputProps }}
						InputLabelProps={InputLabelProps}
						error={!isNil(error)}
						id={id}
						label={label}
						onBlur={handleBlur}
						onFocus={handleFocus}
						required={required}
						value={value}
					>
						{children}
					</TextField>
				)
			)
		},
	};

	function ActionList(props: PickersActionBarProps) {
		const { onSetToday, className } = props;
		const actions = [
		  { text: 'Set to current date', method: onSetToday },
		];
		return (
		  <List style={{ padding: "0" }} className={className}>
			{actions.map(({ text, method }) => (
			  <ListItem key={text} disablePadding>
				<ListItemButton onClick={method} style={{backgroundColor: "#F3F7FB"}}>
				  <ListItemText primary={text} style={{color: "#4a83d3", display: "flex", justifyContent: "flex-end", padding: "5px"}}/>
				</ListItemButton>
			  </ListItem>
			))}
		  </List>
		);
	  }

	return (
		<div className={fieldWrapperClassName(className)}>
			{hasTimePicker ? (<DateTimePicker
				{...commonProps}
				disablePast
				value={value}
				closeOnSelect={false}
				slotProps={{
					actionBar: {
						actions: ['today', 'cancel', 'accept'],
					},
					textField: { variant: 'outlined' }
				}}
			/>) : (<DatePicker
					value={value === '' ? null : value}
					{...commonProps}
					slotProps={{
						actionBar: {
							actions: ['today'],
						},
						popper: {
							placement: 'right-start'
						},
						layout: {
							sx: {
							  [`.${pickersLayoutClasses.actionBar}`]: {
								gridColumn: 2,
								gridRow: 1,
							  },
							},
						},
					}}
					slots={{
						actionBar: ActionList,
					}}
					disableFuture={disableFuture}
					disablePast={disablePast}
			/>)}
			{
				!!error && <div className={errorClassName(positionAbsoluteError)}>{error}</div>
			}
		</div>
	);
};

export default React.memo(NxDatepicker);
