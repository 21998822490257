import React from 'react';
import { signOut, useAuth } from '../../shared/auth/context';
import NxButton from '../shared/nxButton/NxButton';
import { ReactComponent as Logo } from '../../assets/images/logo-login-header.svg';
import { useTranslation } from 'react-i18next';
import styles from './UserNoGroup.module.scss'

const UserNoGroup: React.FC = () => {
    const auth = useAuth();
    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            <Logo className={styles.logo} />
            <div className={styles.subContainer}>
                <h1>{t('LOGIN.NO_USER_GROUP')} <span className={styles.username}>{auth.state.username}</span></h1>
                <h3>{t('LOGIN.CONTACT_ADMIN')}</h3>

                <NxButton className={styles.logoutButton} onClick={() => signOut(auth.dispatch)}>
                    {t('LOGIN.SIGN_OUT')}
                </NxButton>
            </div>
        </div>
    )
}

export default UserNoGroup;