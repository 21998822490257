import dayJs from "dayjs";
import { GridColDef } from "@mui/x-data-grid";
import { TFunction } from "i18next";
import { createColDefWithDefaultConfiguration } from "../../shared/utils/dataGridDefaultColumnDefValues";
import {
  TRANSACTION_DATE_FORMAT,
  TRANSACTION_TIME_FORMAT,
} from "../../shared/constants/format-templates";
import { formatCurrency } from "../../shared/utils/currency-format";

const transactionsTableColumnDefinitions = (t: TFunction): Array<GridColDef> =>
  createColDefWithDefaultConfiguration([
    {
      field: "username",
      headerName: t("TRANSACTIONS.DETAILS.BIZAP_USERNAME") ?? "",
    },
    {
      field: "accountNumber",
      headerName: t("SHARED.ACCOUNT_NUMBER") ?? "",
    },
    {
      field: "mobileNumber",
      headerName: t("SHARED.PHONE_NUMBER") ?? "",
    },
    {
      field: "referenceNumber",
      headerName: t("TRANSACTIONS.TABLE.COLUMN_HEADERS.TRANSACTION_ID") ?? "",
    },
    {
      field: "transactionGroup",
      headerName: t("TRANSACTIONS.TABLE.COLUMN_HEADERS.TYPE") ?? "",
      valueFormatter: ({ value }) => t(`TRANSACTIONS.TRANS_GROUPS.${value}`),
    },
    {
      field: "registeredOn",
      headerName: t("TRANSACTIONS.TABLE.COLUMN_HEADERS.DATE_TIME") ?? "",
      valueFormatter: ({ value }) =>
        dayJs(value).format(
          `${TRANSACTION_DATE_FORMAT} ${TRANSACTION_TIME_FORMAT}`
        ),
    },
    {
      field: "amount",
      headerName: t("SHARED.AMOUNT") ?? "",
      headerAlign: "right",
      valueFormatter: ({ value }) =>
        t("SHARED.PHP", {
          amount: formatCurrency(value, { style: undefined }),
        }),
      align: "right",
    },
    {
      field: "transactionStatus",
      headerName: "Status",
      headerAlign: "right",
      align: "right",
      valueFormatter: ({ value }) =>
        value.charAt(0).toUpperCase() + value.slice(1).toLowerCase(),
    },
  ]);

export default transactionsTableColumnDefinitions;
