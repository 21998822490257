import { useEffect, useState, useCallback } from "react";
import { signOut, useAuth } from '../auth/context';

const channel = new BroadcastChannel('BIZAPP');

const useInactivityTimer = () => {
  const timeoutInMs = 900 * 1000;
  const auth = useAuth();
  const [remainingTime, setRemainingTime] = useState<number>(timeoutInMs);

  const onInactivityTimeout = useCallback(() => {
    signOut(auth.dispatch);
  }, [auth.dispatch]);

  const handleUserActivity = useCallback(() => {
    channel.postMessage('clicked')
    setRemainingTime(timeoutInMs);
  }, [timeoutInMs]);

  channel.onmessage = (event: any) => {
    if (event.data === 'clicked') {
      setRemainingTime(timeoutInMs);
    }

    if (event.data === 'onload') {
      setRemainingTime(timeoutInMs);
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleUserActivity);
    document.addEventListener("keydown", handleUserActivity);

    return () => {
      document.removeEventListener("click", handleUserActivity);
      document.removeEventListener("keydown", handleUserActivity);
    };
  }, [handleUserActivity]);

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime((prevTime) => prevTime - 1000);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (remainingTime === 0) {
      onInactivityTimeout();
    }
  }, [remainingTime, onInactivityTimeout]);
};

export default useInactivityTimer;