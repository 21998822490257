import {NxSelectOption} from './NxSelect';

export interface ValueMapping<T_Value> {
  toValue: (text: string) => T_Value | undefined;
  fromValue: (value: T_Value) => string | undefined
}

export const createMapping = <T_Value,>(options: NxSelectOption<T_Value>[]): ValueMapping<T_Value> => {
  const toValue: Map<string, T_Value> = new Map();
  const fromValue: Map<T_Value, string> = new Map();

  for(const {value} of options) {
    const textValue = JSON.stringify(value);
    toValue.set(textValue, value);
    fromValue.set(value, textValue);
  }

  return {
    toValue: (text: string): T_Value | undefined => toValue.get(text),
    fromValue: (value: T_Value): string | undefined => fromValue.get(value)
  }
}