import {CognitoUser} from 'amazon-cognito-identity-js';

export enum UserGroup {
  ADMIN = "ADMIN",
  CUSTOMER_CARE = "CUSTOMER_CARE",
  EPAY_TEAM_CHECKER = "EPAY_TEAM_CHECKER",
  EPAY_TEAM_MAKER = "EPAY_TEAM_MAKER",
  MOFD = "MOFD",
}

export enum Permission {
  CUSTOMER_READ = "CUSTOMER_READ",
  CUSTOMER_ACCESS_WRITE = "CUSTOMER_ACCESS_WRITE",
  DICTIONARY_READ = "DICTIONARY_READ",
  DICTIONARY_WRITE = "DICTIONARY_WRITE",
  DICTIONARY_EPAY_WRITE = "DICTIONARY_EPAY_WRITE",
  SETTINGS_READ = "SETTINGS_READ",
  SETTINGS_WRITE = "SETTINGS_WRITE",
  INSTAPAY_READ = "INSTAPAY_READ",
  INSTAPAY_WRITE = "INSTAPAY_WRITE",
  REPORT_READ = "REPORT_READ",
  ACTIVITY_READ = "ACTIVITY_READ",
  ANNOUNCEMENT_READ = "ANNOUNCEMENT_READ",
  ANNOUNCEMENT_CREATE = "ANNOUNCEMENT_CREATE",
  ACTIVITY_BUSINESS_OWNERS_ONLY = "ACTIVITY_BUSINESS_OWNERS_ONLY",
  REPORTS_DELETED_ACCOUNTS_ONLY = "REPORTS_DELETED_ACCOUNTS_ONLY",
  DELETE_ACCOUNTS = "DELETE_ACCOUNTS",
  REGISTERED_ACCOUNTS = "REGISTERED_ACCOUNTS",
  INVOICES = "INVOICES",
  PAYROLLS = "PAYROLLS",
  BLOCK_ACCOUNTS = "BLOCK_ACCOUNTS"
}

export enum AuthState {
  NOT_SIGNED_IN,
  SIGNED_IN,
  PASSWORD_CHANGE_REQUIRED
}

export type AuthData = {
  username?: string;
  userGroups?: UserGroup[];
  apiToken?: string;
  authState?: AuthState;
  cognitoUser?: CognitoUser;
}

export const RolePermission: Map<UserGroup, Permission[]> = new Map([
  [
    UserGroup.ADMIN,
    [
      Permission.CUSTOMER_READ,
      Permission.CUSTOMER_ACCESS_WRITE,
      Permission.DICTIONARY_READ,
      Permission.DICTIONARY_WRITE,
      Permission.SETTINGS_READ,
      Permission.SETTINGS_WRITE,
      Permission.INSTAPAY_READ,
      Permission.REPORT_READ,
      Permission.ACTIVITY_READ,
      Permission.ANNOUNCEMENT_READ,
      Permission.ANNOUNCEMENT_CREATE,
      Permission.REGISTERED_ACCOUNTS,
      Permission.INVOICES,
      Permission.PAYROLLS,
      Permission.BLOCK_ACCOUNTS,
    ],
  ],
  [
    UserGroup.CUSTOMER_CARE,
    [
      Permission.CUSTOMER_READ,
      Permission.CUSTOMER_ACCESS_WRITE,
      Permission.DICTIONARY_READ,
      Permission.SETTINGS_READ,
      Permission.INSTAPAY_READ,
      Permission.BLOCK_ACCOUNTS,
    ],
  ],
  [
    UserGroup.EPAY_TEAM_CHECKER,
    [
      Permission.CUSTOMER_READ,
      Permission.INSTAPAY_READ,
      Permission.INSTAPAY_WRITE,
      Permission.REPORT_READ,
      Permission.DICTIONARY_EPAY_WRITE,
      Permission.DICTIONARY_READ,
    ],
  ],
  [
    UserGroup.EPAY_TEAM_MAKER,
    [
      Permission.CUSTOMER_READ,
      Permission.INSTAPAY_READ,
      Permission.INSTAPAY_WRITE,
      Permission.REPORT_READ,
      Permission.DICTIONARY_EPAY_WRITE,
      Permission.DICTIONARY_READ,
    ],
  ],
  [
    UserGroup.MOFD,
    [
      Permission.CUSTOMER_READ,
      Permission.ACTIVITY_READ,
      Permission.ACTIVITY_BUSINESS_OWNERS_ONLY,
      Permission.DELETE_ACCOUNTS,
      Permission.REPORT_READ,
      Permission.REPORTS_DELETED_ACCOUNTS_ONLY,
    ],
  ],
]);
