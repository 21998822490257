import clsx from 'clsx';
import React, {ReactElement} from 'react';
import styles from './StatusColored.module.scss';

type BusinessOwnerStatusColoredProps = {
  statusName?: string | ReactElement;
  style?: StatusStyle;
  isDeletedAccount?: boolean;
}

export enum StatusStyle {
  NEUTRAL = 'neutral',
  SUCCESS = 'success',
  WARN = 'warning',
  ERROR = 'error'
}

export default function StatusColored({ statusName, style, isDeletedAccount }: BusinessOwnerStatusColoredProps): ReactElement {
  return (
    <div className={clsx(styles.status, { [styles[`status_${style}`]]: !!style })} style={{ color: isDeletedAccount ? '#B1BECF' : '', background: isDeletedAccount ? '#F3F7FB' : '' }}>
      {statusName ?? '-'}
    </div>
  );
}
