import React, {ReactElement} from 'react';
import RCBCLogo from '../../../assets/images/Boss-Logo.svg';
import {TransHelper} from '../../../shared/utils/trans-helper';
import styles from './AppLogo.module.scss';

const PrefixedTrans = TransHelper.getPrefixedTrans("SHARED");

const AppLogo = (): ReactElement => (
  <div className={styles.appLogo}>
    <img className={styles.rcbcLogo} src={RCBCLogo} alt='Logo' />
    <div className={styles.appLogoSeparator} />
    <div className={styles.appName}>
      <PrefixedTrans>APP_NAME</PrefixedTrans>
    </div>
  </div>
);

export default AppLogo;
