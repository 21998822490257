import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import NxButton, { NxButtonVariant } from '../../../shared/nxButton/NxButton';
import styles from './UnblockDialog.module.scss';
import clsx from 'clsx';

export default function UnblockDialog({ open, onClose, onConfirm, isLoading, username }: any) {
    const save = async (): Promise<void> => {
        onConfirm()
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <div className={styles.container}>
                <DialogTitle className={styles.headerText}>
                    {"Unblock Boz Profile?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText className={styles.text}>
                        {`Are you sure you want to unblock `}
                        <span style={{ fontWeight: 'bold' }}>{`${username}'s `}</span>
                        {`profile?`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <div className={styles.buttonSet}>
                        <NxButton className={clsx(styles.button)}
                            onClick={save}
                            loaded={isLoading}
                        >
                            {"Unblock the profile"}
                        </NxButton>
                        <NxButton className={styles.button}
                            onClick={onClose}
                            variant={NxButtonVariant.TEXT}
                            loaded={isLoading}
                        >
                            {"No, Go back"}
                        </NxButton>
                    </div>
                </DialogActions>
            </div>
        </Dialog>
    );
}