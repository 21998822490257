import { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import NxButton, { NxButtonVariant } from '../../shared/nxButton/NxButton';
import styles from './NewDialog.module.scss';
import clsx from 'clsx';

export default function NewDialog({ open, onClose, onConfirm }: any) {
    const [loading, setLoading] = useState(false);

    const save = async (): Promise<void> => {
        await onConfirm(setLoading)
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <div className={styles.container}>
                <DialogTitle className={styles.headerText}>
                    {"Create announcement"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText className={styles.text}>
                        {"Are you sure you want to create this announcement?"}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <div className={styles.buttonSet}>
                        <NxButton className={styles.button}
                            onClick={onClose}
                            variant={NxButtonVariant.TEXT}
                            >
                            {"No, Go back"}
                        </NxButton>
                        <NxButton className={clsx(styles.button)}
                            disabled={loading}
                            onClick={() => {
                                setLoading(true)
                                save()
                            }}
                            loaded={!loading}
                            >
                            {"Proceed"}
                        </NxButton>
                    </div>
                </DialogActions>
            </div>
        </Dialog>
    );
}