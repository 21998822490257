import dayjs from 'dayjs';
import { Formik, FormikProps } from 'formik';
import { object, string } from 'yup';
import { ReactElement, useState } from "react";
import ContentBox from '../../shared/content-box/ContentBox';
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { RoutePaths } from "../../../router";
import usePost from '../../../shared/hooks/use-post.hook';
import usePut from '../../../shared/hooks/use-put.hook';
import NxButton from "../../shared/nxButton/NxButton";
import { NxButtonVariant } from "../../shared/nxButton/NxButton";
import NxInput from '../../shared/nxInput/NxInput';
import NxGenericTextField from '../../shared/nxGenericTextField/NxGenericTextField';
import NxDatepicker from '../../shared/nxDatepicker/NxDatepicker';
import MenuItem from '@mui/material/MenuItem';
import { Divider } from '@mui/material';
import { useAlert } from '../../../shared/context/alert';
import { useTranslation } from "react-i18next";
import { NewAnnouncementType } from '../Announcements.model';
import styles from './NewAnnouncement.module.scss';
import formatString from '../../../shared/utils/stringFormatter';
import clsx from 'clsx';
import NewDialog from '../create-dialog/NewDialog';
import { TimePicker } from '@mui/x-date-pickers';

const FREQUENCY = ["DAILY", "WEEKLY", "MONTHLY"];
interface NewAnnouncementsProps {
    isNewAnnouncement: boolean;
}

export default function NewAnnouncements({ isNewAnnouncement }: NewAnnouncementsProps): ReactElement {
    const { t } = useTranslation();
    const location = useLocation();
    const createPost = usePost(`/admin/announcement`);
    const navigate = useNavigate();
    const updatePost = usePut(`/admin/announcement/${location?.state?.results?.id}`)
    const { showAlert } = useAlert();
    const [characterCount, setCharacterCount] = useState(location?.state?.results?.message.length ?? 0);
    const [newDialogShown, setNewDialogShown] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [valuesTemp, setValuesTemp] = useState<any>(null);
    const maxCharacters = 550;
    const allowedCharacters = (input: string) => {
        return /^[a-zA-Z0-9@()#^*-=+\][{}:;/><.\s]+$/.test(input);
    }

    const announcementSchema: any = object({
        title: string()
            .test('special', t('ANNOUNCEMENT.INVALID_CHARACTERS_ERROR'), function (value) {
                return allowedCharacters(value!)
            }),
        message: string()
            .test('special', t('ANNOUNCEMENT.INVALID_CHARACTERS_ERROR'), function (value) {
                return allowedCharacters(value!)
            }),
    }).defined();

    const formSubmit = async (values: NewAnnouncementType): Promise<void> => {


        if (isNewAnnouncement) {
            setValuesTemp(values)
            newAnnouncement()
        } else {
            setIsLoading(true)

            setTimeout(() => {
                try {
                    const tempStartTime = typeof values.startTime === 'object' ? values.startTime.toTimeString().split(" ")[0] : values.startTime;
                    const tempEndTime = typeof values.endTime === 'object' ? values.endTime.toTimeString().split(" ")[0] : values.endTime;

                    setNewDialogShown(false)
                    updatePost({
                        endDate: values.endDate,
                        frequency: values.frequency,
                        message: values.message,
                        publishDate: values.publishDate,
                        title: values.title,
                        endTime: tempEndTime,
                        startTime: tempStartTime,
                        id: location?.state?.results?.id
                    })
                    .then((res) => {
                        setIsLoading(false)
                        showAlert({ type: 'success', message: t('ANNOUNCEMENT.SUCCESS_UPDATE') });
                        navigate(`/announcements`)
                    }).catch((error) => {
                        setIsLoading(false)
                        showAlert({ type: 'error', message: `${t('ANNOUNCEMENT.FAIL_UPDATE')} ${error.message.match(/"errorMessage":"([^"]+)"/)[1]}` });
                        return Promise.reject(error);
                    })
                } catch (error) {
                    setIsLoading(false)
                    return Promise.reject(error);
                }
            }, 100)
        }
    }

    const newAnnouncement = () => {
        setNewDialogShown(true);
    }

    const confirmNewAnnouncement = async (setLoading: any) => {
        try {
            setIsLoading(true)
            createPost({
                endDate: valuesTemp.endDate,
                endTime: valuesTemp.endTime,
                startTime: valuesTemp.startTime,
                frequency: valuesTemp.frequency,
                message: valuesTemp.message,
                publishDate: valuesTemp.publishDate,
                title: valuesTemp.title,
                id: location?.state?.results?.id
            })
            .then((res) => {
                showAlert({ type: 'success', message: t('ANNOUNCEMENT.SUCCESS_CREATE') });
                navigate(`/announcements`)
            }).catch((error) => {
                setLoading(false)
                setNewDialogShown(false)
                showAlert({ type: 'error', message: `${t('ANNOUNCEMENT.FAIL_CREATE')} ${error.message.match(/"errorMessage":"([^"]+)"/)[1]}` });
                return Promise.reject(error);
            })
        } catch (error) {
            setLoading(false)
            setNewDialogShown(false)
            showAlert({ type: 'error', message: t('ANNOUNCEMENT.FAIL_CREATE') });
            return Promise.reject(error);
        } finally {
            setIsLoading(false)
        }
    }

    const BackButton = () => (
        <NavLink to={RoutePaths.ANNOUNCEMENTS} className={styles.backButton}>
            <NxButton variant={NxButtonVariant.BACK}>
                Back
            </NxButton>
        </NavLink>
    );


    const NewAnnouncementForm = ({
        values,
        initialValues,
        initialTouched,
        dirty,
        handleSubmit,
        handleChange,
        setFieldValue,
        errors,
        isSubmitting }: FormikProps<NewAnnouncementType>): ReactElement => {
        return (
            <ContentBox header={<BackButton />}>
                    <NewDialog
                        open={newDialogShown && isNewAnnouncement}
                        onClose={() => setNewDialogShown(false)}
                        onConfirm={confirmNewAnnouncement}
                    />
                        <form onSubmit={handleSubmit}>
                            <div className={styles.mainHeaders}>
                                <span className={styles.headerTitle}>{isNewAnnouncement ? "Create" : "Edit"} Announcement</span>
                                <NxButton
                                    type='submit'
                                    className={clsx(styles.button, styles.submit)}
                                    disabled={(() => {
                                        const publishDateIsDirty = values.publishDate !== initialValues.publishDate;
                                        const endDateIsDirty = values.endDate !== initialValues.endDate;
                                        const titleIsDirty = values.title !== initialValues.title;
                                        const messageIsDirty = values.message !== initialValues.message;
                                        const frequencyIsDirty = values.frequency !== initialValues.frequency;
                                        const startTimeIsDirty = values.startTime !== initialValues.startTime;
                                        const endTimeIsDirty = values.endTime !== initialValues.endTime;

                                        if (isLoading) {
                                            return true;
                                        }

                                        if (errors.message || errors.title) {
                                            return true;
                                        }

                                        if (!isNewAnnouncement) {
                                            // If at least one field is dirty, enable the button
                                            if (publishDateIsDirty || endDateIsDirty || titleIsDirty || messageIsDirty || frequencyIsDirty || startTimeIsDirty || endTimeIsDirty) {
                                                return false;
                                            } else {
                                                return true;
                                            }
                                        } else {
                                            // If all fields are dirty, enable the button
                                            if (publishDateIsDirty && endDateIsDirty && titleIsDirty && messageIsDirty && frequencyIsDirty && startTimeIsDirty && endTimeIsDirty) {
                                                return false;
                                            } else {
                                                return true;
                                            }
                                        }
                                    })()}
                                    loaded={!isLoading}
                                >
                                    {isNewAnnouncement ? "Create" : "Update"}
                                </NxButton>
                            </div>

                            <div className={styles.form}>
                                <div className={styles.formRow}>
                            <NxInput error={errors.title} value={values.title} maxLength={32}  name="title" onChange={(event: any) => {
                                        const newValue = event.target.value

                                        setFieldValue('title', newValue)
                                    }} label={"Announcement Title"} className={styles.titleInput} />
                            <span style={{ color: "#00000042" }}>or</span>
                                    <NxButton
                                        type='submit'
                                        className={styles.button}
                                        disabled={true}
                                        loaded={true}
                                        variant={NxButtonVariant.OUTLINED}
                                        onClick={() => { }}
                                    >
                                        {"Select from Template"}
                                    </NxButton>
                                </div>

                                <div className={styles.formRow}>
                            <NxGenericTextField error={errors.message} value={values.message} name="message" label={"Message"} shrinkLabel multiline sx={{ color: "white", width: "70vw" }} fullWidth={true} inputProps={{ maxLength: maxCharacters }} className={styles.messageInput} onChange={(event: any) => {
                                    const newValue = event.target.value;

                                    setFieldValue('message', newValue)
                                    setCharacterCount(newValue.length)
                                    }} />
                                    <span className={styles.characterCount} style={{
                                        color: characterCount === maxCharacters ? 'red' : '#6F85A5'
                                    }}>
                                        {characterCount}/{maxCharacters}
                                    </span>
                                </div>

                                <div className={styles.formRow}>
                                    <NxButton
                                        type='submit'
                                        className={styles.button}
                                        disabled={true}
                                        loaded={true}
                                        onClick={() => { }}
                                    >
                                        {"+ Save Template"}
                                    </NxButton>
                                </div>

                                <Divider className={styles.divider} />

                                <div className={styles.formRow}>
                            <span className={clsx(styles.messageInput, styles.header)}>Announcement Schedule</span>
                                </div>

                                <div className={styles.formRow}>
                                    <NxDatepicker
                                        disablePast={true}
                                        className={styles.select}
                                        name='publishDate'
                                        label={t('ANNOUNCEMENT.DATE_PUBLISHED')}
                                        inputFormat={'MM/DD/YYYY'}
                                        value={values.publishDate ?? ''}
                                        onChange={(value) => setFieldValue(
                                            'publishDate',
                                            dayjs(value).isValid() ? dayjs(value).format('YYYY-MM-DD') : '',
                                            true
                                        )}
                                        disabled={isSubmitting || isLoading} />



                                    <NxDatepicker
                                        disablePast={true}
                                        className={styles.select}
                                        name='endDate'
                                        label={t('ANNOUNCEMENT.DATE_END')}
                                        inputFormat={'MM/DD/YYYY'}
                                        value={values.endDate ?? ''}
                                        onChange={(value) => setFieldValue(
                                            'endDate',
                                            dayjs(value).isValid() ? dayjs(value).format('YYYY-MM-DD') : '',
                                            true
                                        )}
                                        disabled={isSubmitting || isLoading} />
                                </div>

                                <div className={styles.formRow}>
                                    <TimePicker
                                        className={styles.select}
                                        name='startTime'
                                        label={'Start Time'}
                                        value={values.startTime ?? null}
                                        onOpen={() => {
                                            return setFieldValue(
                                                'startTime',
                                                dayjs(new Date()).isValid() ? dayjs("00:00:00").format('HH:mm:ss') : '',
                                                true
                                            )
                                        }}
                                        onChange={(value: any) => {
                                            return setFieldValue(
                                                'startTime',
                                                dayjs(value).isValid() ? dayjs(value).format('HH:mm:ss') : '',
                                                true
                                            )
                                        }}
                                    />
                                    <TimePicker
                                        className={styles.select}
                                        name='endTime'
                                        label={'End Time'}
                                        value={values.endTime ?? null}
                                        onOpen={() => {
                                            return setFieldValue(
                                                'endTime',
                                                dayjs(new Date()).isValid() ? dayjs("00:00:00").format('HH:mm:ss') : '',
                                                true
                                            )
                                        }}
                                        onChange={(value: any) => {
                                            return setFieldValue(
                                                'endTime',
                                                dayjs(value).isValid() ? dayjs(value).format('HH:mm:ss') : '',
                                                true
                                            )
                                        }}
                                    />
                                </div>

                                <div className={styles.formRow}>
                                    <NxGenericTextField
                                        name='frequency'
                                        className={styles.select}
                                        disabled={false}
                                        label={"Frequency"}
                                        value={values.frequency}
                                        onChange={handleChange}
                                        select
                                    >
                                        {FREQUENCY.map((value) => (
                                            <MenuItem key={value} value={value}>{formatString(value)}</MenuItem>
                                        ))}
                                    </NxGenericTextField>
                                </div>
                            </div>
                        </form>

            </ContentBox>
        )
    }

    const getTime = (date:string, time: string) => {
        if (!time) {
            return
        }
        // @ts-ignore
        if (new Date(`${date}T${time}`) === 'Invalid Date') {
            return null
        } else {
            return new Date(`${date}T${time}`)
        }
    }

    const formInitValues = {
        title: location?.state?.results?.title ?? '',
        message: location?.state?.results?.message ?? '',
        publishDate: location?.state?.results?.publishDate ?? '',
        startTime: getTime(location?.state?.results?.publishDate, location?.state?.results?.startTime) ?? null,
        endDate: location?.state?.results?.endDate ?? '',
        endTime: getTime(location?.state?.results?.endDate, location?.state?.results?.endTime) ?? null,
        frequency: location?.state?.results?.frequency ?? ''
    };

    return (
        <div className={styles.container}>
            <Formik<NewAnnouncementType>
                initialValues={formInitValues}
                enableReinitialize
                onSubmit={formSubmit}
                validationSchema={announcementSchema}
            >
                {NewAnnouncementForm}
            </Formik>
        </div>
    )
}
