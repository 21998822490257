import React from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as IconLock} from '../../../../../assets/icons/icon-lock.svg';
import {ReactComponent as IconUnlock} from '../../../../../assets/icons/icon-unlock.svg';
import {Permission} from '../../../../../shared/auth/model';
import NxButton from '../../../../shared/nxButton/NxButton';
import styles from './LockUnlockButton.module.scss';

export interface LockUnlockButtonProps {
  loaded?: boolean;
  onClick?: () => void;
  isLocked: boolean;
  isDeletedAccount: boolean;
}

export default function LockUnlockButton({ isLocked, loaded, onClick, isDeletedAccount }: LockUnlockButtonProps) {

  const {t} = useTranslation();

  return (
    <NxButton className={styles.button}
              onClick={onClick}
              loaded={loaded}
              disabled={!loaded || isDeletedAccount}
              permission={Permission.CUSTOMER_ACCESS_WRITE}>
      {isLocked ? <IconUnlock /> : <IconLock />}
      {isLocked ? t('SHARED.UNBLOCK') + " Device" : t('SHARED.BLOCK') + " Device"}
    </NxButton>
  );
}