import {Button, PropTypes} from '@mui/material';
import clsx from 'clsx';
import React, {ReactElement} from 'react';
import {useAuth} from '../../../shared/auth/context';
import {Permission} from '../../../shared/auth/model';
import {hasRequiredPermission} from '../../../shared/auth/utils';
import NxLoader, {NxLoaderColorVariant, NxLoaderVariant} from '../nxLoader/NxLoader';
import {ReactComponent as AddIcon} from '../../../assets/icons/icon-add.svg';
import {ReactComponent as LeftArrowIcon} from '../../../assets/icons/icon-arrow-left.svg';
import {ReactComponent as RightArrowIcon} from '../../../assets/icons/icon-arrow-right.svg';
import {ReactComponent as CloseIcon} from '../../../assets/icons/icon-close.svg';
import {ReactComponent as SaveIcon} from '../../../assets/icons/icon-save.svg';
import {ReactComponent as DeleteIcon} from '../../../assets/icons/icon-delete.svg';
import {ReactComponent as UndoIcon} from '../../../assets/icons/icon-undo.svg';
import {ReactComponent as BackIcon} from '../../../assets/icons/icon-back.svg';
import { ReactComponent as ProfileDelete } from '../../../assets/icons/icon-delete-profile.svg';
import styles from './NxButton.module.scss';
import {MATERIAL_SECONDARY_COLOR_VARIANT, MaterialButtonVariant, NxButtonVariant} from './NxButtonVariants';

export {NxButtonVariant} from './NxButtonVariants';

export interface NxButtonProps {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  endIcon?: React.ReactElement | null;
  loaded?: boolean;
  onClick?: () => void;
  startIcon?: React.ReactElement | null;
  type?: string;
  variant?: NxButtonVariant;
  permission?: Permission;
}

type ButtonColors = PropTypes.Color | "error"

const NxButton = (
  {
    children,
    className,
    disabled = false,
    endIcon,
    loaded = true,
    onClick,
    startIcon,
    type = 'button',
    variant = NxButtonVariant.CONTAINED,
    permission
  }: NxButtonProps
): ReactElement => {

  const {state} = useAuth();

  let buttonVariant = MaterialButtonVariant.CONTAINED;
  let materialUiColorVariant: ButtonColors = 'primary';

  switch (variant) {
    case NxButtonVariant.ADD:
      startIcon = <AddIcon className={styles.addIcon} />;
      break;
    case NxButtonVariant.UNDO:
      buttonVariant = MaterialButtonVariant.OUTLINED;
      startIcon = <UndoIcon className={styles.undoIcon} />;
      break;
    case NxButtonVariant.CLOSE:
      buttonVariant = MaterialButtonVariant.OUTLINED;
      startIcon = <CloseIcon className={styles.closeIcon} />;
      break;
    case NxButtonVariant.NEXT:
      endIcon = <RightArrowIcon className={styles.arrowIcon} />;
      break;
    case NxButtonVariant.OUTLINED:
      buttonVariant = MaterialButtonVariant.OUTLINED;
      break;
    case NxButtonVariant.PREVIOUS:
      startIcon = <LeftArrowIcon className={styles.arrowIcon} />;
      break;
    case NxButtonVariant.SAVE:
      buttonVariant = MaterialButtonVariant.OUTLINED;
      startIcon = <SaveIcon className={styles.saveIcon} />;
      break;
    case NxButtonVariant.TEXT:
      buttonVariant = MaterialButtonVariant.TEXT;
      break;
    case NxButtonVariant.REJECT:
      startIcon = <CloseIcon className={clsx([styles.closeIcon, styles.closeIcon_white])} />;
      materialUiColorVariant = MATERIAL_SECONDARY_COLOR_VARIANT;
      break;
    case NxButtonVariant.DELETE:
      buttonVariant = MaterialButtonVariant.OUTLINED;
      startIcon = <DeleteIcon className={styles.deleteIcon} />;
      materialUiColorVariant = 'error';
      break;
    case NxButtonVariant.CANCEL:
      materialUiColorVariant = 'error';
      break;
    case NxButtonVariant.BACK:
      buttonVariant = MaterialButtonVariant.TEXT;
      startIcon = <BackIcon className={styles.backIcon} />;
      break;
    case NxButtonVariant.PROFILE_DELETE:
      buttonVariant = MaterialButtonVariant.OUTLINED;
      startIcon = <ProfileDelete className={styles.deleteIcon} />;
      materialUiColorVariant = 'error';
      break;
    default:
      break;
  }

  const loaderColorVariant =
    buttonVariant === MaterialButtonVariant.OUTLINED || buttonVariant === MaterialButtonVariant.TEXT
      ? NxLoaderColorVariant.PRIMARY
      : NxLoaderColorVariant.WHITE;

  const buttonClasses = {
    endIcon: clsx(
      styles.icon_end,
      {
        [styles.hidden]: !loaded
      }
    ),
    startIcon: clsx(
      styles.icon,
      {
        [styles.hidden]: !loaded
      }
    )
  };

  const buttonClassName = clsx(className, styles.button, {[styles.button_disabled]: disabled});

  return (
    <Button variant={buttonVariant}
            color={materialUiColorVariant}
            classes={buttonClasses}
            className={buttonClassName}
            disableElevation={true}
            disabled={disabled || (permission && !hasRequiredPermission(state.userGroups ?? [], permission))}
            onClick={onClick}
            startIcon={startIcon}
            endIcon={endIcon}
            type={type as 'button' | 'submit'}>
      {
        !loaded && <NxLoader variant={NxLoaderVariant.SMALL} colorVariant={loaderColorVariant} />
      }
      <span className={clsx(styles.label, {[styles.hidden]: !loaded})}>{children}</span>
    </Button>
  );
};

export default NxButton;
