export type ActivitySearchQuery = {
    pageNo?: string;
    pageSize?: string;
    username?: string;
    activityType?: ActivityType;
    fromDate?: string;
    toDate?: string;
}

export type ActivityDownloadQuery = {
    dateFrom?: string;
    dateTo?: string;
    type?: string;
    username?: string;
  };

export type ActivityRow = {
  activityDetailsId: number;
  activityType: string;
  id: number;
  lastChangeTime: string;
  modification: string;
  username: string;
  status?: string;
  remarks?: string;
};

export type ActivityDetails = {
  id: number;
  before?: {
    BIZ_APP_USERNAME?: string;
    STATUS?: string;
    CODE?: string;
    VALUE?: string;
    VISIBILITY?: string;
    TRANSACTIONS_ID?: string;
    REMARKS?: string;
  };
  after: {
    BIZ_APP_USERNAME?: string;
    STATUS?: string;
    CODE?: string;
    VALUE?: string;
    VISIBILITY?: string;
    TRANSACTIONS_ID?: string;
    REMARKS?: string;
  };
};

export enum ActivityType {
    BUSINESS_OWNERS = 'BUSINESS_OWNERS',
    DICTIONARIES = 'DICTIONARIES',
    TRANSACTIONS = 'TRANSACTIONS',
    SYSTEM_PROPERTIES = 'SYSTEM_PROPERTIES'
}
