import clsx from 'clsx';
import React, {ReactElement} from 'react';
import styles from './NxLoader.module.scss';

export enum NxLoaderVariant {
  BIG = 'BIG',
  SMALL = 'SMALL'
}

export enum NxLoaderColorVariant {
  WHITE = 'WHITE',
  TEXT = 'TEXT',
  PRIMARY = 'PRIMARY'
}

export interface NxLoaderProps {
  colorVariant?: NxLoaderColorVariant;
  variant?: NxLoaderVariant;
  className?: string;
}

const NxLoader = ({className, colorVariant, variant = NxLoaderVariant.BIG}: NxLoaderProps): ReactElement => {

  const isButtonSpinner = variant === NxLoaderVariant.SMALL;

  // if colorVariant is not explicitly set: for button spinner white variant is set, for content spinner - text color
  const fragmentColorVariant = colorVariant
    ? colorVariant
    : isButtonSpinner ? NxLoaderColorVariant.WHITE : NxLoaderColorVariant.TEXT;

  const spinnerClass = clsx(
    className,
    {
      [styles.buttonSpinner]: isButtonSpinner,
      [styles.contentSpinner]: !isButtonSpinner
    }
  );

  const spinnerFragmentClass = clsx (
    styles.fragment,
    {
      [styles.fragment_primary]: fragmentColorVariant === NxLoaderColorVariant.PRIMARY,
      [styles.fragment_textColor]: fragmentColorVariant === NxLoaderColorVariant.TEXT
    }
  );

  return (
    <div className={spinnerClass}>
      {
        // generate 4 or 12 graphic spinner parts
        [...Array(isButtonSpinner ? 4 : 12)]
          .map((element, index) => <div key={index} className={spinnerFragmentClass} />)
      }
    </div>
  );
}

export default NxLoader;