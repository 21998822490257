import { InputAdornment } from '@mui/material';
import { isNil } from 'lodash/fp';
import React, { ChangeEvent, useRef, useState } from 'react';
import { Nil } from '../model/SharedTypes';
import NxGenericTextField from '../nxGenericTextField/NxGenericTextField';
import styles from './NxPasswordInput.module.scss';
import { PasswordVisibilityButton } from './PasswordVisibilityButton';

export interface NxPasswordInputProps {
  className?: string;
  disabled?: boolean;
  error?: string;
  id?: string;
  label: React.ReactNode;
  name?: string;
  onBlur?: (e: ChangeEvent) => void;
  onChange?: (e: ChangeEvent) => void;
  positionAbsoluteError?: boolean;
  required?: boolean;
  value?: string | null;
  autoComplete?: string;
}

const calculateValue = (value: string | Nil, lastValue: string): string => {
  if (isNil(value)) {
    return value === null ? lastValue : '';
  }

  return value;
};

const NxPasswordInput = (
  {
    className,
    disabled = false,
    error,
    id,
    label,
    name,
    onBlur,
    onChange,
    positionAbsoluteError,
    required,
    value,
    autoComplete
  }: NxPasswordInputProps
): React.ReactElement => {

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const lastValue = useRef('');
  const finalValue = calculateValue(value, lastValue.current);
  lastValue.current = finalValue;

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    lastValue.current = event.target.value;
    if (!onChange) {
      return;
    }

    onChange(event);
  };

  const EndAdornment = (
    <InputAdornment className={styles.iconWrapper} position='end'>
      <PasswordVisibilityButton isVisible={isPasswordVisible} setIsVisible={setIsPasswordVisible} />
    </InputAdornment>
  );

  return <NxGenericTextField disabled={disabled}
                             className={className}
                             endAdornment={EndAdornment}
                             error={error}
                             id={id}
                             onBlur={onBlur}
                             onChange={handleChange}
                             label={label}
                             name={name}
                             positionAbsoluteError={positionAbsoluteError}
                             required={required}
                             type={isPasswordVisible ? 'text' : 'password'}
                             autoComplete={autoComplete}
                             value={finalValue} />;
};

export default NxPasswordInput;
