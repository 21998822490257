import dayJs from 'dayjs';
import { TRANSACTION_DATE_FORMAT, TRANSACTION_TIME_FORMAT } from '../../shared/constants/format-templates';
import { GridColDef } from '@mui/x-data-grid';
import { TFunction } from 'i18next';
import { createColDefWithDefaultConfiguration } from '../../shared/utils/dataGridDefaultColumnDefValues';
import { ReactComponent as IconDown } from '../../assets/icons/icon-chevron-down.svg';
import { ReactComponent as BookIcon } from '../../assets/icons/icon-book.svg';
import { ReactComponent as DocumentIcon } from '../../assets/icons/icon-document-small.svg';
import { ReactComponent as GearIcon } from '../../assets/icons/icon-gear.svg';
import { ReactComponent as PersonIcon } from '../../assets/icons/icon-person.svg';
import './ActivityTable.css';

export const activityTableColumnDefinitions =
  (t: TFunction): Array<GridColDef> => createColDefWithDefaultConfiguration(
    [
      {
        field: 'username',
        headerName: t('SHARED.USERNAME') ?? ''
      },
      {
        field: 'activityType',
        headerName: t('ACTIVITY.ACTIVITY_TYPE') ?? '',
        align: 'left',
        cellClassName: 'activity-type',
        renderCell: params => [renderIcon(params.value), t(`${params.value}.BOX_TITLE`)]
      },
      {
        field: 'lastChangeTime',
        headerName: t("SHARED.DATE_AND_TIME") ?? '',
        valueFormatter: ({ value }) => dayJs(value).format(`${TRANSACTION_DATE_FORMAT} ${TRANSACTION_TIME_FORMAT}`)
      },
      {
        field: 'modification',
        headerName: t('ACTIVITY.MODIFICATION') ?? ''
      },
      {
        field: ' ',
        headerName: ' ',
        maxWidth: 40,
        renderCell: () => <IconDown />
      },
    ]
  );

function renderIcon(iconValue: string) {
  switch (iconValue) {
    case 'BUSINESS_OWNERS':
      return <PersonIcon />
    case 'DICTIONARIES':
      return <BookIcon />
    case 'TRANSACTIONS':
      return <DocumentIcon />
    case 'SYSTEM_PROPERTIES':
      return <GearIcon />
  }
}