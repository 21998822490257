import React from 'react';
import {Trans} from 'react-i18next';

interface Props {
  children: string;
  values?: Record<string, unknown>;
}

export default function useTrans(prefix: string): (props: Props) => React.ReactElement {
  return ({children, values}: Props): React.ReactElement => {
    return <Trans values={values}>{`${prefix}.${children}`}</Trans>;
  };
}
