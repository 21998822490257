import { ReactElement, useState, useCallback } from "react";
import clsx from 'clsx';
import ContentBox from '../shared/content-box/ContentBox';
import DataTable from '../shared/table/Table';
import useSingleQuery from '../../shared/hooks/use-single-query.hook';
import { PagedResponse } from '../../shared/model/Page.model';
import { GridRowParams } from '@mui/x-data-grid';
import { Divider } from '@mui/material';
import NxButton from '../shared/nxButton/NxButton';
import NxGenericTextField from "../shared/nxGenericTextField/NxGenericTextField";
import NxLoader from "../shared/nxLoader/NxLoader";
import MenuItem from '@mui/material/MenuItem';
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AnnouncementsType, AnnouncementSearchQuery } from "./Announcements.model";
import { announcementTableColumnDefinitions } from "./announcementTableColumnDefinitions";
import formatString from "../../shared/utils/stringFormatter";
import styles from './Announcements.module.scss';

const ANNOUNCEMENT_STATUS = ["PENDING", "FAILED", "ACTIVE", "COMPLETED", "CANCELLED"];



export default function Announcements(): ReactElement {
    const [statusFilter, setStatusFilter] = useState("");
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { data, isLoading, fetch } = useSingleQuery<PagedResponse<AnnouncementsType>>(`/admin/announcement`, null);

    let rowData: any = [];

    if (data?.result) {
        rowData = data?.result.map((row: any) => {
        if (ANNOUNCEMENT_STATUS.includes(row.status)) {
            const statusIndex = ANNOUNCEMENT_STATUS.indexOf(row.status);
            return { ...row, status: formatString(ANNOUNCEMENT_STATUS[statusIndex]) };
        } else {
            return { ...row }
        }
        });
    }

    const [pageSize, setPageSize] = useState<number | unknown>(10);
    const [searchParams, setSearchParams] = useState<AnnouncementSearchQuery | undefined>();

    const filterStatus = useCallback(async (params: AnnouncementSearchQuery) => {
        try {
            delete searchParams?.pageNo;
            const newParams = { pageSize: String(pageSize), ...searchParams, ...params };
            Object.keys(newParams).forEach((key) => {
                if (newParams[key as keyof AnnouncementSearchQuery] === '') {
                    delete newParams[key as keyof AnnouncementSearchQuery];
                }
            });
            fetch(new URLSearchParams(newParams));
            setSearchParams(newParams);
        } catch (error) {
            return Promise.reject(error);
        }
    }, [fetch, searchParams, pageSize]);


    const handlePageChange = useCallback((pageNo: number) => {
        filterStatus({ pageNo: String(pageNo) });
    }, [filterStatus]);

    const handlePageSizeChange = useCallback((pageSize: number | unknown) => {
        if (location?.state?.pageSize) {
            location.state.pageSize = null;
        }
        setPageSize(pageSize);
        filterStatus({ pageSize: String(pageSize) });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterStatus]);

    return (
        <ContentBox header={""}>
            <div className={styles.container}>
                <div className={styles.headers}>
                    <div className={styles.mainHeaders}>
                        <span className={styles.headerTitle}>Announcements</span>
                        <NxButton
                            type='submit'
                            className={styles.button}
                            disabled={false}
                            loaded={true}
                            onClick={() => { navigate('/announcements/new') }}
                        >
                            {"Create announcement"}
                        </NxButton>

                    </div>
                    <NxGenericTextField
                        name='status'
                        className={styles.select}
                        disabled={false}
                        label={"Status"}
                        value={statusFilter}
                        onChange={(e: any) => {
                            setStatusFilter(e.target.value)
                            filterStatus({ status: e.target.value })
                        }}
                        select
                    >
                        <MenuItem value="">{t('SHARED.EMPTY')}</MenuItem>
                        {ANNOUNCEMENT_STATUS.map((value) => (
                            <MenuItem key={value} value={value}>{t(`ANNOUNCEMENT.${value}`)}</MenuItem>
                        )
                        )}
                    </NxGenericTextField>
                </div>
                <Divider className={styles.divider} />
                {rowData.length > 0 ? (
                    <>
                        <div className={styles.dataContainer}>
                            {isLoading && (
                                <div className={clsx(styles.overlay, styles.loaderContainer)}>
                                    <NxLoader />
                                </div>
                            )}
                        </div>
                        <div className={styles.results}>
                            <span>List of announcements </span>
                            <span className={styles.totalCount}>({data?.totalCount})</span>
                        </div>
                        <DataTable
                            getRowId={(row) => row.id}
                            loading={isLoading}
                            pageInfo={{
                                pageNo: data?.pageNo ?? 0,
                                resultCount: data?.resultCount ?? 0,
                                totalCount: data?.totalCount ?? 0
                            }}
                            pageSize={Number(pageSize)}
                            rows={rowData ?? []}
                            onRowClick={(rowParams: GridRowParams, event: any, details: any): void => {
                                const rowId = rowParams.id as number
                                const foundObject = data?.result.find(obj => obj.id === rowId)

                                return navigate(`${rowParams.id}`, {
                                    state:
                                    {
                                        searchParams: searchParams,
                                        results: foundObject
                                    }
                                })
                            }}
                            onPageNumberChange={handlePageChange}
                            onPageSizeChange={handlePageSizeChange}
                            columns={announcementTableColumnDefinitions(t)}
                            noRowsMessage={""}
                            hideFooterPagination={false}
                            hideFooter={false}
                        />
                    </>
                ) : (<div className={styles.noDataText}>
                        <span>{t('ANNOUNCEMENT.NO_ANNOUNCEMENTS')}</span>
                </div>)}
            </div>
        </ContentBox>
    )
}
