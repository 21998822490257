import { Formik, FormikHelpers, FormikProps } from 'formik';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { object, SchemaOf, string } from 'yup';
import useTrans from '../../../shared/hooks/use-trans.hook';
import NxButton from '../../shared/nxButton/NxButton';
import NxPasswordInput from '../../shared/nxPasswordInput/NxPasswordInput';
import PasswordErrorField from '../../shared/password-error-field/PasswordErrorField';
import { PasswordSetFormFields } from './PasswordSetForm.model';
import {
  checkLowercase,
  checkUppercase,
  checkNumber,
  checkSpecialChar,
  checkInvalidChar
} from '../../../shared/utils/passwordValidationUtils';
import styles from './PasswordSetForm.module.scss';

type PasswordSetFormProps = {
  onSubmit: (password: string) => Promise<void>;
}

export default function PasswordSetForm({ onSubmit }: PasswordSetFormProps): React.ReactElement {

  const { t } = useTranslation();
  const PrefixedTrans = useTrans('LOGIN');

  const submitPasswordChange = async (values: PasswordSetFormFields,
    actions: FormikHelpers<PasswordSetFormFields>): Promise<void> => {
    await onSubmit(values.password)
      .catch(error => actions.setFieldError('generalError', error));
  };

  const loginFormSchema: SchemaOf<PasswordSetFormFields> = object({
    password: string()
      .defined(t('SHARED.FORM.ERROR.REQUIRED'))
      .required()
      .test('min', t('PASSWORD.LENGTH'), function (value) {
        return value?.length! >= 8
      })
      .test('number', t('PASSWORD.NUMBER'), function (value) {
        return checkNumber(value!)
      })
      .test('special', t('PASSWORD.SPECIAL'), function (value) {
        return checkSpecialChar(value!)
      })
      .test('lower', t('PASSWORD.LOWERCASE'), function (value) {
        return checkLowercase(value!)
      })
      .test('invalid', t('PASSWORD.INVALID'), function (value) {
        return !checkInvalidChar(value!)
      })
      .test('upper', t('PASSWORD.UPPERCASE'), function (value) {
        return checkUppercase(value!)
      }),
    generalError: string().notRequired(),
  }).defined();

  const passwordSetForm = ({
    values,
    handleSubmit,
    handleChange,
    isSubmitting
  }: FormikProps<PasswordSetFormFields>): ReactElement => (
    <form onSubmit={handleSubmit}>
      <div className={styles.hint}>
        <PrefixedTrans>SET_PASSWORD_HINT</PrefixedTrans>
      </div>
      <NxPasswordInput name='password'
        className={styles.input}
        disabled={isSubmitting}
        label={<PrefixedTrans>PASSWORD</PrefixedTrans>}
        value={values.password}
        onChange={handleChange} />
      <PasswordErrorField password={values.password} />
      <div className={styles.signInRow}>
        <NxButton type='submit' className={styles.button} loaded={!isSubmitting}>
          <PrefixedTrans>SUBMIT_LABEL</PrefixedTrans>
        </NxButton>
      </div>
    </form>
  );

  const formInitValues = {
    password: '',
    generalError: ''
  };

  return (
    <Formik<PasswordSetFormFields> initialValues={formInitValues}
      onSubmit={submitPasswordChange}
      validateOnMount={false}
      validateOnBlur={true}
      validateOnChange={true}
      validationSchema={loginFormSchema}>
      {passwordSetForm}
    </Formik>
  );
}
