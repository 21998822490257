import {DataGrid} from '@mui/x-data-grid';
import {DataGridProps} from '@mui/x-data-grid/models/props/DataGridProps';
import React, {ChangeEvent, ReactElement} from 'react';
import {PageInfo} from '../../../shared/model/Page.model';
import EmptyTable from '../empty-table/EmptyTable';
import NxLoader, {NxLoaderColorVariant, NxLoaderVariant} from '../nxLoader/NxLoader';
import TableFooter from './table-footer/TableFooter';
// import TableFooter from './table-footer/TableFooter';

export type TableProps = Pick<DataGridProps,
  'loading' |
  'columns' |
  'rows' |
  'onRowClick' |
  'getRowId' |
  'onPageChange' |
  'pageSize' |
  'className' |
  'autoPageSize' |
  'hideFooter' |
  'hideFooterPagination'> & {
    pageInfo?: PageInfo;
    noRowsMessage: string;
    tableFooter?: React.JSXElementConstructor<any>;
    onPageNumberChange?: (pageNo: number, event?: ChangeEvent<unknown>) => void;
    onPageSizeChange?: (pageSize: number | unknown) => void;
    pageSizeOptions?: number[];
  };

const DataTable = (props: TableProps): ReactElement => {
  const {tableFooter, ...rest} = props;
  return (
    <DataGrid
      {...rest}
      page={props.pageInfo?.pageNo}
      rowCount={props.pageInfo?.resultCount}
      pagination
      hideFooterPagination={props.hideFooterPagination}
      hideFooter={props.hideFooter}
      disableColumnMenu
      disableSelectionOnClick
      autoHeight
      autoPageSize={props.autoPageSize}
      rowHeight={50}
      headerHeight={50}
      components={{
        LoadingOverlay: NxLoader,
        NoRowsOverlay: EmptyTable,
        Footer: tableFooter ?? (() => <TableFooter {...rest} />)
      }}
      componentsProps={{
        loadingOverlay: {
          variant: NxLoaderVariant.SMALL,
          colorVariant: NxLoaderColorVariant.TEXT
        },
        noRowsOverlay: {
          message: props.noRowsMessage
        }
      }}
    />
  );
};

export const SimpleDetailsDataTable = (props: TableProps): ReactElement => (
  <DataGrid
    {...props}
    page={props.pageInfo?.pageNo}
    rowCount={props.pageInfo?.totalCount}
    pagination
    hideFooterPagination
    hideFooter
    disableColumnMenu
    disableSelectionOnClick
    sx={{
      '& .MuiDataGrid-row:hover': {
        background: 'inherit !important',
      },
      '& .MuiDataGrid-cell, & .MuiDataGrid-columnHeader': {
        padding: '0 !important',
      },
      '& .MuiDataGrid-row': {
        cursor: 'auto !important',
      }
    }}
    autoHeight
    rowHeight={50}
    headerHeight={50}
    components={{
      LoadingOverlay: NxLoader,
      NoRowsOverlay: EmptyTable
    }}
    componentsProps={{
      loadingOverlay: {
        variant: NxLoaderVariant.SMALL,
        colorVariant: NxLoaderColorVariant.TEXT
      },
      noRowsOverlay: {
        message: props.noRowsMessage
      }
    }} />
);

export default DataTable;
