import { Formik, FormikHelpers, FormikProps } from 'formik';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import MenuItem from '@mui/material/MenuItem';
import NxButton from '../../shared/nxButton/NxButton';
import NxGenericTextField from '../../shared/nxGenericTextField/NxGenericTextField';
import { REPORTS_DATE_FORMAT, TRANSACTION_DATE_FORMAT } from '../../../shared/constants/format-templates';
import NxDatepicker from '../../shared/nxDatepicker/NxDatepicker';
import { ReportGroup, ReportsDownloadQuery } from '../Reports.model';
import styles from './ReportsDownloadForm.module.scss';
import { useAuth } from '../../../shared/auth/context';
import { hasRequiredPermission } from '../../../shared/auth/utils';
import { Permission } from '../../../shared/auth/model';
import { useAlert } from '../../../shared/context/alert';

type ReportsDownloadFormProps = {
  isLoading: boolean;
  onSubmit: (newParams: ReportsDownloadQuery) => Promise<void>;
};

export default function ReportsDownloadForm({ isLoading, onSubmit }: ReportsDownloadFormProps): React.ReactElement {
  const { state } = useAuth();
  const { t } = useTranslation();
  const { showAlert } = useAlert();
  const isMofdReportsVisible = hasRequiredPermission(state.userGroups ?? [], Permission.REPORTS_DELETED_ACCOUNTS_ONLY);
  const isRegisteredAccountsReportsVisible = hasRequiredPermission(state.userGroups ?? [], Permission.REGISTERED_ACCOUNTS);
  const isInvoicesVisible = hasRequiredPermission(state.userGroups ?? [], Permission.INVOICES);
  const isPayrollsVisible = hasRequiredPermission(state.userGroups ?? [], Permission.PAYROLLS);
  const isAdmin = state?.userGroups?.find(group => group.includes('ADMIN'))

  const submitReportDownload = async (
    values: ReportsDownloadQuery,
    actions: FormikHelpers<ReportsDownloadQuery>): Promise<void> => {
    if (new Date(values.dateTo) < new Date(values.dateFrom)) {
      actions.setFieldError('dateFrom', '')
      actions.setFieldError('dateTo', '')
      showAlert({ type: 'error', message: `Invalid date. Date To field should not be earlier than Date From field` });
      throw new Error();
    }
    onSubmit(values).catch(error => actions.setFieldError('generalError', error));
  };

  const ReportsDownloadForm = ({
    values,
    errors,
    handleSubmit,
    handleChange,
    isSubmitting,
    setFieldValue
  }: FormikProps<ReportsDownloadQuery>): ReactElement => (
    <form onSubmit={handleSubmit} className={styles.form}>
      <NxGenericTextField
        name='report'
        className={styles.select}
        disabled={isSubmitting || isLoading || isMofdReportsVisible}
        label={t('REPORTS.REPORT')}
        value={isMofdReportsVisible ? ReportGroup.DELETED_ACCOUNTS : values.report}
        error={errors.report}
        onChange={handleChange}
        select
      >
        {isMofdReportsVisible ? (
          <MenuItem key={ReportGroup.DELETED_ACCOUNTS} value={ReportGroup.DELETED_ACCOUNTS}>
            {t(`REPORTS.REPORT_GROUPS.DELETED_ACCOUNTS`)}
          </MenuItem>
        ) : (Object.entries(ReportGroup)
          .filter(([key]) => {
            const isRegistered = isRegisteredAccountsReportsVisible || key !== "REGISTERED_ACCOUNTS";

            const isMofdOrAdmin = (isMofdReportsVisible || isAdmin) || key !== "DELETED_ACCOUNTS";

            const isAdminForFeedbacks = isAdmin || key !== "FEEDBACKS";

            const isPayrolls = (isAdmin || isPayrollsVisible) || key !== "PAYROLLS";

            const isInvoices = (isAdmin || isInvoicesVisible) || key !== "INVOICES";

            return isRegistered && isMofdOrAdmin && isAdminForFeedbacks && isPayrolls && isInvoices;
          })
          .map(([key, value]) => (
            value === 'payrolls'
              ? <MenuItem key={key} value={value}>Payroll</MenuItem>
              : <MenuItem key={key} value={value}>{t(`REPORTS.REPORT_GROUPS.${key}`)}</MenuItem>
          )))}
      </NxGenericTextField>
      <NxDatepicker
        disableFuture={true}
        name='dateFrom'
        label={t('SHARED.DATE_FROM')}
        inputFormat={TRANSACTION_DATE_FORMAT}
        value={values.dateFrom ?? ''}
        error={errors.dateFrom}
        onChange={(value) => setFieldValue(
          'dateFrom',
          dayjs(value).isValid() ? dayjs(value).format(REPORTS_DATE_FORMAT) : '',
          true
        )}
        disabled={isSubmitting || isLoading} />
      <NxDatepicker
        minDate={values.dateFrom && dayjs(values.dateFrom)}
        disableFuture={true}
        name='dateTo'
        label={t('SHARED.DATE_TO')}
        inputFormat={TRANSACTION_DATE_FORMAT}
        value={values.dateTo ?? ''}
        error={errors.dateTo}
        onChange={(value) => setFieldValue(
          'dateTo',
          dayjs(value).isValid() ? dayjs(value).format(REPORTS_DATE_FORMAT) : '',
          true
        )}
        disabled={isSubmitting || isLoading} />
      <NxButton
        type='submit'
        className={styles.button}
        disabled={isLoading || Object.values(values).filter((v) => !!v).length < 3}
        loaded={!isSubmitting && !isLoading}
      >
        {t('REPORTS.SUBMIT_LABEL')}
      </NxButton>
    </form>
  );

  const initialValues = {
    report: isMofdReportsVisible ? ReportGroup.DELETED_ACCOUNTS : ReportGroup.BILLS_PAYMENT,
    dateFrom: '',
    dateTo: ''
  };

  return (
    <Formik<ReportsDownloadQuery>
      initialValues={initialValues}
      onSubmit={submitReportDownload}
    >
      {ReportsDownloadForm}
    </Formik>
  );
}
