import {ReactElement} from 'react';
import {TransHelper} from '../../../../shared/utils/trans-helper';
import {useTranslation} from 'react-i18next';
import ContentBox from '../../../shared/content-box/ContentBox';
import {NavLink, useNavigate, useLocation} from 'react-router-dom';
import {RoutePaths} from '../../../../router';
import {Divider, Switch} from '@mui/material';
import {Formik, FormikProps, FieldArray, Form} from 'formik';
import usePost from '../../../../shared/hooks/use-post.hook';
import {useAlert} from '../../../../shared/context/alert';
import NxButton, {NxButtonVariant} from '../../../shared/nxButton/NxButton';
import NxInput from '../../../shared/nxInput/NxInput';
import styles from './NewRecord.module.scss';
import {NewRecordForm, DictionaryNewRecord} from './NewRecord.model';

const NewRecord = (): ReactElement => {

  const {t} = useTranslation();
  const SharedTrans = TransHelper.getPrefixedTrans('SHARED');
  const PrefixedTrans = TransHelper.getPrefixedTrans('DICTIONARIES');
  const location = useLocation();
  const navigate = useNavigate();
  const {showAlert} = useAlert();
  const addNewRecord = usePost(`/admin/system/dictionaries/entries`);
  const previousRoute = `${RoutePaths.DICTIONARIES}/${location.state}`;

  const formSubmit = async (values: DictionaryNewRecord): Promise<void> => {
    const payload = {
      dictCode: location.state,
      entries: values.entries
    }
    const multipleRecords = payload.entries.length > 1;
    const messageRecord = multipleRecords ?
                          payload.entries.length : `${payload.entries[0].code} - ${payload.entries[0].value}`;

    try {
      await addNewRecord(payload)
      showAlert({
        type: 'success',
        message: t(`DICTIONARIES.NEW_RECORD.MESSAGE_CODE.${multipleRecords ? 'ADD_RECORD_MULTIPLE_SUCCESS' : 'ADD_RECORD_SUCCESS'}`, {record: messageRecord})
      })
      navigate(previousRoute)
    } catch (error) {
      showAlert({
        type: 'error',
        message: t('DICTIONARIES.NEW_RECORD.MESSAGE_CODE.ADD_RECORD_FAIL')
      })
      return Promise.reject(error);
    }
  };

  const BackButton = () => (
    <NavLink to={previousRoute} className={styles.backButton}>
      <NxButton variant={NxButtonVariant.BACK}>
        <SharedTrans>BACK</SharedTrans>
      </NxButton>
    </NavLink>
  );

  const NewRecordForm = ({
                        values,
                        handleChange,
                        isSubmitting
                      }: FormikProps<DictionaryNewRecord>): ReactElement => {
  return (
    <Form>
      <FieldArray
        name='entries'
        render={arrayHelpers => {
          return (
            <div>
              {(values.entries.map((entry: NewRecordForm, index: number) => {
                  return (
                    <div key={index}>
                    <div className={styles.rowForm}>
                      <div className={styles.rowFormField}>
                        <NxInput
                          name={`entries.${index}.code`}
                          className={styles.codeInput}
                          value={entry.code}
                          required
                          onChange={handleChange}
                          label={t('DICTIONARIES.CODE_LABEL')}
                        />
                        <NxInput
                          name={`entries.${index}.value`}
                          className={styles.valueInput}
                          value={entry.value}
                          required
                          onChange={handleChange}
                          label={t('DICTIONARIES.VALUE_LABEL')}
                        />
                        <Switch
                          name={`entries.${index}.enabled`}
                          checked={entry.enabled}
                          disableFocusRipple
                          disableRipple
                          disableTouchRipple
                          classes={{
                            switchBase: styles.switchBase,
                            track: styles.switchTrack,
                            thumb: styles.switchThumb,
                            checked: styles.switchChecked,
                          }}
                          sx={{
                            "& .MuiSwitch-track": {
                              backgroundColor: "#e8eff7",
                            },
                            "&-MuiSwitch-base": {
                              "&-MuiSwitch-checked": {
                                backgroundColor: "#555",
                              },
                            },
                          }}
                          className={styles.switch}
                          onChange={handleChange}
                        />
                      </div>
                      {values.entries.length - 1 === index ? (
                        <NxButton
                          onClick={() => {
                            arrayHelpers.push({
                              code: '',
                              value: '',
                              enabled: false,
                            });
                          }}
                          variant={NxButtonVariant.TEXT}
                          className={styles.button}
                        >
                          + <PrefixedTrans>NEW_RECORD.ADD_RECORD</PrefixedTrans>
                        </NxButton>
                      ) : (
                        <NxButton
                          onClick={() => arrayHelpers.remove(index)}
                          variant={NxButtonVariant.DELETE}
                          className={`${styles.button} ${styles.deleteButton}`}
                        >
                          <SharedTrans>FORM.DELETE</SharedTrans>
                        </NxButton>
                      )}
                    </div>
                    {index !== values.entries.length - 1 && <Divider />}
                    </div>
                  );
                })
              )}
              <Divider className={styles.divider} />
              <NxButton
                className={styles.saveButton}
                type='submit'
                loaded={!isSubmitting}
              >
                <SharedTrans>FORM.SAVE</SharedTrans>
              </NxButton>
            </div>
          )
        }}
      />
    </Form>
  );
};

const formInitValues = {
  entries: [
    {
      code: '',
      value: '',
      enabled: false,
    }
  ]
};

  return (
    <ContentBox header={<BackButton/>}>
      <div className={styles.header}>
        <PrefixedTrans>NEW_RECORD.BOX_TITLE</PrefixedTrans>
      </div>

      <div className={styles.row}>
        <Formik<DictionaryNewRecord>
          initialValues={formInitValues}
          enableReinitialize
          onSubmit={formSubmit}
        >
          {NewRecordForm}
        </Formik>
      </div>
    </ContentBox>
  );
};

export default NewRecord;
