import clsx from 'clsx';
import {HTMLAttributes} from 'react';
import styles from './DetailsBox.module.scss';

type DetailBoxProps = Pick<HTMLAttributes<HTMLDivElement>, 'children' | 'className'>;

export default function DetailBox({children, className}: DetailBoxProps) {
  return (
    <div className={clsx(styles.box, className)}>
      {children}
    </div>
  );
}