import React, {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {NavLink, useParams, useNavigate} from 'react-router-dom';
import {RoutePaths} from '../../../router';
import {useAlert} from '../../../shared/context/alert';

import useSingleQuery from '../../../shared/hooks/use-single-query.hook';
import {TransHelper} from '../../../shared/utils/trans-helper';

import ContentBox from '../../shared/content-box/ContentBox';
import NxButton, {NxButtonVariant} from '../../shared/nxButton/NxButton';
import NxLoader from '../../shared/nxLoader/NxLoader';
import ListRow from './list-row/ListRow';

import {DictionaryEntry, DictionaryListQuery} from './List.model';

import styles from './List.module.scss';

const List = (): ReactElement => {

  const usePut = React.useRef<any>(0);
  const navigate = useNavigate();
  const PrefixedTrans = TransHelper.getPrefixedTrans('DICTIONARIES');
  const SharedTrans = TransHelper.getPrefixedTrans('SHARED');
  const {dictionaryCode} = useParams();
  const {showAlert} = useAlert();
  const {t} = useTranslation();
  const {data, isLoading}: DictionaryListQuery = useSingleQuery(`/admin/system/dictionaries/${dictionaryCode}`);

  const submitListUpdate = async (entry: Omit<DictionaryEntry, 'dictCode' | 'variant'>): Promise<void> => {
    try {
      await usePut.current({code: entry.code, enabled: entry.enabled, entryId: entry.id, value: entry.value})
        .then(() => showAlert({
          type: 'success',
          message: t('DICTIONARIES.UPDATE_SUCCESS')
        }))
    } catch (error) {
      showAlert({
        type: 'error',
        message: t('DICTIONARIES.UPDATE_FAIL')
      })
      return Promise.reject(error);
    }
  };

  const getIdToUpdate = (updateEntry: any) => {
    usePut.current = updateEntry;
  };

  const BackButton = () => (
    <NavLink to={RoutePaths.DICTIONARIES} className={styles.backButton}>
      <NxButton variant={NxButtonVariant.BACK}>
        <SharedTrans>BACK</SharedTrans>
      </NxButton>
    </NavLink>
  );

  return (
    <ContentBox header={<BackButton />}>
      <div className={styles.header}>
        <PrefixedTrans>{`CODE.${dictionaryCode}`}</PrefixedTrans>
      </div>
      <div className={styles.entriesContainer}>
        <div className={styles.formHeader}>
          <div><PrefixedTrans>CODE_LABEL</PrefixedTrans></div>
          <div><PrefixedTrans>VALUE_LABEL</PrefixedTrans></div>
          <div><PrefixedTrans>VISIBILITY_LABEL</PrefixedTrans></div>
        </div>
        {isLoading || !data?.entries.length ?
        <NxLoader/> : (
          data?.entries.map(entry => (
            <ListRow
              key={entry.id}
              entry={entry}
              getIdToUpdate={getIdToUpdate}
              onSubmit={submitListUpdate}
            />
          ))
        )}
      </div>
      <NxButton
        className={styles.button}
        onClick={() => navigate(`${RoutePaths.DICTIONARIES}/${dictionaryCode}/new-record`, { state: dictionaryCode })}
      >
        + <PrefixedTrans>NEW_RECORD_ADD</PrefixedTrans>
      </NxButton>
    </ContentBox>
  );
};

export default List;
