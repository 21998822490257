import {AuthenticationDetails, CognitoUser, CognitoUserSession} from 'amazon-cognito-identity-js';
import {CognitoJwtVerifier} from 'aws-jwt-verify';
import React, {Dispatch, ReactElement} from 'react';
import { cognitoUserPool, cognitoIdentityProvider } from '../../index';
import {AuthData, AuthState, UserGroup} from './model';

const channel = new BroadcastChannel('BIZAPP');

export type ActionType =
  'SIGN_IN_SUCCESS' |
  'SIGN_IN_FAIL' |
  'PASSWORD_CHANGE_REQUIRED' |
  'PASSWORD_CHANGE_FAIL' |
  'SIGNED_OUT';

type Action = {
  type: ActionType;
  payload?: Partial<AuthData>;
};

const AuthContext = React.createContext<{state: AuthData; dispatch: Dispatch<Action>} | undefined>(undefined);

const authReducer = (state: AuthData, action: Action): AuthData => {
  switch (action.type) {
    case 'SIGN_IN_SUCCESS': {
      return {
        ...action.payload,
        authState: AuthState.SIGNED_IN
      };
    }
    case 'SIGNED_OUT':
    case 'SIGN_IN_FAIL': {
      return {authState: AuthState.NOT_SIGNED_IN};
    }
    case 'PASSWORD_CHANGE_REQUIRED': {
      return {
        ...action.payload,
        authState: AuthState.PASSWORD_CHANGE_REQUIRED
      };
    }
    case 'PASSWORD_CHANGE_FAIL': {
      return {authState: AuthState.PASSWORD_CHANGE_REQUIRED};
    }
    default: {
      throw new Error(`Unhandled action type: ${action}`);
    }
  }
};

export const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
};

export const AuthProvider = ({children}: {children: ReactElement}): ReactElement => {
  const [state, dispatch] = React.useReducer(authReducer, {authState: AuthState.NOT_SIGNED_IN});
  const value = {state, dispatch};
  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const adminGetUser = async (username: string) => {
  const params = {
    UserPoolId: cognitoUserPool.getUserPoolId(),
    Username: username,
  }

  try {
    const data = await cognitoIdentityProvider.adminGetUser(params);
    return data;
  } catch (err) {
    throw err;
  }
}

export const checkAuthState = async (dispatch: Dispatch<Action>): Promise<void> => {
  const cognitoUser = cognitoUserPool.getCurrentUser();
  return new Promise<void>((resolve) => {
    if (cognitoUser) {
      cognitoUser.getSession((error: null, session: CognitoUserSession) => {
        const idTokenPayload = session.getIdToken().decodePayload();
        const tokenExp = idTokenPayload['exp'];
        const isTokenBeforeExpiration = Date.now() <= tokenExp * 1000;
        if (session.isValid() && isTokenBeforeExpiration) {
          dispatch({
            type: 'SIGN_IN_SUCCESS',
            payload: {
              userGroups: idTokenPayload['cognito:groups'] as Array<UserGroup>,
              username: idTokenPayload['cognito:username'],
              apiToken: session.getIdToken().getJwtToken()
            }
          });
        }
      });
    }
    resolve();
  });
};

export const signIn = async (username: string, password: string, dispatch: Dispatch<Action>): Promise<void> => {
  const authenticationDetails = new AuthenticationDetails({
    Username: username,
    Password: password
  });
  const cognitoUser = new CognitoUser({
    Username: username,
    Pool: cognitoUserPool,
    Storage: window.sessionStorage
  });
  return new Promise<void>((resolve, reject) => {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: async (userSession) => {
        window.localStorage.setItem('isLoggedIn', 'true')

        channel.postMessage('signin')

        const idTokenVerifier = CognitoJwtVerifier.create({
          userPoolId: cognitoUserPool.getUserPoolId(),
          tokenUse: 'id',
          clientId: cognitoUserPool.getClientId()
        });
        try {
          const idTokenPayload = await idTokenVerifier.verify(userSession.getIdToken().getJwtToken());
          dispatch({
            type: 'SIGN_IN_SUCCESS',
            payload: {
              userGroups: idTokenPayload['cognito:groups'] as Array<UserGroup>,
              username: idTokenPayload['cognito:username'],
              apiToken: userSession.getIdToken().getJwtToken()
            }
          });
        } catch (error) {
          reject('Token invalid');
          dispatch({type: 'SIGN_IN_FAIL'});
        }
      },
      newPasswordRequired: () => {
        dispatch({
          type: 'PASSWORD_CHANGE_REQUIRED',
          payload: {
            username,
            cognitoUser
          }
        });
      },
      onFailure: (error) => {
        const errorMsg = error.message || JSON.stringify(error);
        dispatch({type: 'SIGN_IN_FAIL'});
        reject(errorMsg);
      }
    });
  });
};

export const signOut = async (dispatch: Dispatch<Action>): Promise<void> => {
  const cognitoUser = cognitoUserPool.getCurrentUser();

  return new Promise<void>((resolve) => {
    if (cognitoUser) {
      cognitoUser.getSession(() => {
        cognitoUser.globalSignOut({
          onSuccess: (msg) => {
            dispatch({
              type: 'SIGNED_OUT'
            });
            window.localStorage.setItem('isLoggedIn', 'false')
            window.sessionStorage.clear();
            channel.postMessage('signout')
            window.location.reload();
          },
          onFailure: (msg) => {
            dispatch({
              type: 'SIGNED_OUT'
            });
          }
        });
      });
    } else {
      channel.postMessage('refresh')
      window.location.reload();
    }
    resolve();
  });
};

export const setNewPassword = async (newPassword: string,
                                     cognitoUser: CognitoUser | undefined,
                                     dispatch: Dispatch<Action>): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    if (!cognitoUser) {
      dispatch({type: 'PASSWORD_CHANGE_FAIL'});
      reject('User in context not present');
      return;
    }

    cognitoUser.completeNewPasswordChallenge(newPassword, {}, {
      onSuccess: async (userSession: CognitoUserSession) => {
        const idTokenVerifier = CognitoJwtVerifier.create({
          userPoolId: cognitoUserPool.getUserPoolId(),
          tokenUse: 'id',
          clientId: cognitoUserPool.getClientId()
        });
        try {
          const idTokenPayload = await idTokenVerifier.verify(userSession.getIdToken().getJwtToken());
          dispatch({
            type: 'SIGN_IN_SUCCESS',
            payload: {
              userGroups: idTokenPayload['cognito:groups'] as Array<UserGroup>,
              username: idTokenPayload['cognito:username'],
              apiToken: userSession.getIdToken().getJwtToken()
            }
          });
          resolve();
        } catch (error) {
          reject('Token invalid');
          dispatch({type: 'SIGN_IN_FAIL'});
        }
      },
      onFailure: (error: any) => {
        const errorMsg = error.message || JSON.stringify(error);
        dispatch({type: 'PASSWORD_CHANGE_FAIL'});
        reject(errorMsg);
      }
    });
  });
};

export const resetPassword = async (email: string, code: string, password: string): Promise<void> => {
  const getUser = () => {
    return new CognitoUser({
      Username: email,
      Pool: cognitoUserPool
    });
  }

  return new Promise<void>((resolve, reject) => {
    getUser().confirmPassword(code, password, {
      onSuccess: () => {
        resolve();
      },
      onFailure: err => {
        reject(err)
      }
    });
  })
};

export const sendforgotPasswordCode = async (email: string): Promise<void> => {
  const getUser = () => {
    return new CognitoUser({
      Username: email,
      Pool: cognitoUserPool
    });
  }

  return new Promise<void>((resolve, reject) => {
    getUser().forgotPassword({
      onSuccess: () => {
        resolve();
      },
      onFailure: err => {
        reject(err);
      },
      inputVerificationCode: () => {
        resolve();
      }
    })
  });
};

export const resendCode = async (email: string): Promise<void> => {
  const getUser = () => {
    return new CognitoUser({
      Username: email,
      Pool: cognitoUserPool
    });
  }

  getUser().resendConfirmationCode(function (err, result) {
    if (err) {
      return;
    }
  });
};