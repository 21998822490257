import { Formik, FormikHelpers, FormikProps } from 'formik';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import NxInput from '../../shared/nxInput/NxInput';
import NxButton from '../../shared/nxButton/NxButton';
import NxGenericTextField from '../../shared/nxGenericTextField/NxGenericTextField';
import NxDatepicker from '../../shared/nxDatepicker/NxDatepicker';
import dayjs from 'dayjs';
import { useAlert } from '../../../shared/context/alert';
import { REPORTS_DATE_FORMAT, TRANSACTION_DATE_FORMAT } from '../../../shared/constants/format-templates';
import { TransactionSearchQuery, TransactionGroup, TransactionStatus } from '../Transactions.model';
import styles from './TransactionSearchForm.module.scss';

type TransactionSearchFormProps = {
  isLoading: boolean;
  onSubmit: (newParams: TransactionSearchQuery) => Promise<void>;
  formValues: TransactionSearchQuery
};

export default function TransactionSearchForm({ isLoading, onSubmit, formValues }: TransactionSearchFormProps): React.ReactElement {

  const { t } = useTranslation();
  const { showAlert } = useAlert();

  const submitPropertyChange = async (
    values: TransactionSearchQuery,
    actions: FormikHelpers<TransactionSearchQuery>): Promise<void> => {
    if (new Date(values.dateTo as string) < new Date(values.dateFrom as string)) {
      actions.setFieldError('dateFrom', '')
      actions.setFieldError('dateTo', '')
      showAlert({ type: 'error', message: `Invalid date. Date To field should not be earlier than Date From field` });
      throw new Error();
    }
    onSubmit({
      ...values,
      ...(values.mobileNo ? { mobileNo: t('SHARED.FORM.MOBILE_NUMBER_PREFIX.PHP') + values.mobileNo } : {})
    }).catch(error => actions.setFieldError('generalError', error));
  };

  const TransactionSearchForm = ({
    values,
    handleSubmit,
    handleChange,
    isSubmitting,
    errors,
    setFieldValue
  }: FormikProps<TransactionSearchQuery>): ReactElement => (
    <form onSubmit={handleSubmit} className={styles.form}>
      <NxInput
        name='username'
        className={styles.input}
        disabled={isSubmitting || isLoading}
        label={t('TRANSACTIONS.DETAILS.BIZAP_USERNAME')}
        value={values.username}
        onChange={handleChange} />
      <NxInput
        name='accountNumber'
        className={styles.input}
        disabled={isSubmitting || isLoading}
        label={t('SHARED.ACCOUNT_NUMBER')}
        value={values.accountNumber}
        onChange={handleChange} />
      <NxInput
        name='mobileNo'
        className={styles.input}
        disabled={isSubmitting || isLoading}
        label={t('SHARED.PHONE_NUMBER')}
        value={values.mobileNo}
        onChange={handleChange}
        startAdornment={<InputAdornment position='start'>{t('SHARED.FORM.MOBILE_NUMBER_PREFIX.PHP')}</InputAdornment>} />
      <NxInput
        name='referenceNumber'
        className={styles.input}
        disabled={isSubmitting || isLoading}
        label={t('TRANSACTIONS.TABLE.COLUMN_HEADERS.TRANSACTION_ID')}
        value={values.referenceNumber}
        onChange={handleChange} />
      <NxButton
        type='submit'
        className={styles.button}
        disabled={isLoading}
        loaded={!isSubmitting && !isLoading}
      >
        {t('SHARED.SEARCH')}
      </NxButton>
      <NxGenericTextField
        name='transactionGroup'
        className={styles.select}
        disabled={isSubmitting || isLoading}
        label={t('TRANSACTIONS.TABLE.COLUMN_HEADERS.TYPE')}
        value={values.transactionGroup ?? ''}
        onChange={handleChange}
        select
      >
        <MenuItem value="">{t('SHARED.EMPTY')}</MenuItem>
        {Object.keys(TransactionGroup).map((key) => (
          <MenuItem key={key} value={key}>
            {t(`TRANSACTIONS.TRANS_GROUPS.${key}`)}
          </MenuItem>
        ))}
      </NxGenericTextField>
      <NxGenericTextField
        name='transactionStatus'
        className={styles.select}
        disabled={isSubmitting || isLoading}
        label={t('TRANSACTIONS.TABLE.COLUMN_HEADERS.STATUS')}
        value={values.transactionStatus ?? ''}
        onChange={handleChange}
        select
      >
        <MenuItem value="">{t('SHARED.EMPTY')}</MenuItem>
        {Object.keys(TransactionStatus).map((key) => (
          <MenuItem key={key} value={key}>
            {t(`TRANSACTIONS.TRANS_STATUSES.${key}`)}
          </MenuItem>
        ))}
      </NxGenericTextField>
      <NxDatepicker
        disableFuture={true}
        name='dateFrom'
        label={t('SHARED.DATE_FROM')}
        inputFormat={TRANSACTION_DATE_FORMAT}
        value={values.dateFrom ?? ''}
        error={errors.dateFrom}
        onChange={(value) => setFieldValue(
          'dateFrom',
          dayjs(value).isValid() ? dayjs(value).format(REPORTS_DATE_FORMAT) : '',
          true
        )}
        disabled={isSubmitting || isLoading} />
      <NxDatepicker
        minDate={values.dateFrom && dayjs(values.dateFrom)}
        disableFuture={true}
        name='dateTo'
        label={t('SHARED.DATE_TO')}
        inputFormat={TRANSACTION_DATE_FORMAT}
        value={values.dateTo ?? ''}
        error={errors.dateTo}
        onChange={(value) => setFieldValue(
          'dateTo',
          dayjs(value).isValid() ? dayjs(value).format(REPORTS_DATE_FORMAT) : '',
          true
        )}
        disabled={isSubmitting || isLoading} />
    </form>
  );

  const formInitValues = {
    username: formValues?.username ?? '',
    accountNumber: formValues?.accountNumber ?? '',
    mobileNo: formValues?.mobileNo ? formValues.mobileNo.slice(3, 13) : '',
    referenceNumber: formValues?.referenceNumber ?? '',
    transactionGroup: formValues?.transactionGroup ?? '',
    transactionStatus: formValues?.transactionStatus ?? '',
    dateFrom: formValues?.dateFrom ?? '',
    dateTo: formValues?.dateTo ?? '',
    generalError: ''
  };

  return (
    <Formik<TransactionSearchQuery>
      initialValues={formInitValues}
      onSubmit={submitPropertyChange}
    >
      {TransactionSearchForm}
    </Formik>
  );
}
