import {GridCellParams, GridColDef} from '@mui/x-data-grid';
import {TFunction} from 'i18next';
import {createColDefWithDefaultConfiguration} from '../../../../shared/utils/dataGridDefaultColumnDefValues';
import {getFormattedDateOrNullReplacement} from '../../../../shared/utils/valueFormatter';
import {BusinessOwnerAuthData} from '../BusinessOwnerDetails.model';
import EnableIndicator from './enable-indicator/EnableIndicator';
import LockUnlockButton from './lock-unlock-button/LockUnlockButton';
import {AuthDataTableRow} from './MobileAppAccessTable';
import styles from '../BusinessOwnersDetails.module.scss'

export const LOGIN_DATE_TIME_FORMAT = 'MM/DD/YYYY hh:mm:ss';

export const mobileAppAccessTableColumnDefinitions =
  (t: TFunction, isDeletedAccount: boolean): Array<GridColDef<BusinessOwnerAuthData>> => createColDefWithDefaultConfiguration(
    [
      {
        field: 'authDataId',
        headerName: t('BUSINESS_OWNERS.DETAILS.MOBILE_APP_ACCESS.ID') ?? ''
      },
      {
        field: 'enabled',
        headerName: t('BUSINESS_OWNERS.DETAILS.MOBILE_APP_ACCESS.ENABLED') ?? '',
        cellClassName: isDeletedAccount ? styles.deletedAccount : '',
        renderCell: params => <EnableIndicator isEnabled={params.formattedValue} />
      },
      {
        field: 'lastSuccessfulLogin',
        headerName: t('BUSINESS_OWNERS.DETAILS.MOBILE_APP_ACCESS.LAST_SUCC_LOGIN') ?? '',
        cellClassName: isDeletedAccount ? styles.deletedAccount : '',
        valueFormatter: params => getFormattedDateOrNullReplacement(params.value, LOGIN_DATE_TIME_FORMAT)
      },
      {
        field: 'lastFailedLogin',
        headerName: t('BUSINESS_OWNERS.DETAILS.MOBILE_APP_ACCESS.LAST_FAIL_LOGIN') ?? '',
        cellClassName: isDeletedAccount ? styles.deletedAccount : '',
        valueFormatter: params => getFormattedDateOrNullReplacement(params.value, LOGIN_DATE_TIME_FORMAT)
      },
      {
        field: 'enabled.sid.group',
        headerName: '',
        renderCell: (params: GridCellParams<AuthDataTableRow>) =>
          <LockUnlockButton isLocked={!params.row.enabled}
                            onClick={() => params.row.changeLockStatus(params.row)}
                            loaded={!params.row.isLoading}
                            isDeletedAccount={isDeletedAccount} />
      }
    ]
  );
