import {isNil} from 'lodash/fp';
import React, {ChangeEvent, ReactElement, useCallback, useRef} from 'react';
import {useAuth} from '../../../shared/auth/context';
import {Permission} from '../../../shared/auth/model';
import {hasRequiredPermission} from '../../../shared/auth/utils';
import {Nil} from '../model/SharedTypes';
import NxGenericTextField from '../nxGenericTextField/NxGenericTextField';

export interface NxInputProps {
  className?: string;
  disabled?: boolean;
  error?: string;
  id?: string;
  label?: React.ReactNode;
  name?: string;
  onBlur?: (e: ChangeEvent) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: React.KeyboardEventHandler;
  onFocus?: (e: ChangeEvent) => void;
  positionAbsoluteError?: boolean;
  required?: boolean;
  startAdornment?: React.ReactNode;
  type?: string;
  maxLength?: number;
  value?: string | null;
  permission?: Permission;
  autoComplete?: string;
  onPaste?: any;
}

const calculateValue = (value: string | Nil, lastValue: string): string => {
  if (isNil(value)) {
    return value === null ? lastValue : '';
  }

  return value;
};

/**
 * NxInput value will be cut according to maxLength if settled.
 */
const NxInput = (
  {
    className,
    disabled = false,
    error,
    id,
    label,
    name,
    onBlur,
    onChange,
    onKeyDown,
    onFocus,
    positionAbsoluteError,
    required,
    startAdornment,
    type,
    maxLength,
    value,
    permission,
    autoComplete,
    onPaste
  }: NxInputProps
): ReactElement => {

  const {state} = useAuth();
  const lastValue = useRef('');
  const finalValue = calculateValue(value, lastValue.current);
  lastValue.current = finalValue;

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
    lastValue.current = event.target.value;
    if (!onChange) {
      return;
    }

    onChange(event);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, onChange]);

  return <NxGenericTextField className={className}
                             disabled={disabled || (permission && !hasRequiredPermission(state.userGroups ?? [],
                               permission))}
                             error={error}
                             id={id}
                             onBlur={onBlur}
                             onChange={handleChange}
                             onKeyDown={onKeyDown}
                             onFocus={onFocus}
                             positionAbsoluteError={positionAbsoluteError}
                             required={required}
                             label={label}
                             name={name}
                             startAdornment={startAdornment}
                             type={type}
                             inputProps={{
                               maxLength,
                               onPaste
                             }}
                             autoComplete={autoComplete}
                             value={finalValue} />;
};

export default NxInput;
