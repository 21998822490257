import {Dayjs} from 'dayjs';

export type BusinessOwnerDetails = {
  username: string;
  firstName: string;
  middleName: string;
  lastName: string;
  customerNumber: number;
  mobileNumber: string;
  status: BusinessOwnerStatus;
  businessName: string;
  accountList: BusinessOwnerAccount[];
  authData: BusinessOwnerAuthData[];
  statusChangeRemarks?: string;
};

export enum BusinessOwnerStatus {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED',
  CLOSED = 'CLOSED'
}

export type BusinessOwnerAuthData = {
  authDataId: number;
  sid: Sid,
  enabled: boolean,
  lastSuccessfulLogin: Dayjs;
  lastFailedLogin: Dayjs;
};

export type BusinessOwnerAccount = {
  number: string;
  productName: string;
  productType: string;
  status: BusinessOwnerAccountStatus,
  category: BusinessOwnerAccountCategory,
  customerNumber: number;
  currency: string;
  balance: number;
  currentBalance: number;
  name: string;
};

export enum BusinessOwnerAccountStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  SUSPENDED = "SUSPENDED",
  CLOSED = "CLOSED"
}

export enum BusinessOwnerAccountCategory {
  BUSINESS = 'BUSINESS',
  PERSONAL = 'PERSONAL',
  CASH_RECEIPT = 'CASH_RECEIPT',
  DISBURSEMENT = 'DISBURSEMENT',
  PETTY_CASH = 'PETTY_CASH',
  GOAL = 'GOAL',
  SETTLEMENT = 'SETTLEMENT'
}

export type Sid = {
  group: SidGroup;
  channel: SidChannel;
  login: string;
};

export enum SidChannel {
  DEV = 'DEV',
  WEB = 'WEB',
  MOBILE = 'MOBILE'
}

export enum SidGroup {
  ADMIN = 'ADMIN',
  CUSTOMER = 'CUSTOMER'
}
