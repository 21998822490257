import {Divider} from '@mui/material';
import {GridRowParams} from '@mui/x-data-grid';
import {ReactElement, useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useLocation} from 'react-router-dom';
import useGet from '../../shared/hooks/use-get.hook';
import { PagedResponse, PageInfo } from '../../shared/model/Page.model';
import {TransHelper} from '../../shared/utils/trans-helper';
import ContentBox from '../shared/content-box/ContentBox';
import NxLoader from '../shared/nxLoader/NxLoader';
import SystemInfoText from '../shared/system-info-text/SystemInfoText';
import DataTable from '../shared/table/Table';
import {Customer} from './BusinessOwners.model';
import {businessOwnersTableColumnDefinitions} from './businessOwnersTableColumnDefinitions';
import SearchBar, {SearchValues} from './search-bar/SearchBar';
import styles from './BusinessOwners.module.scss';

export default function BusinessOwners(): ReactElement {

  const PrefixedTrans = TransHelper.getPrefixedTrans('BUSINESS_OWNERS');
  const SharedPrefixedTrans = TransHelper.getPrefixedTrans('SHARED');
  const getCustomersPagedResponse = useGet<PagedResponse<Customer>>(`/admin/customers`);
  const [customers, setCustomers] = useState<Customer[] | undefined>();
  const navigate = useNavigate();
  const [pageInfoResults, setPageInfoResults] = useState<PageInfo>({pageNo: 0, resultCount: 0, totalCount: 0});
  const [searchQueryParams, setSearchQueryParams] = useState<[string, string][]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {t} = useTranslation();
  const location = useLocation();

  useEffect(() => {
    if (location.state) {
      handleSearch(location.state)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSearch = (values: SearchValues | [string, string][]): void => {
    setIsLoading(true);
    const searchParamArray = Array.isArray(values) ? values : Object.entries(values).filter(([_, value]) => !!value);
    const urlParams = new URLSearchParams(searchParamArray);
    getCustomersPagedResponse(urlParams)
      .then((pagedResponse) => {
        setCustomers(pagedResponse.result ?? []);
        setPageInfoResults({
          pageNo: pagedResponse.pageNo,
          totalCount: pagedResponse.totalCount,
          resultCount: pagedResponse.resultCount
        });
        setSearchQueryParams(searchParamArray);
      })
      .catch(() => setCustomers([]))
      .finally(() => setIsLoading(false));
  };

  return (
    <ContentBox header={<PrefixedTrans>BOX_TITLE</PrefixedTrans>}>
      <div className={styles.container}>
        <SearchBar onSearch={handleSearch} isLoading={isLoading} formValues={location.state} />
        <Divider className={styles.divider} flexItem />
        <div className={styles.dataContainer}>
          {
            isLoading ?
              <NxLoader /> :
              (
                !customers ?
                  (<div className={styles.emptyStateText}>
                    <PrefixedTrans>TABLE.NO_RESULT_MESSAGE</PrefixedTrans>
                  </div>) : !customers?.length ?
                    (
                      <div className={styles.emptyStateText}>
                        <div className={styles.emptyResultText}>{t('SHARED.NO_RESULTS')}</div>
                        {t('SHARED.MAKE_SURE_ENTER_CORRECT_DATA')}
                      </div>
                    ) :
                    (
                      <div className={styles.tableContainer}>
                        <div className={styles.resultsText}>
                          <SharedPrefixedTrans components={[<span />]}
                            values={{count: pageInfoResults?.totalCount}}>
                            RESULTS
                          </SharedPrefixedTrans>
                        </div>
                        <DataTable
                          loading={isLoading}
                          className={styles.table}
                          getRowId={(row: {rowNum: any;}) => row.rowNum}
                          pageInfo={{
                            pageNo: pageInfoResults?.pageNo ?? 0,
                            resultCount: pageInfoResults?.resultCount ?? 0,
                            totalCount: pageInfoResults?.totalCount ?? 0
                          }}
                          rows={customers}
                          onRowClick={(rowParams: GridRowParams): void => {
                            if (rowParams.row.deleteId) {
                              navigate(`${rowParams.row.customerNumber}`, { state: { ...searchQueryParams, deleteId: rowParams.row.deleteId, deletedOn: rowParams.row.deletedOn, deletedBy: rowParams.row.deletedBy } })
                            } else {
                              navigate(`${rowParams.row.customerNumber}`, { state: searchQueryParams })
                            }
                          }}
                          pageSize={20}
                          columns={businessOwnersTableColumnDefinitions(t)}
                          noRowsMessage={t('BUSINESS_OWNERS.TABLE.NO_RESULT_MESSAGE')}
                          tableFooter={() => (pageInfoResults?.totalCount > 20) ? (<SystemInfoText>
                              <PrefixedTrans>TABLE.NARROW_SEARCH_MESSAGE</PrefixedTrans>
                            </SystemInfoText>) : null
                          }/>
                      </div>
                    )
              )
          }
        </div>
      </div>
    </ContentBox>
  );
}
