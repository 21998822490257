import { Divider } from '@mui/material';
import { GridRowParams } from '@mui/x-data-grid';
import clsx from 'clsx';
import { ReactElement, useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import useSingleQuery from '../../shared/hooks/use-single-query.hook';
import { PagedResponse } from '../../shared/model/Page.model';
import { TransHelper } from '../../shared/utils/trans-helper';
import ContentBox from '../shared/content-box/ContentBox';
import NxLoader from '../shared/nxLoader/NxLoader';
import DataTable from '../shared/table/Table';
import TransactionSearchForm from './transaction-search-form/TransactionSearchForm';
import { TransactionExtension, TransactionSearchQuery } from './Transactions.model';
import styles from './Transactions.module.scss';
import transactionsTableColumnDefinitions from './transactionsTableColumnDefinitions';

export default function Transactions(): ReactElement {

  const location = useLocation();
  const navigate = useNavigate();

  const PrefixedTrans = TransHelper.getPrefixedTrans('TRANSACTIONS');

  const mapFunction = (response: any) => {
    return {
      ...response,
      result: response.result?.map((item: any) => {
        return {
          username: item.senderAlias,
          accountNumber: item.senderAccount?.accountNumber,
          mobileNumber: item.sender?.mobileNumber,
          referenceNumber: item.transaction?.referenceNumber,
          transactionGroup: item.transaction?.transactionGroup,
          registeredOn: item.transaction?.registeredOn,
          amount: item.transaction?.amount,
          transactionStatus: item.transaction?.status
        };
      })
    };
  };

  const { data, isLoading, fetch } = useSingleQuery<PagedResponse<TransactionExtension>>(
    `/admin/transactions/extensions`,
    mapFunction,
    false
  );

  const { t } = useTranslation();

  const [pageSize, setPageSize] = useState<number | unknown>(10);
  const [latestParams, setLatestParams] = useState<TransactionSearchQuery | undefined>();

  const submitTransactionsSearch = useCallback(async (params: TransactionSearchQuery) => {
    try {
      delete latestParams?.pageNo; // refresh pageNo query param
      const newParams = { pageSize: String(pageSize), ...latestParams, ...params };
      Object.keys(newParams).forEach((key) => {
        if (newParams[key as keyof TransactionSearchQuery] === '') {
          delete newParams[key as keyof TransactionSearchQuery];
        }
      });
      fetch(new URLSearchParams(newParams));
      setLatestParams(newParams);
    } catch (error) {
      return Promise.reject(error);
    }
  }, [fetch, latestParams, pageSize]);


  useEffect(() => {
    if (location.state) {
      submitTransactionsSearch(location.state)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handlePageChange = useCallback((pageNo: number) => {
    submitTransactionsSearch({ pageNo: String(pageNo) });
  }, [submitTransactionsSearch]);

  const handlePageSizeChange = useCallback((pageSize: number | unknown) => {
    if (location?.state?.pageSize) {
      location.state.pageSize = null;
    }
    setPageSize(pageSize);
    submitTransactionsSearch({ pageSize: String(pageSize) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitTransactionsSearch]);

  return (
    <ContentBox header={<PrefixedTrans>BOX_TITLE</PrefixedTrans>}>
      <div className={styles.container}>
        <TransactionSearchForm isLoading={isLoading} onSubmit={submitTransactionsSearch} formValues={location.state} />
        <Divider />
        <div className={styles.dataContainer}>
          {isLoading && (
            <div className={clsx(styles.overlay, styles.loaderContainer)}>
              <NxLoader />
            </div>
          )}
          {!data && (
            <div className={clsx(styles.overlay, styles.emptyResultsText)}>
              <PrefixedTrans>TABLE.NO_RESULT_MESSAGE</PrefixedTrans>
            </div>
          )}
          <div className={styles.results}>
            <PrefixedTrans>RESULTS</PrefixedTrans>
            <div className={styles.totalCount}>({data?.totalCount})</div>
          </div>
          <DataTable
            loading={isLoading}
            getRowId={row => row.referenceNumber}
            pageInfo={{
              pageNo: data?.pageNo ?? 0,
              resultCount: data?.resultCount ?? 0,
              totalCount: data?.totalCount ?? 0
            }}
            pageSize={Number(location?.state?.pageSize) || Number(pageSize)}
            rows={data?.result ?? []}
            onRowClick={(rowParams: GridRowParams): void => navigate(`${rowParams.id}`, { state: latestParams })}
            onPageNumberChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            columns={transactionsTableColumnDefinitions(t)}
            noRowsMessage={t('TRANSACTIONS.TABLE.NO_RESULT_MESSAGE')}
            hideFooterPagination={false}
            hideFooter={false}
          />
        </div>
      </div>
    </ContentBox>
  );
}
