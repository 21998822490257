export type TransactionSearchQuery = {
  pageNo?: string;
  pageSize?: string;
  username?: string;
  accountNumber?: string;
  mobileNo?: string;
  referenceNumber?: string;
  transactionGroup?: string;
  transactionStatus?: string;
  dateFrom?: string;
  dateTo?: string;
};

export type TransactionExtension = {
  sender: Sender;
  senderAccount: SenderAccount;
  transaction: Transaction;
  senderAlias: string;
};

export type Transaction = {
  accountNumber: string;
  amount: number;
  attributes: Record<string, string>;
  customerNumber: string;
  expenseCategoryId: number;
  finacleId: string;
  id: number;
  partnerSessionId: string;
  referenceNumber: string;
  registeredOn: string;
  remarks: string;
  status: TransactionStatus;
  transactionGroup: TransactionGroup;
  transactionName: string;
  transactionType: TransactionType;
  responseApiDetails: ResponseApiDetails;
};

interface ResponseApiDetails {
  [key: string]: string;
}

export enum TransactionStatus {
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
  UNCONFIRMED = "UNCONFIRMED",
}

export enum TransactionGroup {
  RCBC_GROUP_TRANSFER = "RCBC_GROUP_TRANSFER",
  INSTAPAY_TRANSFER = "INSTAPAY_TRANSFER",
  PESONET_TRANSFER = "PESONET_TRANSFER",
  BILLS_PAYMENT = "BILLS_PAYMENT",
  UNCATEGORIZED = "UNCATEGORIZED",
}

export enum TransactionType {
  DEBIT = "DEBIT",
  CREDIT = "CREDIT",
  UNKNOWN = "UNKNOWN",
  ANY = "ANY",
}

export type SenderAccount = {
  accountCategory: SenderAccountCategory;
  accountNumber: string;
  businessName: string;
  customerNumber: number;
};

export enum SenderAccountCategory {
  BUSINESS = "BUSINESS",
  PERSONAL = "PERSONAL",
  CASH_RECEIPT = "CASH_RECEIPT",
  DISBURSEMENT = "DISBURSEMENT",
  PETTY_CASH = "PETTY_CASH",
  GOAL = "GOAL",
  SETTLEMENT = "SETTLEMENT",
}

export type Sender = {
  customerNumber: number;
  customerType: CustomerType;
  email: string;
  firstName: string;
  lastName: string;
  middleName: string;
  mobileNumber: string;
  privacyPolicy: true;
  status: CustomerStatus;
  termsAndConditions: string;
};

export enum CustomerType {
  RETAIL = "RETAIL",
  CORPORATE = "CORPORATE",
}

export enum CustomerStatus {
  INACTIVE = "INACTIVE",
  ACTIVE = "ACTIVE",
  SUSPENDED = "SUSPENDED",
  CLOSED = "CLOSED",
}
