import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useState } from 'react';
import NxButton, { NxButtonVariant } from '../../../shared/nxButton/NxButton';
import NxGenericTextField from '../../../shared/nxGenericTextField/NxGenericTextField';
import styles from './BlockDialog.module.scss';
import clsx from 'clsx';
import { ReactComponent as AlertIcon } from '../../../../assets/icons/icon-alert.svg';

export default function BlockDialog({ open, onClose, onConfirm, username }: any) {

    const [characterCount, setCharacterCount] = useState(0);
    const maxCharacters = 250;
    const [remarks, setRemarks] = useState('');
    const [showError, setShowError] = useState(false);

    const save = async (): Promise<void> => {
        if (!remarks) {
            setShowError(true);
            return;
        }
        onConfirm(remarks);
        setShowError(false);
        setRemarks('');
        setCharacterCount(0);
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle textAlign={'center'} fontWeight={'bold'}>
                {"Block Boz Profile?"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText marginBottom={'1.5rem'} textAlign={'center'}>
                    {`Please indicate detailed explanation for blocking `}
                    <span style={{ fontWeight: 'bold', fontSize: '14px' }}>{username}</span>
                    {` profile`}
                </DialogContentText>

                <div className={styles.formRow}>
                    <NxGenericTextField error={showError ? 'This field is required.' : undefined} value={remarks} name="message" label={"Remarks"} shrinkLabel multiline sx={{ color: "white", width: "70vw" }} fullWidth={true} inputProps={{ maxLength: 250 }} className={styles.messageInput} onChange={(event: any) => {
                        setShowError(false)
                        setCharacterCount(event.target.value.length)
                        setRemarks(event.target.value)
                    }} />
                    <span className={styles.characterCount} style={{
                        color: characterCount === maxCharacters ? 'red' : '#6F85A5'
                    }}>
                        {characterCount}/{maxCharacters}
                    </span>
                </div>

                <div className={styles.headerDeleted}><AlertIcon/>This action will pause all scheduled transfer.</div>
            </DialogContent>
            <DialogActions>
                <div className={styles.buttonSet}>
                    <NxButton className={clsx(styles.button, styles.proceed)}
                        onClick={save}
                    >
                        {"Block Boz Profile"}
                    </NxButton>
                    <NxButton className={styles.button}
                        onClick={onClose}
                        variant={NxButtonVariant.TEXT}
                    >
                        {"No, Go back"}
                    </NxButton>

                </div>
            </DialogActions>
        </Dialog>
    );
}
