import clsx from 'clsx';
import React from 'react';
import styles from './EmptyTable.module.scss';

const EmptyTable = ({position, message}: {position?: string, message: string}): React.ReactElement => {
  const positionClass = position === 'relative';
  return (
    <div className={clsx(styles.emptyTableOverlay, {[styles.positionRelative]: positionClass})}>
      {message}
    </div>
  );
};

export default EmptyTable;
