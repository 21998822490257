import {ReactElement} from 'react';
import {Navigate, RouteProps} from 'react-router-dom';
import {useAuth} from '../auth/context';
import {Permission} from '../auth/model';
import {hasRequiredPermission} from '../auth/utils';

type GuardedRouteProps = RouteProps & {
  permission: Permission;
  children: ReactElement;
}

export default function GuardedRoute(props: GuardedRouteProps): ReactElement {

  const {state} = useAuth();

  return hasRequiredPermission(state.userGroups ?? [], props.permission) ?
    props.children :
    <Navigate to='/' replace />;
}
