import {GridColDef} from '@mui/x-data-grid';
import clsx from 'clsx';
import dayJs from 'dayjs';
import {TFunction} from 'i18next';
import qs from 'qs';
import {ReactElement, useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import { NavLink, useParams, useLocation } from 'react-router-dom';
import {ReactComponent as CalendarIcon} from '../../../assets/icons/icon-calendar.svg';
import {ReactComponent as ClockIcon} from '../../../assets/icons/icon-clock.svg';
import {Permission} from '../../../shared/auth/model';
import {TRANSACTION_DATE_FORMAT, TRANSACTION_TIME_FORMAT} from '../../../shared/constants/format-templates';
import {useAlert} from '../../../shared/context/alert';
import usePost from '../../../shared/hooks/use-post.hook';
import useSingleQuery from '../../../shared/hooks/use-single-query.hook';
import {PagedResponse} from '../../../shared/model/Page.model';
import {formatCurrency} from '../../../shared/utils/currency-format';
import {createColDefWithDefaultConfiguration} from '../../../shared/utils/dataGridDefaultColumnDefValues';
import {TransHelper} from '../../../shared/utils/trans-helper';
import ContentBox from '../../shared/content-box/ContentBox';
import DetailBox from '../../shared/detail-box/DetailsBox';
import NxButton, {NxButtonVariant} from '../../shared/nxButton/NxButton';
import {SimpleDetailsDataTable} from '../../shared/table/Table';
import {TransactionExtension, TransactionStatus} from '../Transactions.model';
import StatusChangeDialog from './status-change-dialog/StatusChangeDialog';
import styles from './TransactionDetails.module.scss';

type ChangeStatusPayload = {
  id: number;
  status: TransactionStatus;
}

export default function TransactionDetails(): ReactElement {

  const {referenceNumber} = useParams();
  const location = useLocation();

  const {t} = useTranslation();

  const PrefixedTrans = TransHelper.getPrefixedTrans('TRANSACTIONS.DETAILS');
  const TransactionTrans = TransHelper.getPrefixedTrans('TRANSACTIONS');
  const SharedTrans = TransHelper.getPrefixedTrans('SHARED');

  const {showAlert} = useAlert();
  const [statusChangeDialogShown, setStatusChangeDialogShown] = useState(false);
  const changeStatusRequest = usePost<void, ChangeStatusPayload>('/admin/transactions/update-status');

  const mapFunction = (res: PagedResponse<TransactionExtension>): TransactionExtension => {
    if (!res.result?.length) {
      showAlert({type: 'warning', message: t('TRANSACTIONS.DETAILS.MESSAGE_CODE.TRANSACTION_NOT_FOUND')});
    }
    return res.result[0];
  };
  const {data, fetch} = useSingleQuery<TransactionExtension, PagedResponse<TransactionExtension>>(
    `/admin/transactions/extensions?${qs.stringify({referenceNumber})}`,
    mapFunction
  );
  const {transaction, sender, senderAccount, senderAlias} = data ?? {} as TransactionExtension;

  const detailsArray = [];
  const responseArray = [];

  if (transaction?.responseApiDetails && Object.keys(transaction?.responseApiDetails).length) {
    for (const key in transaction.responseApiDetails) {
      if (key.startsWith('DETAILS')) {
        detailsArray.push({ key, value: transaction.responseApiDetails[key] });
      } else if (key.startsWith('RESPONSE')) {
        responseArray.push({ key, value: transaction.responseApiDetails[key] });
      }
    }
  }

  const BackButton = useCallback(() => (
    <NavLink to='/transactions' state={location.state} className={styles.backButton}>
      <NxButton variant={NxButtonVariant.BACK}>{t('SHARED.BACK')}</NxButton>
    </NavLink>
  ), [t, location.state]);

  const Header = useCallback(() => (
    <div className={styles.header}>
      <PrefixedTrans>BOX_TITLE</PrefixedTrans>
      {transaction && (
        <div className={styles.transactionId}>
          {t('SHARED.ID')}: {transaction?.id}
        </div>
      )}
    </div>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [t, transaction]);

  const {date, time}: {date: string, time: string;} = useMemo(() => {
    const [date, time] = dayJs(transaction?.registeredOn)
      .format(`${TRANSACTION_DATE_FORMAT},${TRANSACTION_TIME_FORMAT}`)
      .split(',');

    return {date, time};
  }, [transaction]);

  type DetailItemProps = {
    label: ReactElement | string;
    children: ReactElement | string;
  }

  const DetailItem = ({label, children}: DetailItemProps): ReactElement => {
    return (
      <div className={styles.detailContainer}>
        <div className={styles.detailLabel}>{label}</div>
        <div className={styles.detailValue}>{children}</div>
      </div>
    );
  };

  const attributesTableColumnDefinitions = (t: TFunction): Array<GridColDef> => createColDefWithDefaultConfiguration(
    [
      {
        field: 'key',
        headerName: t('TRANSACTIONS.DETAILS.ATTRIBUTE_KEY') ?? ''
      },
      {
        field: 'value',
        headerName: t('TRANSACTIONS.DETAILS.ATTRIBUTE_VALUE') ?? ''
      }
    ]
  );

  const changeStatus = async (transactionStatus: TransactionStatus): Promise<void> => {
    try {
      await changeStatusRequest({id: data?.transaction.id ?? 0, status: transactionStatus});
    } catch {
      showAlert({type: 'error', message: t('TRANSACTIONS.DETAILS.MESSAGE_CODE.FAILED_TO_CHANGE_STATUS')});
      throw Error('Failed to request API for status change');
    }
    await fetch();
  };

  const isTransactionNonTerminal = data?.transaction.status === TransactionStatus.UNCONFIRMED;

  return (
    <ContentBox header={<Header />}
                backButton={<BackButton />}>
      <StatusChangeDialog open={statusChangeDialogShown}
                          onSave={changeStatus}
                          onClose={() => setStatusChangeDialogShown(false)} />
      {transaction && (
        <div className={styles.container}>
          <DetailBox>
            <div className={styles.amount}>
              <SharedTrans values={{amount: formatCurrency(transaction?.amount, {style: undefined})}}>
                PHP
              </SharedTrans>
            </div>
            <div className={clsx(styles.details, styles.details_transaction)}>
              <DetailItem label={t('SHARED.DATE') + ":"}>
                <div className={styles.textWithIcon}>
                  <CalendarIcon />{date}
                </div>
              </DetailItem>
              <DetailItem label={<PrefixedTrans>TRANS_GROUP</PrefixedTrans>}>
                <TransactionTrans>{`TRANS_GROUPS.${transaction?.transactionGroup}`}</TransactionTrans>
              </DetailItem>
              <DetailItem label={t('SHARED.TIME') + ":"}>
                <div className={styles.textWithIcon}>
                  <ClockIcon />{time}
                </div>
              </DetailItem>
              <DetailItem label={t('SHARED.STATUS') + ":"}>
                <>
                  <div className={clsx(styles.status, styles[transaction?.status?.toLowerCase()])}>
                    <TransactionTrans>{`TRANS_STATUSES.${transaction?.status}`}</TransactionTrans>
                  </div>
                  {
                    isTransactionNonTerminal &&
                    <NxButton onClick={() => setStatusChangeDialogShown(true)}
                              variant={NxButtonVariant.TEXT}
                              permission={Permission.INSTAPAY_WRITE}
                              className={styles.changeStatusButton}>
                      <PrefixedTrans>CHANGE</PrefixedTrans>
                    </NxButton>
                  }
                </>
              </DetailItem>
              <DetailItem label={<PrefixedTrans>TRANS_NAME</PrefixedTrans>}>
                {transaction?.transactionName ?? '-'}
              </DetailItem>
              <DetailItem label={<PrefixedTrans>TRANS_TYPE</PrefixedTrans>}>
                <TransactionTrans>{`TRANS_TYPES.${transaction?.transactionType}`}</TransactionTrans>
              </DetailItem>
              <DetailItem label={<PrefixedTrans>FINACLE_ID</PrefixedTrans>}>
                {transaction?.finacleId ?? '-'}
              </DetailItem>
              <DetailItem label={<PrefixedTrans>SESSION_ID</PrefixedTrans>}>
                {transaction?.partnerSessionId ?? '-'}
              </DetailItem>
              <DetailItem label={<PrefixedTrans>REFERENCE_NUMBER</PrefixedTrans>}>
                {transaction?.referenceNumber ?? '-'}
              </DetailItem>
              <DetailItem label={<PrefixedTrans>REMARKS</PrefixedTrans>}>
                {transaction?.remarks ?? '-'}
              </DetailItem>
            </div>
          </DetailBox>
          <div className={styles.fromTo}>
            <DetailBox>
              <div className={styles.sectionHeader}>{t('SHARED.FROM')}</div>
              <div className={styles.details}>
                <DetailItem label={t('TRANSACTIONS.DETAILS.BIZAP_USERNAME') + ":"}>
                  {senderAlias ?? '-'}
                </DetailItem>
                <DetailItem label={<PrefixedTrans>ACCOUNT_NUMBER</PrefixedTrans>}>
                  {senderAccount?.accountNumber ?? '-'}
                </DetailItem>
                <DetailItem label={t('SHARED.PHONE_NUMBER') + ":"}>
                  {sender?.mobileNumber ?? '-'}
                </DetailItem>
              </div>
            </DetailBox>
            <DetailBox>
              <div className={styles.sectionHeader}>{t('SHARED.TO')}</div>
              <div className={styles.details}>
                <DetailItem label={<PrefixedTrans>ACCOUNT_NAME</PrefixedTrans>}>
                  {transaction?.attributes?.TRANSFER_RECIPIENT_NAME ?? '-'}
                </DetailItem>
                <DetailItem label={<PrefixedTrans>ACCOUNT_NUMBER</PrefixedTrans>}>
                  {transaction?.attributes?.TRANSFER_RECIPIENT_ACCOUNT_NUMBER ?? '-'}
                </DetailItem>
                <DetailItem label={<PrefixedTrans>BANK</PrefixedTrans>}>
                  {transaction?.attributes?.TRANSFER_RECIPIENT_BANK_NAME ?? '-'}
                </DetailItem>
              </div>
            </DetailBox>
          </div>
          {transaction?.attributes && (<DetailBox>
            <div className={styles.sectionHeader}>
              <PrefixedTrans>ATTRIBUTES_HEADER</PrefixedTrans>
            </div>
            <SimpleDetailsDataTable className={styles.table}
                                    rows={Object.keys(transaction?.attributes).map((value, index) => ({
                                      id: index,
                                      key: value,
                                      value: transaction?.attributes[`${value}`]
                                    }))}
                                    pageSize={Object.keys(transaction?.attributes).length}
                                    columns={attributesTableColumnDefinitions(t)}
                                    noRowsMessage={t('BUSINESS_OWNERS.TABLE.NO_RESULT_MESSAGE')} />
          </DetailBox>)}

          {!!responseArray.length && (<DetailBox>
            <div className={styles.sectionHeader}>
              <PrefixedTrans>API_RESPONSE_HEADER</PrefixedTrans>
            </div>
            <SimpleDetailsDataTable className={styles.table}
              rows={responseArray.map((value: any, index: any) => ({
                id: index,
                key: t(`TRANSACTIONS.DETAILS.API_RESPONSE.${value.key}`),
                value: value.value
              }))}
              columns={attributesTableColumnDefinitions(t)}
              noRowsMessage={t('BUSINESS_OWNERS.TABLE.NO_RESULT_MESSAGE')} />
          </DetailBox>)}

          {!!detailsArray.length && (<DetailBox>
            <div className={styles.sectionHeader}>
              <PrefixedTrans>API_DETAILS_HEADER</PrefixedTrans>
            </div>
            <SimpleDetailsDataTable className={styles.table}
              rows={detailsArray.map((value, index) => ({
                id: index,
                key: t(`TRANSACTIONS.DETAILS.API_DETAILS.${value.key}`),
                value: value.value
              }))}
              columns={attributesTableColumnDefinitions(t)}
              noRowsMessage={t('BUSINESS_OWNERS.TABLE.NO_RESULT_MESSAGE')} />
          </DetailBox>)}
        </div>
      )}
    </ContentBox>
  );
}
