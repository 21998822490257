import {Divider, Switch} from '@mui/material';
import {Formik, FormikHelpers, FormikProps} from 'formik';
import React, {ReactElement} from 'react';
import {useAuth} from '../../../../shared/auth/context';
import {Permission} from '../../../../shared/auth/model';
import {hasRequiredPermission} from '../../../../shared/auth/utils';
import {TransHelper} from '../../../../shared/utils/trans-helper';
import usePut from '../../../../shared/hooks/use-put.hook';
import NxButton from '../../../shared/nxButton/NxButton';
import NxInput from '../../../shared/nxInput/NxInput';
import {DictionaryEntry} from '../List.model';
import styles from './ListRow.module.scss';
import {DictionaryEntryFormFields} from './ListRowForm.model';

type updateEntryType = ReturnType<typeof usePut>

type DictionaryEntryProps = {
  entry: DictionaryEntry,
  getIdToUpdate: (id: updateEntryType) => void,
  onSubmit: (entry: Omit<DictionaryEntry, 'dictCode' | 'variant'>) => Promise<void>
}

const ListRow = ({entry, getIdToUpdate, onSubmit}: DictionaryEntryProps): React.ReactElement => {

  const {state} = useAuth();
  const updateEntry = usePut(`/admin/system/dictionaries/entries/${entry.id}`);
  const SharedTrans = TransHelper.getPrefixedTrans('SHARED');

  const handleInput = (updateEntry: updateEntryType) => {
    getIdToUpdate(updateEntry);
  };

  const formSubmit = async (values: DictionaryEntryFormFields,
                            actions: FormikHelpers<DictionaryEntryFormFields>): Promise<void> => {
    handleInput(updateEntry);
    await onSubmit({code: values.code, enabled: values.enabled, id: values.id, value: values.value});
    actions.resetForm({values})
  };

  const canToggleBankVisibility = hasRequiredPermission(state.userGroups ?? [], Permission.DICTIONARY_WRITE) 
                                  || hasRequiredPermission(state.userGroups ?? [], Permission.DICTIONARY_EPAY_WRITE)

  const EntryRowForm = ({
                          values,
                          handleSubmit,
                          handleChange,
                          isSubmitting,
                          dirty
                        }: FormikProps<DictionaryEntryFormFields>): ReactElement => {
    return (
      <form className={styles.rowForm} onSubmit={handleSubmit} onChange={handleChange}>
        <NxInput name='code'
                 className={styles.codeInput}
                 value={values.code}
                 required
                 onChange={handleChange}
                 permission={Permission.DICTIONARY_WRITE || Permission.DICTIONARY_EPAY_WRITE} />
        <NxInput name='value'
                 className={styles.valueInput}
                 value={values.value}
                 required
                 onChange={handleChange}
                 permission={Permission.DICTIONARY_WRITE || Permission.DICTIONARY_EPAY_WRITE} />
        <Switch name='enabled'
                checked={values.enabled}
                disableFocusRipple
                disableRipple
                disableTouchRipple
                classes={{
                  switchBase: styles.switchBase,
                  track: styles.switchTrack,
                  thumb: styles.switchThumb,
                  checked: styles.switchChecked
                }}
                sx={{
                  '& .MuiSwitch-track': {
                    backgroundColor: '#e8eff7'
                  },
                  '&-MuiSwitch-base': {
                    '&-MuiSwitch-checked': {
                      backgroundColor: '#555'
                    }
                  }
                }}
                disabled={!canToggleBankVisibility}
                className={styles.switch}
                onChange={handleChange} />
        <NxButton type='submit'
                  loaded={!isSubmitting}
                  className={styles.button}
                  disabled={!dirty}
                  >
          <SharedTrans>FORM.UPDATE</SharedTrans>
        </NxButton>
      </form>
    );
  };

  const formInitValues = {
    code: entry.code,
    value: entry.value,
    enabled: entry.enabled,
    id: entry.id,
    generalError: ''
  };

  return (
    <div className={styles.row}>
      <Formik<DictionaryEntryFormFields> initialValues={formInitValues}
                                         onSubmit={formSubmit}>
        {EntryRowForm}
      </Formik>
      <Divider className={styles.divider}/>
    </div>
  );
};

export default ListRow;