import React, {ReactElement, useEffect, useState} from 'react';
import {Navigate, Outlet, useLocation} from 'react-router-dom';
import MainContainer from './components/shared/main-container/MainContainer';
import NxLoader from './components/shared/nxLoader/NxLoader';
import ForgotPasswordRequest from './components/login/forgot-password/forgot-password-request/ForgotPasswordRequest';
import { checkAuthState, useAuth } from './shared/auth/context';
import {AuthState} from './shared/auth/model';

function App(): ReactElement {

  const auth = useAuth();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      if (auth.state.authState !== AuthState.SIGNED_IN) {
        await checkAuthState(auth.dispatch);
      }
      setIsLoading(false);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigateIfNotIsCurrent = (target: string): ReactElement => {
    if (location.pathname !== target) {
      return <Navigate to={target} />;
    }

    return <Outlet />;
  };

  const resolveUrl = (): ReactElement => {
    if (auth.state.authState === AuthState.SIGNED_IN && (!auth.state.userGroups || !auth.state.userGroups.length)) {
      return navigateIfNotIsCurrent('/user-no-group');
    }

    if (auth.state.authState === AuthState.SIGNED_IN && location.pathname === '/') {
      return navigateIfNotIsCurrent('/business-owners');
    }

    if ((auth.state.authState === AuthState.SIGNED_IN) && location.pathname !== '/forgot-password') {
      if (sessionStorage.getItem('tabOpenTime') === null) {
        sessionStorage.setItem('tabOpenTime', Date.now().toString());
      }
      return (
        <MainContainer>
          <Outlet />
        </MainContainer>
      );
    }

    if (location.pathname !== '/forgot-password' && (auth.state.authState === AuthState.NOT_SIGNED_IN || auth.state.authState === AuthState.PASSWORD_CHANGE_REQUIRED)) {
      return navigateIfNotIsCurrent('/login');
    } else {
      return <ForgotPasswordRequest/>
    }

  };

  return (
    isLoading
      ? <NxLoader />
      : resolveUrl()
  );
}

export default App;
