import {CancelTokenSource} from 'axios';
import {useAuth} from '../auth/context';
import {Request} from '../model/Request.model';
import HttpService from '../services/http.service';
import useApiCatch from './api-error-catch.hook';

export type UsePostOutput<T, E> = {
  (data?: E | null,
    params?: URLSearchParams | null,
    cancelTokenSource?: CancelTokenSource | null,
    paymentToken?: string | null,
    subUrl?: string,
    responseType?: string): Promise<T>;
};

/**
 * Dedicated hook for perform POST request.
 *
 * @param url - target url for API request.
 *
 * T - type of data returned by api call.
 * E - type of data passed as POST body.
 *
 * @return function which allows execute configured POST request. It has following arguments:
 * data - Data of type E which would be passed as POST body, it can be null or undefined.
 * cancelTokenSource - CancelTokenSource which allows to abort request, it can be null or undefined.
 */
export default function usePost<T, E>(url: string): UsePostOutput<T, E> {

  const catchError = useApiCatch();
  const {state} = useAuth();

  return async (
    data?: E | null,
    params?: URLSearchParams | null,
    cancelTokenSource?: CancelTokenSource | null,
    paymentToken?: string | null,
    subUrl: string = "",
    responseType?: string
  ): Promise<T> => {
    const request: Request = {
      url: url + subUrl,
      method: "POST",
      responseType: responseType,
      data,
      params,
      cancelTokenSource,
      sessionToken: state.apiToken,
    };

    return HttpService.request<T>(request).catch(catchError);
  };
}
