import dayJs from 'dayjs';
import { TRANSACTION_DATE_FORMAT, TRANSACTION_TIME_FORMAT } from '../../shared/constants/format-templates';
import { GridColDef } from '@mui/x-data-grid';
import { TFunction } from 'i18next';
import { createColDefWithDefaultConfiguration } from '../../shared/utils/dataGridDefaultColumnDefValues';

export const announcementTableColumnDefinitions =
  (t: TFunction): Array<GridColDef> => createColDefWithDefaultConfiguration(
    [
      {
        field: 'title',
        headerName: t('ANNOUNCEMENT.TITLE') ?? ''
      },
      {
        field: 'status',
        headerName: t('ANNOUNCEMENT.STATUS') ?? ''
      },
      {
        field: 'createdOn',
        headerName: t('ANNOUNCEMENT.DATE_CREATED') ?? '',
        valueFormatter: ({ value }) => dayJs(value).format(`${TRANSACTION_DATE_FORMAT} ${TRANSACTION_TIME_FORMAT}`)
      },
      {
        field: 'publishDate',
        headerName: t("ANNOUNCEMENT.DATE_PUBLISHED") ?? '',
        valueGetter: (params) => {
          const publishDate = `${params.row.publishDate}T${params.row.startTime}`
          return dayJs(publishDate).format(`${TRANSACTION_DATE_FORMAT} ${TRANSACTION_TIME_FORMAT}`)
        }
      },
      {
        field: 'createdBy',
        headerName: t('ANNOUNCEMENT.CREATED_BY') ?? ''
      },
    ]
  );
