import {ReactElement} from 'react';
import MuiSnackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import styles from './Alert.module.scss';
import clsx from 'clsx';

export interface AlertProps {
  className?: string;
  isOpen?: boolean
  delay?: number;
  duration?: number;
  message: string;
  vertical?: 'top' | 'bottom';
  horizontal?: 'left' | 'center' | 'right';
  type: 'warning' | 'success' | 'error' | 'info';
  hideAlert: () => void;
}

const Alert = ({
  className = '',
  isOpen,
  vertical = 'top',
  horizontal = 'center',
  type,
  duration,
  message = '',
  hideAlert
}: AlertProps): ReactElement => {

  const snackbarClassName = clsx(className, styles.snackbar)

  return (
    <MuiSnackbar
      anchorOrigin={{vertical, horizontal}}
      open={isOpen}
      autoHideDuration={duration}
      onClose={hideAlert}
      className={snackbarClassName}
    >
      <MuiAlert onClose={hideAlert} severity={type} className={styles.alert}>
        {message}
      </MuiAlert>
    </MuiSnackbar >
  )
};

export default Alert;
