export enum MaterialButtonVariant {
  CONTAINED = 'contained',
  OUTLINED = 'outlined',
  TEXT = 'text'
}

export enum NxButtonVariant {
  ADD = 'ADD',
  UNDO = 'UNDO',
  CLOSE = 'CLOSE',
  CONTAINED = 'CONTAINED',
  NEXT = 'NEXT',
  OUTLINED = 'OUTLINED',
  PREVIOUS = 'PREVIOUS',
  REJECT = 'REJECT',
  SAVE = 'SAVE',
  TEXT = 'TEXT',
  DELETE = 'DELETE',
  BACK = 'BACK',
  CANCEL = 'CANCEL',
  PROFILE_DELETE = 'PROFILE_DELETE'
}

export const MATERIAL_SECONDARY_COLOR_VARIANT = 'secondary';
