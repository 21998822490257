interface FormatCurrencyOptions {
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  style?: string;
  currency?: string;
}

export const formatCurrency = (value?: number, formatOptions?: FormatCurrencyOptions): string =>
  new Intl.NumberFormat(
    'en-US',
    {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      style: 'currency',
      currency: 'PHP',
      ...formatOptions
    }
  ).format(value ?? 0);