import {ReactElement} from 'react';
import styles from './ContentBox.module.scss';

type ContentBoxProps = {
  backButton?: ReactElement;
  children: ReactElement | ReactElement[];
  header: ReactElement | string;
};

const ContentBox = ({backButton, children, header}: ContentBoxProps): ReactElement => {
  return (
    <div className={styles.box}>
      {backButton}
      <div className={styles.boxHeader}>
        {header}
      </div>
      <div className={styles.boxContent}>
        {children}
      </div>
    </div>
  );
};

export default ContentBox;
