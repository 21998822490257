export type AnnouncementsType = {
  id: number;
  title: string;
  message: string;
  publishDate: string;
  startTime: any;
  endDate: string;
  endTime: any;
  frequency: string;
  createdBy: string;
  status: string;
  createdOn: string;
};

export type AnnouncementSearchQuery = {
  pageNo?: string;
  pageSize?: string;
  status?:string;
};

export enum AnnouncementStatus {
  PENDING = "PENDING",
  FAILED = "FAILED",
  ACTIVE = "ACTIVE",
  COMPLETED = "COMPLETED",
  CANCELLED = "CANCELLED",
}

export interface NewAnnouncementType
  extends Pick<
    AnnouncementsType,
    | "title"
    | "message"
    | "publishDate"
    | "endDate"
    | "frequency"
    | "startTime"
    | "endTime"
  > {}