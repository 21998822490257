import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Logo } from '../../../../assets/images/logo-login-header.svg'
import { ReactComponent as Success } from '../../../../assets/icons/icon-success.svg';
import { ReactComponent as Failure } from '../../../../assets/icons/icon-failure.svg';
import NxButton from '../../../shared/nxButton/NxButton';
import styles from './ChangePasswordResult.module.scss';

type ChangePasswordResultProps = {
    result: 'success' | 'fail' | '';
}

export default function ChangePasswordResult({ result }: ChangePasswordResultProps) {
    const navigate = useNavigate();
    const { t } = useTranslation();

    function redirect() {
        result === 'success' ? navigate('/login') : navigate('/forgot-password');
    }

    return (
        <div className={styles.container}>
            <div className={styles.messageContainer}>
                <Logo className={styles.logo}/>
                <div className={styles.subContainer}>
                    {result === 'success' ? <Success/> : <Failure/>}
                    <div className={styles.mainText}>{result === 'success' ? t(`LOGIN.CHANGE_PASSWORD_SUCCESS`) : t(`LOGIN.CHANGE_PASSWORD_FAIL`)}</div>
                    <div className={styles.subText}>{result === 'success' ? t(`LOGIN.CHANGE_PASSWORD_SUCCESS_SUBTEXT`) : t(`LOGIN.CHANGE_PASSWORD_FAIL_SUBTEXT`)}</div>
                    <NxButton onClick={redirect} className={styles.button}>{result === 'success' ? t(`LOGIN.SUCCESS_BUTTON`) : t(`LOGIN.FAIL_BUTTON`)}</NxButton>
                </div>
            </div>
            <span className={styles.footer}>{t(`MAIN_CONTAINER.FOOTER_TEXT`)}</span>
        </div>
    )
}