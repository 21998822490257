import dayjs from 'dayjs';
import { ReactElement, useState } from "react";
import ContentBox from '../../shared/content-box/ContentBox';
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { RoutePaths } from "../../../router";
import clsx from 'clsx';
import usePost from '../../../shared/hooks/use-post.hook';
import NxButton from "../../shared/nxButton/NxButton";
import { NxButtonVariant } from "../../shared/nxButton/NxButton";
import { useAlert } from '../../../shared/context/alert';
import { useTranslation } from "react-i18next";
import DetailBox from '../../shared/detail-box/DetailsBox';
import formatString from '../../../shared/utils/stringFormatter';
import CancelDialog from '../cancel-dialog/CancelDialog';
import styles from './AnnouncementDetails.module.scss';
import StatusColored, { StatusStyle } from '../../shared/statusColored/StatusColored';
import { AnnouncementStatus } from '../Announcements.model';

const mapStatusToStyle = (status?: any): StatusStyle | undefined => {
    switch (status) {
        case AnnouncementStatus.ACTIVE:
            return StatusStyle.SUCCESS;
        case AnnouncementStatus.COMPLETED:
            return StatusStyle.SUCCESS;
        case AnnouncementStatus.PENDING:
            return StatusStyle.WARN;
        case AnnouncementStatus.CANCELLED:
            return StatusStyle.NEUTRAL;
        case AnnouncementStatus.FAILED:
            return StatusStyle.ERROR;
    }
};

export default function AnnouncementDetails(): ReactElement {
    const { t } = useTranslation();
    const { showAlert } = useAlert();
    const location = useLocation();
    const navigate = useNavigate();
    const [cancelDialogShown, setCancelDialogShown] = useState(false);
    const deleteAnnouncement = usePost(`/admin/announcement/${location?.state?.results?.id}/cancel`);
    const isEditEnabled = location?.state?.results?.status === "PENDING";
    const isCancelEnabled = location?.state?.results?.status === "PENDING" || location?.state?.results?.status === "ACTIVE";

    if (!location.state) {
        navigate('/announcements')
    }

    const BackButton = () => (
      <NavLink to={RoutePaths.ANNOUNCEMENTS} className={styles.backButton}>
          <NxButton variant={NxButtonVariant.BACK}>
              Back
          </NxButton>
      </NavLink>
    );

    const cancelAnnouncement = () => {
        setCancelDialogShown(true);
    }

    const confirmCancelAnnouncement = (remarks: string) => {
        try {
            deleteAnnouncement({
                id: location?.state?.results.id,
                remarks: remarks
            })
              .then((res) => {
                  showAlert({ type: 'success', message: t('ANNOUNCEMENT.SUCCESS_CANCEL') });
                  navigate(`/announcements`)
              })
        } catch (error) {
            return Promise.reject(error);
        }
    }


    return (
      <ContentBox header={<BackButton />}>
          <CancelDialog
            open={cancelDialogShown}
            onClose={() => setCancelDialogShown(false)}
            onConfirm={confirmCancelAnnouncement}
          />
          <div className={styles.container}>
              <div className={styles.mainHeaders}>
                  <span className={styles.headerTitle}>{t(`ANNOUNCEMENT.DETAILS_ANNOUNCEMENT`)}</span>
                  <div className={styles.buttonSet}>
                      {isEditEnabled && <NxButton
                        type='submit'
                        className={styles.button}
                        variant={NxButtonVariant.OUTLINED}
                        disabled={!isEditEnabled}
                        onClick={() => {
                            navigate(`/announcements/edit/${location?.state?.results.id}`, {
                                state:
                                  {
                                      results: location?.state?.results
                                  }
                            })
                        }}
                      >
                          {t(`ANNOUNCEMENT.EDIT`)}
                      </NxButton>}

                      {isCancelEnabled  && <NxButton
                        type='submit'
                        className={clsx(styles.button, !isCancelEnabled ? "" : styles.buttonDelete)}
                        variant={NxButtonVariant.OUTLINED}
                        onClick={cancelAnnouncement}
                        disabled={!isCancelEnabled}
                      >
                          {t(`ANNOUNCEMENT.CANCEL_ANNOUNCEMENT`)}
                      </NxButton>}
                  </div>
              </div>
              <DetailBox>
                  <div className={clsx(styles.detailsSectionContent, styles.detailsSectionContent_personal)}>
                      <div>
                          <span className={clsx(styles.detailTitle)}>Title</span>
                          <span className={clsx(styles.detailValue, styles.title)}>{location?.state?.results?.title}</span>
                      </div>

                      <div className={styles.detailSet}>
                          <span className={styles.detailMessage}>Message</span>
                          <span><pre className={styles.detailValue}>{location?.state?.results?.message}</pre></span>
                      </div>

                      <div>
                          <span className={styles.detailTitle}>Publish Date</span>
                          <span className={styles.detailValue}>{`${dayjs(location?.state?.results?.publishDate).format('MM/DD/YYYY')} | ${(new Date(`${location?.state?.results?.publishDate}T${location?.state?.results?.startTime}`)).toLocaleTimeString()}`}</span>
                      </div>

                      <div>
                          <span className={styles.detailTitle}>End date</span>
                          <span className={styles.detailValue}>{`${dayjs(location?.state?.results?.endDate).format('MM/DD/YYYY')} | ${(new Date(`${location?.state?.results?.endDate}T${location?.state?.results?.endTime}`)).toLocaleTimeString() }`}</span>
                      </div>

                      <div>
                          <span className={styles.detailTitle}>Frequency</span>
                          <span className={styles.detailValue}>{formatString(location?.state?.results?.frequency)}</span>
                      </div>

                      <div>
                          <span className={styles.detailTitle}>Status</span>

                          <span className={clsx(styles.detailValue, styles.status)}><StatusColored style={mapStatusToStyle(location?.state?.results?.status)}
                                                                                                   statusName={formatString(location?.state?.results?.status)}/></span>
                      </div>
                  </div>
              </DetailBox>
          </div>
      </ContentBox>
    )
}