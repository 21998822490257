import { Formik, FormikHelpers, FormikProps } from 'formik';
import { ReactElement } from 'react';
import dayjs from 'dayjs';
import MenuItem from '@mui/material/MenuItem';
import NxButton from '../../shared/nxButton/NxButton';
import NxInput from '../../shared/nxInput/NxInput';
import NxDatepicker from '../../shared/nxDatepicker/NxDatepicker';
import NxGenericTextField from '../../shared/nxGenericTextField/NxGenericTextField';
import { useTranslation } from 'react-i18next';
import { MMDDYYYY_DASH_DATE_FORMAT } from '../../../shared/constants/format-templates';
import { ActivitySearchQuery, ActivityType } from '../Activity.model';
import styles from './ActivitySearch.module.scss';

type ActivitySearchFormProps = {
  isLoading: boolean;
  onSubmit: (newParams: ActivitySearchQuery) => Promise<void>;
  isBusinessOwnersOnlyVisible: boolean;
}

export default function ActivitySearch({ isLoading, onSubmit, isBusinessOwnersOnlyVisible }: ActivitySearchFormProps) {
  const { t } = useTranslation();

  const submitPropertyChange = async (
    values: ActivitySearchQuery,
    actions: FormikHelpers<ActivitySearchQuery>): Promise<void> => {
    onSubmit({
      ...values
    }).catch(error => actions.setFieldError('generalError', error));
  };

  const ActivitySearchForm = ({
    values,
    errors,
    handleSubmit,
    handleChange,
    isSubmitting,
    setFieldValue
  }: FormikProps<ActivitySearchQuery>): ReactElement => (
    <form onSubmit={handleSubmit} className={styles.searchBarContainer}>
      <NxInput
        label={t("SHARED.USERNAME")}
        name="username"
        className={styles.username}
        value={values.username}
        onChange={handleChange}
      />
      <NxGenericTextField
        name="activityType"
        select
        className={styles.activitySelect}
        disabled={isSubmitting || isLoading || isBusinessOwnersOnlyVisible}
        label={"Activity type"}
        value={isBusinessOwnersOnlyVisible ? 'BUSINESS_OWNERS' : values.activityType}
        onChange={handleChange}
      >
      {isBusinessOwnersOnlyVisible ? (
            <MenuItem key={'BUSINESS_OWNERS'} value={'BUSINESS_OWNERS'}>
              {t(`${'BUSINESS_OWNERS'}.BOX_TITLE`)}
            </MenuItem>
        ) : (
          [
            <MenuItem key="empty" value="">
              {t('SHARED.EMPTY')}
            </MenuItem>,
            ...Object.entries(ActivityType).map(([key, value]) => (
              <MenuItem key={key} value={value}>
                {t(`${key}.BOX_TITLE`)}
              </MenuItem>
            )),
          ]
        ) }
      </NxGenericTextField>
      <NxDatepicker
        disableFuture={true}
        name="fromDate"
        inputFormat={MMDDYYYY_DASH_DATE_FORMAT}
        className={styles.fromDate}
        label={t("SHARED.DATE_FROM")}
        value={values.fromDate ?? ""}
        error={errors.fromDate}
        onChange={(value) =>
          setFieldValue(
            "fromDate",
            dayjs(value).isValid()
              ? dayjs(value).format(MMDDYYYY_DASH_DATE_FORMAT)
              : "",
            true
          )
        }
        disabled={isSubmitting || isLoading}
      />
      <NxDatepicker
        disableFuture={true}
        name="toDate"
        inputFormat={MMDDYYYY_DASH_DATE_FORMAT}
        className={styles.dateTo}
        label={t("SHARED.DATE_TO")}
        value={values.toDate ?? ""}
        error={errors.toDate}
        onChange={(value) =>
          setFieldValue(
            "toDate",
            dayjs(value).isValid()
              ? dayjs(value).format(MMDDYYYY_DASH_DATE_FORMAT)
              : "",
            true
          )
        }
        disabled={isSubmitting || isLoading}
      />
      <NxButton
        type="submit"
        className={styles.searchButton}
        disabled={isLoading}
        loaded={!isSubmitting && !isLoading}
      >
        {t("SHARED.SEARCH")}
      </NxButton>
    </form>
  );

  return (
    <Formik<ActivitySearchQuery>
      initialValues={{}}
      onSubmit={submitPropertyChange}
    >
      {ActivitySearchForm}
    </Formik>
  );
}


