import React from 'react';
import {createBrowserRouter, createRoutesFromElements, Route} from 'react-router-dom';
import App from './App';
import BusinessOwners from './components/business-owners/BusinessOwners';
import BusinessOwnersDetails from './components/business-owners/details/BusinessOwnersDetails';
import Dictionaries from './components/dictionaries/Dictionaries';
import List from './components/dictionaries/list/List';
import NewRecord from './components/dictionaries/list/new-record/NewRecord';
import Login from './components/login/Login';
import ForgotPasswordRequest from './components/login/forgot-password/forgot-password-request/ForgotPasswordRequest';
import SystemProperties from './components/system-properties/SystemProperties';
import TransactionDetails from './components/transactions/transaction-details/TransactionDetails';
import Transactions from './components/transactions/Transactions';
import {Permission} from './shared/auth/model';
import GuardedRoute from './shared/utils/GuardedRoute';
import Reports from './components/reports/Reports';
import Activity from './components/activity/Activity';
import UserNoGroup from './components/user-no-group/UserNoGroup';
import Announcements from './components/announcements/Announcements';
import NewAnnouncements from './components/announcements/new-announcement/NewAnnouncement';
import AnnouncementDetails from './components/announcements/announcement-details/AnnouncementDetails';

export enum RoutePaths {
  LOGIN = '/login',
  FORGOT_PASSWORD = '/forgot-password',
  BUSINESS_OWNERS = '/business-owners',
  BUSINESS_OWNERS_DETAILS = '/business-owners/:businessOwnerId',
  TRANSACTIONS = '/transactions',
  TRANSACTION_DETAILS = '/transactions/:referenceNumber',
  DICTIONARIES = '/dictionaries',
  DICTIONARIES_LIST = '/dictionaries/:dictionaryCode',
  DICTIONARIES_LIST_NEW_RECORD = '/dictionaries/:dictionaryCode/new-record',
  SYSTEM_PROPERTIES = '/system-properties',
  REPORTS = '/reports',
  ACTIVITY = '/activity',
  USER_NO_GROUP = '/user-no-group',
  ANNOUNCEMENTS = '/announcements',
  NEW_ANNOUNCEMENT = '/announcements/new',
  EDIT_ANNOUNCEMENT = '/announcements/edit/:announcementId',
  DETAILS_ANNOUNCEMENT = '/announcements/:announcementId'
}

const router = createBrowserRouter(createRoutesFromElements(
  <Route path='/' element={<App />}>
    <Route path={RoutePaths.FORGOT_PASSWORD} element={<ForgotPasswordRequest />} />
    <Route path={RoutePaths.LOGIN} element={<Login />} />
    <Route path={RoutePaths.USER_NO_GROUP} element={<UserNoGroup />} />
    <Route index path={RoutePaths.BUSINESS_OWNERS} element={
      <GuardedRoute permission={Permission.CUSTOMER_READ}>
        <BusinessOwners />
      </GuardedRoute>
    } />
    <Route path={RoutePaths.BUSINESS_OWNERS_DETAILS} element={
      <GuardedRoute permission={Permission.CUSTOMER_READ}>
        <BusinessOwnersDetails />
      </GuardedRoute>
    } />
    <Route path={RoutePaths.TRANSACTIONS} element={
      <GuardedRoute permission={Permission.INSTAPAY_READ}>
        <Transactions />
      </GuardedRoute>
    } />
    <Route path={RoutePaths.TRANSACTION_DETAILS} element={
      <GuardedRoute permission={Permission.INSTAPAY_READ}>
        <TransactionDetails />
      </GuardedRoute>
    } />
    <Route path={RoutePaths.DICTIONARIES} element={
      <GuardedRoute permission={Permission.DICTIONARY_READ}>
        <Dictionaries />
      </GuardedRoute>
    } />
    <Route path={RoutePaths.DICTIONARIES_LIST} element={
      <GuardedRoute permission={Permission.DICTIONARY_READ}>
        <List />
      </GuardedRoute>
    } />
    <Route path={RoutePaths.DICTIONARIES_LIST_NEW_RECORD} element={
      <GuardedRoute permission={Permission.DICTIONARY_WRITE}>
        <NewRecord />
      </GuardedRoute>
    } />
    <Route path={RoutePaths.SYSTEM_PROPERTIES} element={
      <GuardedRoute permission={Permission.SETTINGS_READ}>
        <SystemProperties />
      </GuardedRoute>
    } />
    <Route path={RoutePaths.ACTIVITY} element={
      <GuardedRoute permission={Permission.ACTIVITY_READ}>
        <Activity />
      </GuardedRoute>
    } />
    <Route path={RoutePaths.REPORTS} element={
      <GuardedRoute permission={Permission.REPORT_READ}>
        <Reports />
      </GuardedRoute>
    } />
    <Route path={RoutePaths.ANNOUNCEMENTS} element={
      <GuardedRoute permission={Permission.ANNOUNCEMENT_READ}>
        <Announcements />
      </GuardedRoute>
    } />
    <Route path={RoutePaths.NEW_ANNOUNCEMENT} element={
      <GuardedRoute permission={Permission.ANNOUNCEMENT_CREATE}>
        <NewAnnouncements isNewAnnouncement={true} />
      </GuardedRoute>
    } />
    <Route path={RoutePaths.DETAILS_ANNOUNCEMENT} element={
      <GuardedRoute permission={Permission.ANNOUNCEMENT_CREATE}>
        <AnnouncementDetails />
      </GuardedRoute>
    } />
    <Route path={RoutePaths.EDIT_ANNOUNCEMENT} element={
      <GuardedRoute permission={Permission.ANNOUNCEMENT_CREATE}>
        <NewAnnouncements isNewAnnouncement={false} />
      </GuardedRoute>
    } />
    </Route>
));

export default router;
