import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useState } from 'react';
import NxButton, { NxButtonVariant } from '../../shared/nxButton/NxButton';
import NxGenericTextField from '../../shared/nxGenericTextField/NxGenericTextField';
import styles from './CancelDialog.module.scss';
import clsx from 'clsx';

export default function CancelDialog({ open, onClose, onConfirm }: any) {

    const [characterCount, setCharacterCount] = useState(0);
    const maxCharacters = 250;
    const [remarks, setRemarks] = useState('');

    const save = async (): Promise<void> => {
        onConfirm(remarks)
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                {"Cancel Announcement"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText marginBottom={'1.5rem'}>
                    {"Please indicate the reason why you want to cancel"}
                </DialogContentText>

                <div className={styles.formRow}>
                    <NxGenericTextField value={remarks} name="message" label={"Remarks"} shrinkLabel multiline sx={{ color: "white", width: "70vw" }} fullWidth={true} inputProps={{ maxLength: 250 }} className={styles.messageInput} onChange={(event: any) => {
                        setCharacterCount(event.target.value.length)
                        setRemarks(event.target.value)
                    }} />
                    <span className={styles.characterCount} style={{
                        color: characterCount === maxCharacters ? 'red' : '#6F85A5'
                    }}>
                        {characterCount}/{maxCharacters}
                    </span>
                </div>
            </DialogContent>
            <DialogActions>
                <div className={styles.buttonSet}>
                    <NxButton className={clsx(styles.button)}
                        variant={NxButtonVariant.CANCEL}
                        onClick={save}
                        disabled={!characterCount}
                        >
                        {"Proceed Cancellation"}
                    </NxButton>
                    <NxButton className={styles.button}
                        onClick={onClose}
                        variant={NxButtonVariant.TEXT}
                        >
                        {"No, Go back"}
                    </NxButton>

                </div>
            </DialogActions>
        </Dialog>
    );
}